import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";

import CONFIG from "../../config";
import CameraHelper from "helpers/cameraHelper";
import style from "./opaqueOverlayFraudChars.module.scss";

const OpaqueOverlayFraudChars = ({
  video,
  letters,
  overlayColors,
  imagesCaptured,
}) => {
  const characterOverlayElement = React.useRef(null);

  React.useEffect(() => {
    characterOverlayElement.current = document.getElementById(
      "opaqueOverlayCharacter"
    );
    if (characterOverlayElement.current) {
      characterOverlayElement.current.addEventListener(
        "animationend",
        captureOCRImage
      );
      characterOverlayElement.current.addEventListener(
        "webkitAnimationEnd",
        captureOCRImage
      );
    }
  }, []); // eslint-disable-line

  const captureOCRImage = () => {
    setTimeout(() => {
      CameraHelper.doScreenshot(
        video,
        [],
        CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS2
      );
      characterOverlayElement.current.removeEventListener(
        "animationend",
        captureOCRImage
      );
      characterOverlayElement.current.removeEventListener(
        "webkitAnimationEnd",
        captureOCRImage
      );
      imagesCaptured();
    }, CONFIG.OVERLAY_TIMEOUT);
  };

  return (
    <div
      id="opaqueOverlayCharacter"
      style={{
        color: overlayColors.characters,
      }}
      className={cs({
        [style.letterContainer]: true,
        [style.darkChars]: overlayColors.isLightOverlay,
        [style.lightChars]: !overlayColors.isLightOverlay,
      })}
    >
      <div
        className={cs({
          [style.flexContainer]: true,
          [style.foldableCase]:
            window.innerHeight / window.innerWidth > 2.1 ? true : false,
        })}
      >
        {letters.map((char, index) => (
          <p
            className={cs({
              [style.rotatechar90]: true,
              [style.leftChar]: [0, 3, 6].includes(index) ? true : false,
              [style.RightChar]: [2, 5, 8].includes(index) ? true : false,
            })}
            key={index}
          >
            {char}
          </p>
        ))}
      </div>
    </div>
  );
};

OpaqueOverlayFraudChars.propTypes = {
  letters: PropTypes.array,
  video: PropTypes.object,
};

OpaqueOverlayFraudChars.defaultProps = {
  letters: [],
};

export default OpaqueOverlayFraudChars;
