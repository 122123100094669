const FullScreenHelper = {
  enterFullScreenMode: (bodyElement) => {
    return new Promise((resolve, reject) => {
      if (
        (!bodyElement.fullscreenElement ||
          !bodyElement.webkitFullscreenElement ||
          !bodyElement.mozFullScreenElement ||
          !bodyElement.msFullscreenElement) &&
        (document.fullscreenEnabled ||
          document.webkitFullscreenEnabled ||
          document.mozFullScreenEnabled ||
          document.msFullscreenEnabled)
      ) {
        if (bodyElement.requestFullscreen) {
          bodyElement.requestFullscreen().catch((error) => {
            reject(error);
          });
        } else if (bodyElement.mozRequestFullScreen) {
          bodyElement.mozRequestFullScreen().catch((error) => {
            reject(error);
          });
        } else if (bodyElement.webkitRequestFullscreen) {
          bodyElement.webkitRequestFullscreen().catch((error) => {
            reject(error);
          });
        } else if (bodyElement.msRequestFullscreen) {
          bodyElement.msRequestFullscreen().catch((error) => {
            reject(error);
          });
        }
      } else {
        reject(false);
      }
      resolve(true);
    });
  },
  exitFullScreenMode: (isFullScreenMode) => {
    return new Promise((resolve, reject) => {
      if (!isFullScreenMode) {
        resolve(true);
      }

      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      } else if (document.mozCancelFullScreen) {
        document
          .mozCancelFullScreen()
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      } else if (document.webkitExitFullscreen) {
        document
          .webkitExitFullscreen()
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      } else if (document.msExitFullscreen) {
        document
          .msExitFullscreen()
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      }
      resolve(true);
    });
  },
};

export default FullScreenHelper;
