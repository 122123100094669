import CONFIG from "../config";
const platform = require("platform");

const isFoldable = {
  status: false,
  type: 0,
};

const DeviceHelper = {
  isAppleDevice: () => {
    if (
      platform &&
      platform.os &&
      (platform.os.family === "iOS" || platform.os.family === "OS X")
    ) {
      return true;
    }
    return false;
  },
  isAndroid: () => {
    if (platform && platform.os && platform.os.family === "Android") {
      return true;
    }
    return false;
  },
  isIOS15Above: () => {
    if (DeviceHelper.isIpad()) {
      const version = (platform && platform.version.split(".")) || "";
      if (version.length && version[0] > 14) return true;
      return false;
    } else if (DeviceHelper.isAppleDevice()) {
      const version = (platform.os && platform.os.version.split(".")) || "";
      if (version.length && version[0] > 14) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  },
  isIpad: () => {
    const isIPad =
      /Macintosh/.test(navigator.userAgent) && "ontouchend" in document;
    if (isIPad) {
      return true;
    }
    return false;
  },
  isSafariBrowser: () => {
    if (platform && platform.name === "Safari") {
      return true;
    }
    return false;
  },
  isChromeBrowser: () => {
    if (
      platform &&
      (platform.name === "Chrome" || platform.name === "Chrome Mobile")
    ) {
      return true;
    }
    return false;
  },
  isSamsungInternetBrowser: () => {
    if (platform && platform.name === "Samsung Internet") {
      return true;
    }
    return false;
  },
  isFirefoxBrowser: () => {
    if (
      platform &&
      (platform.name === "Firefox Mobile" ||
        platform.name === "Firefox for iOS" ||
        platform.name === "Firefox")
    ) {
      return true;
    }
    return false;
  },
  isEdgeBrowser: () => {
    if (platform && platform.name === "Microsoft Edge") {
      return true;
    }
    return false;
  },
  isElectronBrowser: () => {
    if (platform && platform.name === "Electron") {
      return true;
    }
    return false;
  },
  isAndroidWebview: () => {
    if (
      platform?.name.includes("Android Browser") &&
      platform?.description.includes("Android Browser")
    ) {
      return true;
    }
    return false;
  },
  getDeviceDetails: async () => {
    try {
      const resp = await window.getDeviceProps();
      return resp;
    } catch (error) {
      return {};
    }
  },
  makeAndModelCheckAllowed: () => {
    if (
      (DeviceHelper.isAndroid() && CONFIG.DEVICE_MAKE_AND_MODEL.IS_ANDROID) ||
      (DeviceHelper.isAppleDevice() && CONFIG.DEVICE_MAKE_AND_MODEL.IS_IOS)
    ) {
      return true;
    }
    return false;
  },
  setMakeAndModel: (obj) => {
    localStorage.setItem(CONFIG.MAKEANDMODEL, JSON.stringify(obj));
  },

  getMakeAndModel: () => {
    const makeAndModel = JSON.parse(localStorage.getItem(CONFIG.MAKEANDMODEL));
    return makeAndModel ? makeAndModel : null;
  },
  setFoldableDevice: () => {
    localStorage.setItem("isFoldableDevice", "true");
  },
  isDeviceFoldable: () => {
    if (localStorage.getItem("isFoldableDevice") === "true") {
      return true;
    }
    return false;
  },
  detectFoldableDevice: (atlasData) => {
    atlasData = atlasData || DeviceHelper.getMakeAndModel();

    if (atlasData && atlasData.vendor === "Samsung") {
      // SAMSUNG FOLD SERIES  == 1
      if (atlasData && atlasData.marketingName.includes("Fold")) {
        isFoldable.status = true;
        isFoldable.type = 1;
        return 1;
      }
      // SAMSUNG FLIP SERIES == 2
      else if (atlasData && atlasData.marketingName.includes("Flip")) {
        isFoldable.status = true;
        isFoldable.type = 2;
        return 2;
      }
    }
    return 0;
  },
  getFoldScreenType: () => {
    if (window.innerHeight > 580 && window.innerWidth > 550) {
      // INNER FOLDING SCREEN
      return 2;
    } else if (window.innerWidth < 350) {
      // OUTER SCREEN
      return 1;
    }
    return 0;
  },
  isValidFoldingScreenCase: () => {
    if (
      (DeviceHelper.isDeviceFoldable() &&
        DeviceHelper.detectFoldableDevice() === 2) || //Flip case
      (DeviceHelper.isDeviceFoldable() &&
        DeviceHelper.detectFoldableDevice() === 1 &&
        DeviceHelper.getFoldScreenType() === 2) //Fold inner case
    )
      return true;
    return false;
  },
  setExperimentalFoldableCase: () =>
    localStorage.setItem("isExperimentalFoldableCase", true),
  removeExperimentalFoldableCase: () =>
    localStorage.removeItem("isExperimentalFoldableCase"),
  isExperimentalFoldableCase: () =>
    localStorage.getItem("isExperimentalFoldableCase") === "true",
};

export default DeviceHelper;
