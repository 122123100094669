const REDIRECT_TIME_INTERVALS_12 = [3000, 2000, 2000, 2000, 2000, 1000];
const REDIRECT_TIME_INTERVALS_15 = [3000, 2000, 2000, 2000, 2000, 2000, 2000];
const REDIRECT_TIME_INTERVALS_20 = [
  3000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 1000,
];
let currentInterval = 0;

const RouteHelper = {
  routeToUrl: ({ navigate, pathname, search }) => {
    navigate({
      pathname: pathname,
      search: search,
    });
  },
  replaceUrl: ({ navigate, pathname, search, state = {} }) => {
    navigate(pathname + search, { state: state });
  },
  callToHelp: (number) => {
    window.location.href = `tel://${number}`;
  },
  redirectTimeOut: (time) => {
    let interval = REDIRECT_TIME_INTERVALS_15;
    switch (time) {
      case 12:
        interval = REDIRECT_TIME_INTERVALS_12;
        break;
      case 15:
        interval = REDIRECT_TIME_INTERVALS_15;
        break;
      case 20:
        interval = REDIRECT_TIME_INTERVALS_20;
        break;
      default:
        interval = REDIRECT_TIME_INTERVALS_15;
        break;
    }

    return new Promise((resolve) => {
      if (interval[currentInterval]) {
        setTimeout(() => {
          currentInterval += 1;
          resolve(true);
        }, interval[currentInterval]);
      } else {
        resolve(false);
      }
    });
  },
  routesLabels: {
    "is-front-screen-cracked": "/diagnosis/is-front-screen-cracked",
    "is-back-screen-cracked": "/diagnosis/is-back-screen-cracked",
    "pixel-display-test": "/diagnosis/pixel-display-test",
    "speaker-test": "/diagnosis/speaker-test",
    "mic-test": "/diagnosis/mic-test",
  },
};

export default RouteHelper;
