import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import DeviceAssessmentsReducer from "./deviceAssessmentsReducer";

const rootReducer = combineReducers({
  routing,
  DeviceAssessmentsReducer,
});

export default rootReducer;
