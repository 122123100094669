import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./dashboard.module.scss";
import {
  sendGTMDLPageView,
  getTenantKey,
  sendCompletionScore,
  sendTransactionId,
} from "../../services/api/googleTag";
import { useNavigate } from "react-router-dom";

import {
  getTestsList,
  setDetectionModel,
  setFullPageLoader,
  setC2PSaas,
  updateAssessmentStatus,
} from "../../actions/deviceAssessmentsAction";
import { isAutomatedTest } from "services/middlewareModel";
import SingleTestRow from "components/singleTestRow";
import LabelHelper from "helpers/labelHelper";
import RouteHelper from "helpers/routeHelper";
import TenantHelper from "helpers/tenantSpecificHelper";
import ErrorHelper from "helpers/errorHelper";
import ModalPopup from "components/modalPopup";
import CONFIG from "../../config";
import deviceDetection from "services/deviceDetectionModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircleIcon } from "assets/C2Picons";

let isModalLoading = false;
const Dashboard = ({
  lang,
  transactionId,
  history,
  theme,
  tenantCode,
  ...res
}) => {
  const ODMReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.ODMModel
  );
  const fullPageLoaderReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.fullPageLoader
  );
  const dispatch = useDispatch();
  const redirectUrl = useRef(null);
  const navigate = useNavigate();
  const [pageDetails, setPageDetails] = useState({
    heading: LabelHelper[lang].DASHBOARD.STARTING.HEADING,
    subHeading: LabelHelper[lang].DASHBOARD.STARTING.SUBCONTENT,
    CTA: LabelHelper[lang].DASHBOARD.STARTING.BELOW_CONTENT,
  });
  const [testArray, setTestArray] = useState([]);
  const [popup, setPopup] = useState({
    show: false,
    title: "",
    subText: "",
    CTA: "",
    actionType: "",
    isCTAdisabled: false,
  });

  useEffect(() => {
    let componentIsMounted = true;

    if (componentIsMounted) {
      dispatch(setFullPageLoader({ ...fullPageLoaderReducer, show: true }));
      getDeviceAssessments();
      // GOOGLE ANALYTICS
      // setVirtualPageName(getTenantKey(), 'dashboard')
    }

    return () => {
      componentIsMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!testArray.length) return;
    sendTransactionId(transactionId);
    TenantHelper("DASHBOARD", theme, { testArray });
    const testsCompleted = getCompletedTests();
    if (testsCompleted.length === testArray.length) {
      sendGTMDLPageView(
        lang,
        getTenantKey(),
        "Starting dashboard",
        "all-test-completed"
      );
      if (redirectUrl.current) {
        dispatch(
          setFullPageLoader({
            ...fullPageLoaderReducer,
            show: true,
            mainText: LabelHelper[lang].PAGE_LOADER.LOADING_TEXT,
            subText: "",
            dynamicCountdown: 15,
            countDownText: LabelHelper[lang].PAGE_LOADER.COUNTDOWN_TEXT,
          })
        );
        handleRedirect();
      } else {
        if (theme === CONFIG.TenantCodeThemeMapping.WINDTRE_IT.name) {
          setPageDetails({
            ...pageDetails,
            heading: LabelHelper[lang].DASHBOARD.FINISHED.HEADING,
            subHeading: LabelHelper[lang].DASHBOARD.FINISHED.SUBCONTENT2,
            CTA: "",
          });
        } else if (theme === CONFIG.TenantCodeThemeMapping.THREEIE_IE.name) {
          setPageDetails({
            ...pageDetails,
            heading: LabelHelper[lang].DASHBOARD.FINISHED.HEADING,
            subHeading: LabelHelper[lang].DASHBOARD.FINISHED.SUBCONTENT3,
            CTA: "",
          });
        } else if (
          theme === CONFIG.TenantCodeThemeMapping.BNPPARIBAS_FR.name ||
          theme === CONFIG.TenantCodeThemeMapping.ORANGE_FR.name
        ) {
          setPageDetails({
            ...pageDetails,
            heading: LabelHelper[lang].DASHBOARD.FINISHED.HEADING2,
            subHeading: LabelHelper[lang].DASHBOARD.FINISHED.SUBCONTENT4,
            CTA: "",
          });
        } else {
          setPageDetails({
            ...pageDetails,
            heading: LabelHelper[lang].DASHBOARD.FINISHED.HEADING,
            subHeading: LabelHelper[lang].DASHBOARD.FINISHED.SUBCONTENT,
            CTA: "",
          });
        }
      }
    } else if (!testsCompleted.length) {
      sendGTMDLPageView(
        lang,
        getTenantKey(),
        "Starting dashboard",
        "no-test-completed"
      );
      setPageDetails({
        ...pageDetails,
        heading: LabelHelper[lang].DASHBOARD.STARTING.HEADING,
        subHeading: LabelHelper[lang].DASHBOARD.STARTING.SUBCONTENT,
        CTA: LabelHelper[lang].DASHBOARD.STARTING.BELOW_CONTENT,
      });
    } else {
      sendGTMDLPageView(
        lang,
        getTenantKey(),
        "Starting dashboard",
        `${testsCompleted.length}-tests-completed`
      );
      setPageDetails({
        ...pageDetails,
        heading: testsLeftToBePerformed(testsCompleted),
        subHeading: LabelHelper[lang].DASHBOARD.INPROCESS.SUBCONTENT,
        CTA: LabelHelper[lang].DASHBOARD.INPROCESS.BELOW_CONTENT,
      });
    }
    dispatch(setFullPageLoader({ ...fullPageLoaderReducer, show: false }));
  }, [testArray]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDeviceAssessments = () => {
    let isPrimaryTestCTA = false;
    getTestsList(dispatch, transactionId)
      .then((data) => {
        if (
          data &&
          data.device &&
          data.device.c2pSaaS &&
          data.device.c2pSaaS.c2pSaaSEnabled
        ) {
          dispatch(setC2PSaas(data.device.c2pSaaS.features));
        }

        // When dashboard page is hit and transaction status is 'created', update to 'in_progress'
        const assessmentStatus = data && data.device.assessmentStatus;
        if (assessmentStatus === "created") {
          updateAssessmentStatus(transactionId, {
            status: "in_progress",
          });
        }

        const c2pSupportedTests =
          (data && data.device && data.device.tests) || [];
        redirectUrl.current =
          (data && data.device && data.device.redirectUrl) || null;
        if (!c2pSupportedTests.length) {
          return;
        }

        c2pSupportedTests.map((ele) => {
          if (
            [
              CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name,
              CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name,
              CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name,
            ].indexOf(ele.name) > -1 &&
            ele.status === CONFIG.TEST_STATUS_LABELS.INITIAL &&
            !ODMReducer &&
            !isModalLoading
          ) {
            isModalLoading = true;
            loadObjectDetectionModal();
          }
          if (
            !isPrimaryTestCTA &&
            ele.status === CONFIG.TEST_STATUS_LABELS.INITIAL
          ) {
            isPrimaryTestCTA = true;
            ele.isPrimaryCTA = true;
          } else {
            ele.isPrimaryCTA = false;
          }
          switch (ele.name) {
            case CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name:
              ele.title =
                LabelHelper[lang].DASHBOARD.TESTS.FRONT_SCREEN_CRACKED;
              break;
            case CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name:
              ele.title = LabelHelper[lang].DASHBOARD.TESTS.BACK_SCREEN_CRACKED;
              break;
            case CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name:
              ele.title = LabelHelper[lang].DASHBOARD.TESTS.PIXEL_DAMAGE;
              break;
            case CONFIG.TEST_LABELS["SPEAKER_TEST"].name:
              ele.title = LabelHelper[lang].DASHBOARD.TESTS.SPEAKER_TEST;
              break;
            case CONFIG.TEST_LABELS["MIC_TEST"].name:
              ele.title = LabelHelper[lang].DASHBOARD.TESTS.MIC_TEST;
              break;
            default:
              ele.title = "";
              break;
          }
          return ele;
        });
        setTestArray(c2pSupportedTests);
      })
      .catch((error) => {
        if (
          error?.status === 403 &&
          error?.data?.message.includes("not authorized")
        ) {
          RouteHelper.replaceUrl({
            navigate,
            pathname: "/expired",
            search: "",
          });
        } else {
          ErrorHelper(error, history, navigate);
        }
      })
      .finally(() => {});
  };

  const loadObjectDetectionModal = () => {
    if (isAutomatedTest(tenantCode)) {
      dispatch(setDetectionModel(true));
    } else {
      deviceDetection.loadODMWorker(handleODMListener);

      function handleODMListener(message) {
        const {
          data: { modelStatus },
        } = message;
        if (modelStatus === "LOADED") {
          dispatch(setDetectionModel(true));
        } else if (modelStatus === "FAILED") {
          setPopup({
            ...popup,
            status: true,
            message: LabelHelper[lang].ERRORS.CAMERA_ERROR,
            actionType: "RELOAD",
          });
        }
      }
    }
  };

  const handleRedirect = async () => {
    try {
      let timeOut = 15;
      if (theme === CONFIG.TenantCodeThemeMapping.UPLUS.name) {
        timeOut = 12;
      }
      const result = await RouteHelper.redirectTimeOut(timeOut);
      if (result) {
        getTestsList(dispatch, transactionId)
          .then((data) => {
            if (data && data.device) {
              const frontScreenTest = data.device.tests.find(
                (test) => test.name === "is-front-screen-cracked"
              );
              if (frontScreenTest && frontScreenTest.results) {
                let crackedProbability =
                  frontScreenTest.results.crackedProbability.toFixed(2);
                sendCompletionScore(
                  lang,
                  getTenantKey(),
                  "testCompleted",
                  CONFIG.GA_TAGS[frontScreenTest.name],
                  crackedProbability
                );
              }
            } else {
              throw new Error();
            }
            if (data && data.device && data.device.isCallbackPost === true) {
              window.location.href = redirectUrl.current;
            } else {
              throw new Error();
            }
          })
          .catch((error) => {
            handleRedirect();
          });
      } else {
        window.location.href = redirectUrl.current;
      }
    } catch (error) {
      handleRedirect();
    }
  };

  const testsLeftToBePerformed = (testsCompleted) => {
    return LabelHelper[lang].DASHBOARD.INPROCESS.HEADING.replace(
      "<>",
      testArray.length - testsCompleted.length
    );
  };

  const getCompletedTests = () => {
    return testArray.filter(
      (e) => e.status === CONFIG.TEST_STATUS_LABELS.FINAL
    );
  };

  const navigateToInstructions = (testName) => {
    navigate({
      pathname: `/instruction/${testName}`,
      search: history?.location?.search,
    });
  };

  const CTAAction = () => {
    switch (popup.actionType) {
      case "RELOAD":
        setPopup({ ...popup, show: false });
        RouteHelper.routeToUrl({
          navigate,
          pathname: `/`,
          search: history.location.search,
        });
        break;
      case "COPY_URL":
        const copyText = document.getElementById("copyURLButton");
        copyText.click();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={style.dashboard + " container pt-4"}>
        <h1
          id="dashboardTitle"
          data-cy="dashboard-heading"
          className={style.heading}
        >
          {pageDetails.heading}
        </h1>
        <p id="dashboardSubTitle" className={style.subHeading + " mt-3"}>
          {pageDetails.subHeading}
        </p>

        {getCompletedTests().length === testArray.length ? (
          <>
            {/* ======= DONE AREA ======= */}
            <div
              data-cy="dashboard-doneArea"
              id="c2p-dashboardDoneArea"
              className={style.doneArea + " d-none text-center mt-4"}
            >
              <FontAwesomeIcon
                className={style.iconButton}
                aria-label={"TESTS FINISHED"}
                icon={faCheckCircleIcon}
              />
              <p data-cy="dashboard-doneText" className="text-center">
                {LabelHelper[lang].DASHBOARD.MISC.DONE}
              </p>
            </div>
            {/* ======= DONE AREA ======= */}
          </>
        ) : (
          <>
            <h4 className={style.assessmentHeading + " text-start"}>
              {testArray.length > 1
                ? LabelHelper[lang].DASHBOARD.MISC.REMAINING_ASSESSMENTS +
                  (testArray.length - getCompletedTests().length)
                : LabelHelper[lang].DASHBOARD.MISC.ASSESSMENT}
            </h4>
            <div
              data-cy="test-array"
              id="dashboardTestArray"
              className={style.testArray + ""}
            >
              {testArray.map((test, index) => (
                <SingleTestRow
                  key={index}
                  lang={lang}
                  test={test}
                  clickAction={navigateToInstructions}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <ModalPopup
        lang={lang}
        show={popup.show}
        title={popup.title}
        message={popup.subText}
        CTA={popup.CTA}
        clickEvent={CTAAction}
        isCTAdisabled={popup.isCTAdisabled}
      />
    </>
  );
};

export default Dashboard;
