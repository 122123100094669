import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Button from "components/button";
import PageLoader from "components/page-loader";
import style from "./mic-diagnosis.module.scss";
import LabelHelper from "../../helpers/labelHelper";
import MicTestOutput from "../../components/micTest-output";
import RouteHelper from "../../helpers/routeHelper";
import {
  startMicTest,
  stopMediaRecorder,
  MIC_ERRORS_CONSTANTS,
} from "../../services/micService";
import {
  getMicTestData,
  getSignedURL,
} from "../../actions/deviceAssessmentsAction";
import ModalPopup from "../../components/modalPopup";
import DeviceHelper from "../../helpers/deviceHelper";
import ErrorHelper from "helpers/errorHelper";
import { sendGTMDLPageView, getTenantKey } from "../../services/api/googleTag";
import CONFIG from "../../config";

const COUNTDOWN_TIME = 10;

const MicDiagnosis = ({ lang, transactionId, locale, history, ...res }) => {
  const { type: testName } = useParams();
  const dispatch = useDispatch();
  const [finalScreen, setFinalScreen] = useState(false);
  const [recording, setRecording] = useState({ status: false, data: null });
  const [count, setCount] = useState(COUNTDOWN_TIME);
  const [values, setValues] = useState({ first: "", second: "" });
  const [audioData, setAudioData] = useState(null);
  const [pageLoader, setPageLoader] = useState({
    status: true,
    text: LabelHelper[lang].PAGE_LOADER.COCO_MODAL_LOADING_TEXT,
    subText: LabelHelper[lang].PAGE_LOADER.COCO_MODAL_LOADING_SUBTEXT,
  });
  const [popup, setPopup] = useState({
    show: false,
    title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
    message: "",
    CTA: "",
    type: "permission",
    disableCTA: false,
    isCancellable: true,
  });
  const navigate = useNavigate();
  useEffect(() => {
    let componentIsMounted = true;
    if (componentIsMounted) {
      getValues();
    }

    return () => {
      componentIsMounted = false;
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    let componentIsMounted = true;
    if (componentIsMounted && recording.status) {
      setTimeout(() => {
        if (count === 1) {
          handleTest();
        } else {
          setCount(count - 1);
        }
      }, 1000);
    }
    return () => {
      componentIsMounted = false;
    };
  }, [recording, count]); // eslint-disable-line

  const getValues = () => {
    getMicTestData(
      transactionId,
      locale,
      (response) => {
        response = (response && response.data) || {};
        setPageLoader({ ...pageLoader, status: false });
        if (response && response.localizeCode) {
          setValues({
            first: response.localizeCode[0],
            second: response.localizeCode[1],
          });
          setPopup({
            ...popup,
            show: true,
            message:
              LabelHelper[lang].DIAGNOSE_LABEL[testName].BEFORE_YOU_BEGIN_TEXT,
            title: LabelHelper[lang].DIAGNOSE_LABEL[testName].BEFORE_YOU_BEGIN,
            CTA: LabelHelper[lang].DIAGNOSE_LABEL[testName]
              .BEFORE_YOU_BEGIN_CTA,
            type: "permission",
            isCancellable: false,
          });
        } else {
          setPopup({
            ...popup,
            show: true,
            title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
            message: LabelHelper[lang].ERRORS.GENERIC_TITLE,
            CTA: LabelHelper[lang].ERRORS.OK,
            type: "backPage",
          });
        }
      },
      () => {
        setPageLoader({ ...pageLoader, status: false });
        setPopup({
          ...popup,
          show: true,
          title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
          message: LabelHelper[lang].ERRORS.GENERIC_TITLE,
          CTA: LabelHelper[lang].ERRORS.OK,
          type: "backPage",
        });
      }
    );
  };

  const handleTest = () => {
    switch (popup.type) {
      case "permission":
        setPopup({ ...popup, show: false });
        break;
      case "backPage":
        RouteHelper.replaceUrl({
          navigate,
          pathname: `/instruction/${testName}`,
          search: history.location.search,
        });
        return false;

      default:
        break;
    }

    if (!recording.status) {
      startRecording();
    } else {
      setRecording({ ...recording, status: false });
      stopRecording();
    }
  };

  const startRecording = async () => {
    try {
      const result = await startMicTest();
      if (result === true) {
        sendGTMDLPageView(
          lang,
          getTenantKey(),
          `${CONFIG.GA_TAGS[testName]} performing`,
          ""
        );
        setRecording({ ...recording, status: true });
      } else {
        throw new Error(result);
      }
    } catch (error) {
      if (error === MIC_ERRORS_CONSTANTS.PERMISSION_DENIED) {
        let disableCTA = false;
        let isCancellable = false;
        if (DeviceHelper.isAndroid()) {
          disableCTA = true;
          isCancellable = true;
        }
        setPopup({
          ...popup,
          show: true,
          title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
          message:
            LabelHelper[lang].DIAGNOSE_LABEL[testName].MIC_PERMISSION_DENIED,
          disableCTA,
          isCancellable,
        });
      } else if (error === MIC_ERRORS_CONSTANTS.ALREADY_IN_USE) {
        setPopup({
          ...popup,
          show: true,
          title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
          message: LabelHelper[lang].DIAGNOSE_LABEL[testName].MIC_IN_USE,
          CTA: LabelHelper[lang].DIAGNOSE_LABEL[testName].MIC_IN_USE_CTA,
          type: "permission",
        });
      } else {
        setPopup({
          ...popup,
          show: true,
          title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
          message: LabelHelper[lang].ERRORS.GENERIC_TITLE,
          CTA: LabelHelper[lang].ERRORS.OK,
          type: "backPage",
        });
      }
    }
  };

  const stopRecording = async () => {
    try {
      const audioOutput = await stopMediaRecorder();
      setAudioData(audioOutput);
      setFinalScreen(true);
    } catch (error) {
      setPopup({
        ...popup,
        show: true,
        title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
        message: LabelHelper[lang].ERRORS.GENERIC_TITLE,
        CTA: LabelHelper[lang].ERRORS.OK,
        type: "backPage",
      });
    }
  };

  const resetTest = () => {
    setRecording({ status: false, data: null });
    setCount(COUNTDOWN_TIME);
    setFinalScreen(false);
  };

  const submitTest = (result) => {
    setPageLoader({ ...pageLoader, status: true, text: "", subText: "" });
    getSignedURL(dispatch, audioData, transactionId, testName, getTenantKey())
      .then((response) => {
        routeToCompletion(result);
      })
      .catch((error) => {
        if (error?.data?.code === "ALREADY_SUBMITTED") {
          routeToCompletion(result);
        } else {
          ErrorHelper(error, history, navigate);
        }
      })
      .finally(() => {
        setPageLoader({ ...pageLoader, status: false });
      });
  };

  const routeToCompletion = (result) => {
    RouteHelper.replaceUrl({
      navigate,
      pathname: `/completion/${testName}`,
      search: history.location.search,
      state: { result, locale },
    });
  };

  return (
    <>
      <PageLoader
        show={pageLoader.status}
        text={pageLoader.text}
        subText={pageLoader.subText}
        lang={lang}
      />
      <div className={`container ${style.micWrapper}`}>
        {finalScreen ? (
          <MicTestOutput
            lang={lang}
            resetTest={resetTest}
            testResult={(value) => submitTest(value)}
            testName={testName}
            audioFile={audioData}
          />
        ) : (
          <>
            <i
              className={`${style.mainIcon} ${
                recording.status ? style.heartBeatAnimation : ""
              } fa fa-microphone`}
              aria-hidden="true"
            ></i>
            <p className={style.heading}>
              {recording.status
                ? LabelHelper[lang].DIAGNOSE_LABEL[testName].SECONDARY_TEXT
                : LabelHelper[lang].DIAGNOSE_LABEL[testName].MAIN_TEXT}
            </p>

            <div className={style.mainWord}>
              <input
                data-cy="mic-test-input-1"
                type="text"
                value={values.first}
                name="letter1"
                id="letter1"
                readOnly={true}
              />
              <input
                data-cy="mic-test-input-2"
                type="text"
                value={values.second}
                name="letter2"
                id="letter2"
                readOnly={true}
              />
            </div>

            <div className={style.bottomArea}>
              {recording.status ? (
                <div className={style.countDown}>
                  <p>Countdown</p>
                  <p>{count} sec</p>
                </div>
              ) : null}
              <Button
                dataHelper="beginAndDoneButton"
                data-cy="beginButton"
                clickEvent={handleTest}
                disabled={!values.first || !values.second}
                text={
                  recording.status
                    ? LabelHelper[lang].DIAGNOSE_LABEL[testName].FINISH_BUTTON
                    : LabelHelper[lang].DIAGNOSE_LABEL[testName].BEGIN_BUTTON
                }
              />
            </div>
          </>
        )}
      </div>
      <ModalPopup
        lang={lang}
        show={popup.show}
        title={popup.title}
        message={popup.message}
        CTA={popup.CTA}
        clickEvent={() => handleTest("popup")}
        isCancellable={popup.isCancellable}
        hidePopup={() => setPopup({ ...popup, show: false })}
        isCTAdisabled={popup.disableCTA}
      />
    </>
  );
};

export default MicDiagnosis;
