import React, { useEffect } from "react";
import LabelHelper from "helpers/labelHelper";
import cs from "classnames";
import RouteHelper from "helpers/routeHelper";

import style from "./expired.module.scss";
import { sendGTMDLPageView, getTenantKey } from "../../services/api/googleTag";
import { useDispatch, useSelector } from "react-redux";
import { setFullPageLoader } from "actions/deviceAssessmentsAction";

const Expired = ({ lang, helpLine, helpLineVisibility, ...res }) => {
  sendGTMDLPageView(lang, getTenantKey(), "Expired link", "");
  const dispatch = useDispatch();
  const fullPageLoaderReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.fullPageLoader
  );

  useEffect(() => {
    dispatch(setFullPageLoader({ ...fullPageLoaderReducer, show: false }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={cs({
        [style.completion]: true,
        fullHeight: true,
      })}
    >
      <div
        className={cs({
          "container fullHeight": true,
          [style.container]: true,
        })}
      >
        <div className={style.content}>
          <h2 data-cy="expired-title">{LabelHelper[lang].EXPIRED.TITLE}</h2>
          {helpLineVisibility ? (
            <>
              <p>{LabelHelper[lang].EXPIRED.PRIMARY_TEXT}</p>
              <p onClick={RouteHelper.callToHelp.bind(this, helpLine)}>
                {helpLine}
              </p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Expired;
