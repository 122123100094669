const CameraHelper = {
  expandByPercentage: (value, percentage, bufferValue = value) => {
    return value + percentage * bufferValue;
  },
  shrinkByPercentage: (value, percentage, bufferValue = value) => {
    return value - percentage * bufferValue;
  },
  doScreenshot: function (element, predictedBoundingBox, type) {
    const canvas = document.getElementById(type);
    const ctx = canvas.getContext("2d");
    if (predictedBoundingBox.length) {
      canvas.width = this.expandByPercentage(predictedBoundingBox[2], 0.1);
      canvas.height = this.expandByPercentage(predictedBoundingBox[3], 0.1);

      const sourceImageX = this.shrinkByPercentage(
        predictedBoundingBox[0],
        0.05,
        predictedBoundingBox[2]
      );
      const sourceImageY = this.shrinkByPercentage(
        predictedBoundingBox[1],
        0.05,
        predictedBoundingBox[3]
      );
      const sourceWidth = this.expandByPercentage(
        predictedBoundingBox[2],
        0.05
      );
      const sourceHeight = this.expandByPercentage(
        predictedBoundingBox[3],
        0.05
      );

      ctx.drawImage(
        element,
        sourceImageX,
        sourceImageY,
        sourceWidth,
        sourceHeight,
        0,
        0,
        canvas.width,
        canvas.height
      );
    } else {
      canvas.width = element.videoWidth;
      canvas.height = element.videoHeight;
      ctx.drawImage(element, 0, 0);
    }
    return true;
  },
  convertRawToODMInput: (canvas, htmlImage, width, height) => {
    canvas.width = width;
    canvas.height = height;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.drawImage(htmlImage, 0, 0, width, height);
    return canvasCtx.getImageData(0, 0, width, height);
  },
  getImageBase64: (data) => {
    return data.toDataURL("image/jpeg", 1.0);
  },
  getWhiteFrameBoxMeasurements: (v) => {
    if (v === "height") {
      return 95;
    }
    return 91;
  },
  getConstraints: function (cameraFacingMode = "user", deviceId = null) {
    const constraints = {
      audio: false,
      video: {
        facingMode: cameraFacingMode,
        width: { min: 720, ideal: 1920, max: 3000 },
        height: { min: 480, ideal: 1080, max: 1400 },
      },
    };
    if (deviceId) {
      constraints.video.deviceId = { exact: deviceId };
      delete constraints.video.facingMode;
    }
    return constraints;
  },
  getVideoDeviceId: () => {
    return new Promise((resolve, reject) => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.error("[getVideoDeviceId] camera API not supported");
        reject(false);
      }
      const deviceIDs = [];
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind === "videoinput") {
              deviceIDs.push(device.deviceId);
            }
          });
          resolve(deviceIDs);
        })
        .catch(function (error) {
          console.error("[enumerateDevices] device ID ", error);
          reject(false);
        });
    });
  },
  convertToImageBlob: async (imageData) => {
    try {
      const response = await fetch(imageData);
      const imageBlob = await response.blob();
      const formData = new FormData();
      formData.append("imageBlob", imageBlob);
      return formData;
    } catch (error) {
      throw error.response;
    }
  },
  isMockTest: () => {
    if (window.localStorage.getItem("mockDetection") === "true") {
      return true;
    }
    return false;
  },
};

export default CameraHelper;
