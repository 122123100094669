import React from "react";
import PropTypes from "prop-types";
import style from "./singleTestRow.module.scss";
import {
  faBackCoverTestIcon,
  faCheckIcon,
  faCrackedScreenTestIcon,
  faMicrophoneTestIcon,
  faPixelTestIcon,
  faSpeakerTestIcon,
} from "assets/C2Picons";
import CONFIG from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import C2PButton from "components/button";
import LabelHelper from "helpers/labelHelper";

const SingleTestRow = ({ lang, test, clickAction }) => {
  const getTestIcon = () => {
    switch (test?.name) {
      case CONFIG.TEST_LABELS.FRONT_SCREEN_CRACK_TEST.name:
        return faCrackedScreenTestIcon;
      case CONFIG.TEST_LABELS.BACK_SCREEN_CRACK_TEST.name:
        return faBackCoverTestIcon;
      case CONFIG.TEST_LABELS.PIXEL_DISPLAY_TEST.name:
        return faPixelTestIcon;
      case CONFIG.TEST_LABELS.MIC_TEST.name:
        return faSpeakerTestIcon;
      case CONFIG.TEST_LABELS.SPEAKER_TEST.name:
        return faMicrophoneTestIcon;

      default:
        return faCrackedScreenTestIcon;
    }
  };

  return (
    <div
      data-cy="test-item"
      style={{
        opacity: test?.status === CONFIG.TEST_STATUS_LABELS.FINAL ? 0.5 : 1,
      }}
      className={
        style.singleTestRow +
        " d-flex justify-content-between align-items-center py-2"
      }
    >
      <div className={style.flexItem}>
        <FontAwesomeIcon
          className={style.testIcon}
          aria-label={test?.name}
          icon={getTestIcon()}
        />
        <h5 id="testTitleName" className={style.testLabel + " ms-2"}>
          {test?.title}
        </h5>
      </div>

      <div className={style.flexItem}>
        {test?.status === CONFIG.TEST_STATUS_LABELS.FINAL ? (
          <>
            <FontAwesomeIcon
              className={style.testCompletedCheckIcon}
              aria-label={test?.name}
              icon={faCheckIcon}
            />
            <h5 className="ms-1 mb-0">
              {LabelHelper[lang]?.DASHBOARD?.MISC?.COMPLETED}
            </h5>
          </>
        ) : (
          <C2PButton
            additionalStyle={style.testButton}
            color={test?.isPrimaryCTA ? "primary" : "secondary"}
            text={LabelHelper[lang]?.DASHBOARD?.MISC?.CTA}
            dataHelper="singleTest-button"
            clickEvent={() => clickAction(test.name)}
          />
        )}
      </div>
    </div>
  );
};

SingleTestRow.propTypes = {
  test: PropTypes.object,
  clickAction: PropTypes.func,
};

SingleTestRow.defaultProps = {
  test: {
    name: "is-front-screen-cracked",
    status: "PENDING",
    title: "Cracked Screen",
  },
};

export default SingleTestRow;
