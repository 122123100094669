import { apiBaseUrl, postRequest, putRequest, getRequest } from "./base";

export const fetchSignedURL = async (transactionId, testName, makeAndModel) => {
  const url = `${apiBaseUrl}/device-assessments/${transactionId}/upload`;
  return await postRequest(url, {
    type: testName,
    makeAndModel: makeAndModel,
  });
};

export const getAllTestData = async (transactionId) => {
  const url = `${apiBaseUrl}/device-assessments/${transactionId}`;
  return await getRequest(url);
};

export const upload = async (signedURL, formData, contentType) => {
  if (contentType === "image/jpeg") {
    formData = formData.get("imageBlob");
  } else if (contentType === "audio/mpeg") {
    formData = formData.get("audioBlob");
  }
  return await putRequest(signedURL, formData, {
    headers: {
      "Content-Type": contentType,
    },
  });
};

export const getMicTestData = async (transactionId, locale) => {
  const url = `${apiBaseUrl}/device-assessments/${transactionId}/mic/code?locale=${locale}`;
  return await getRequest(url);
};

export const imageProcessing = async (transactionId, image) => {
  const url = `${apiBaseUrl}/anomaly-detection/${transactionId}`;
  return await postRequest(url, image);
};

export const fraudDetection = async (
  imgCharacters,
  transactionId,
  characters
) => {
  const url = `${apiBaseUrl}/ocr/${transactionId}/fraud-detection`;
  return await postRequest(url, {
    imgCharacters,
    characters,
  });
};

export const submit = async (transactionId, payload) => {
  const url = `${apiBaseUrl}/device-assessments/${transactionId}/submit`;
  return await postRequest(url, payload);
};

export const updateStatus = async (transactionId, payload) => {
  const url = `${apiBaseUrl}/device-assessments/${transactionId}/update-status`;
  return await postRequest(url, payload);
};