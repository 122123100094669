import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./page-loader.module.scss";
import LabelHelper from "../../helpers/labelHelper";
import Spinner from "react-bootstrap/Spinner";

const PageLoader = ({
  lang,
  text,
  subText,
  dynamicCountdown,
  countDownText,
  show,
}) => {
  const [subText2, setSubText] = useState("");
  const countDown = useRef(0);
  const countInterval = useRef(null);

  useEffect(() => {
    if (dynamicCountdown) {
      countDown.current = dynamicCountdown;
      countInterval.current = setInterval(() => {
        countDownInterval();
      }, 1000);
    }
  }, [dynamicCountdown]); // eslint-disable-line react-hooks/exhaustive-deps

  const countDownInterval = () => {
    setSubText(countDownText.replace("<count>", countDown.current));
    if (countDown.current === 0) {
      clearInterval(countInterval.current);
      return false;
    }
    countDown.current -= 1;
  };

  return (
    <div
      data-cy="page-loader"
      className={`${show ? "" : "d-none"}  ${style.pageLoader}`}
    >
      <div className={style.content}>
        <span>
          <Spinner
            className={style.loaderIcon}
            animation="border"
            role="status"
          ></Spinner>
        </span>
        <span className={style.loaderText}>
          {text ? text : LabelHelper[lang].PAGE_LOADER.LOADING_TEXT}
        </span>
        {subText ? (
          <span className={`mt-5 ${style.loaderSubText}`}>{subText}</span>
        ) : null}
        {subText2 ? (
          <span className={`mt-5 ${style.loaderSubText}`}>{subText2}</span>
        ) : null}
      </div>
    </div>
  );
};

PageLoader.propTypes = {
  lang: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  dynamicCountdown: PropTypes.number,
  countDownText: PropTypes.string,
};

PageLoader.defaultProps = {
  lang: "en",
  show: true,
  dynamicCountdown: 0,
  countDownText: "",
};

export default PageLoader;
