import React from "react";
import { Provider } from "react-redux";
import queryString from "query-string";
import C2PRoutes from "./routes";
import axiosInterceptors from "./services/api/interceptors";
import authService from "./services/auth";
import "font-awesome/css/font-awesome.min.css";
import "./App.scss";
import themeLoader from "./themes/loader";
import CONFIG from "config";
import ModalPopup from "components/modalPopup";
import DeviceHelper from "helpers/deviceHelper";
import ClipboardJS from "clipboard";
import LabelHelper from "helpers/labelHelper";
import { BrowserRouter } from "react-router-dom";

const CURRENT_URL = window.location.href;
let LANG = "en";
const App = (props) => {
  const [themeConfig, setThemeConfig] = React.useState({});
  const [popup, setPopup] = React.useState({
    show: false,
    title: "",
    subText: "",
    CTA: "",
    isCTAdisabled: false,
    popupType: null,
  });

  const params = React.useRef(
    queryString.parse(props?.history?.location?.search || "")
  );

  React.useEffect(() => {
    if (
      CONFIG.AUTOMATED_TEST_TENANT_CODE.indexOf(params.current?.tenantCode) ===
      -1
    )
      deviceMakeAndModel();

    authService.setBearerToken(params.current.bearerToken, params.current.kid);
    axiosInterceptors.init({
      unauthorizedAction: () => {
        window.history.replaceState(null, "", "/expired");
      },
    });

    const loaderConfig = themeLoader.load(
      params.current?.tenantCode,
      params.current?.locale
    );
    if (
      loaderConfig?.theme &&
      CONFIG.SUPPORTED_LANGUAGES.indexOf(loaderConfig.lang) === -1
    ) {
      themeConfig.lang = "en";
      window.history.replaceState(null, "", "/4xx");
    }
    LANG = loaderConfig.lang;
    setThemeConfig(loaderConfig);
  }, []); // eslint-disable-line

  const deviceMakeAndModel = () => {
    if (DeviceHelper.getMakeAndModel() === null) {
      setTimeout(() => {
        DeviceHelper.getDeviceDetails()
          .then((response) => {
            if (!response.vendor) {
              throw new Error({});
            } else {
              DeviceHelper.setMakeAndModel(response);
              const result = DeviceHelper.detectFoldableDevice(response);
              if (result === 1 || result === 2) {
                // foldable device
                DeviceHelper.setFoldableDevice();
                foldableDeviceChecks();
              }
            }
          })
          .catch((error) => {
            console.error("error ", error);
          });
      }, 800);
    } else {
      // foldable device check
      const result = DeviceHelper.detectFoldableDevice();
      if (result === 1 || result === 2) {
        DeviceHelper.setFoldableDevice();
        DeviceHelper.removeExperimentalFoldableCase();
        foldableDeviceChecks();
      }
    }
  };

  const foldableDeviceChecks = () => {
    if (
      DeviceHelper.isDeviceFoldable() &&
      DeviceHelper.detectFoldableDevice() === 1
    ) {
      // Fold device
      if (window.screen && window.screen.orientation.angle !== 0) {
        handleResizeAndOrientation();
      }
      // This will be removed in Phase 2
      else if (DeviceHelper.getFoldScreenType() !== 2) {
        handleResizeAndOrientation();
      }
      window.screen.orientation.addEventListener("change", (event) => {
        handleResizeAndOrientation();
      });
      window.addEventListener("resize", () => {
        handleResizeAndOrientation();
      });
    }
  };

  const handleResizeAndOrientation = () => {
    if (window.screen.orientation.angle !== 0) {
      setPopup({
        ...popup,
        show: true,
        title: LabelHelper[LANG].ERRORS.DEVICE_ORIENTATION_HEADING,
        subText: LabelHelper[LANG].ERRORS.DEVICE_ORIENTATION,
        isCTAdisabled: true,
        popupType: CONFIG.MODAL_POPUP.POPUP_TYPES.DEVICE_ORIENTATION,
      });
    } else if (DeviceHelper.getFoldScreenType() !== 2) {
      setPopup({
        ...popup,
        show: true,
        title: LabelHelper[LANG].ERRORS.FOLDABLE_TO_MAINSCREEN_HEADING,
        subText: LabelHelper[LANG].ERRORS.FOLDABLE_TO_MAINSCREEN,
        isCTAdisabled: true,
        popupType: CONFIG.MODAL_POPUP.POPUP_TYPES.SECONDARY_2_PRIMARY,
      });
    } else {
      setPopup({ ...popup, show: false });
    }
  };

  const CTAAction = () => {
    new ClipboardJS("#copyURLButton");
    const copyText = document.getElementById("copyURLButton");
    copyText.click();
  };

  const supportedBrowserList = () => {
    if (DeviceHelper.isAppleDevice()) {
      return CONFIG.SUPPORTED_BROWSERS;
    } else {
      const androidList = CONFIG.SUPPORTED_BROWSERS;
      if (CONFIG.SUPPORTED_BROWSERS.indexOf("Safari") > -1) {
        androidList.splice(CONFIG.SUPPORTED_BROWSERS.indexOf("Safari"), 1);
      }
      return androidList;
    }
  };

  return (
    <>
      {DeviceHelper.isChromeBrowser() ||
      DeviceHelper.isSafariBrowser() ||
      DeviceHelper.isSamsungInternetBrowser() ||
      DeviceHelper.isEdgeBrowser() ||
      DeviceHelper.isFirefoxBrowser() ||
      DeviceHelper.isElectronBrowser() ||
      DeviceHelper.isAndroidWebview() ? (
        <>
          {themeConfig?.theme ? (
            <Provider store={props.store}>
              <BrowserRouter>
                <C2PRoutes
                  history={props?.history}
                  theme={themeConfig.theme}
                  tenantCode={params.current.tenantCode}
                  logoSrc={themeConfig.logoSrc}
                  lang={themeConfig.lang}
                  locale={params.current.locale}
                  transactionId={params.current.transactionId}
                  helpLine={themeConfig.helpLine}
                  isHeaderVisible={themeConfig.isHeaderVisible ? true : false}
                  helpLineVisibility={
                    themeConfig.helpLineVisibility ? true : false
                  }
                  logoPosition={themeConfig.logoPosition}
                />
                <ModalPopup
                  lang={themeConfig.lang}
                  show={popup.show}
                  title={popup.title}
                  message={popup.subText}
                  isCTAdisabled={popup.isCTAdisabled}
                  popupType={popup.popupType}
                />
              </BrowserRouter>
            </Provider>
          ) : null}
        </>
      ) : (
        <>
          {themeConfig?.theme ? (
            <>
              <button
                id="copyURLButton"
                className="invisible"
                data-clipboard-text={CURRENT_URL}
              >
                Copy URL
              </button>
              <ModalPopup
                lang={themeConfig.lang}
                show={true}
                title={
                  LabelHelper[themeConfig.lang].NOT_SUPPORTED_BROWSER.TITLE
                }
                message={
                  LabelHelper[themeConfig.lang].NOT_SUPPORTED_BROWSER.MESSAGE
                }
                CTA={
                  LabelHelper[themeConfig.lang].NOT_SUPPORTED_BROWSER.COPYURL
                }
                clickEvent={CTAAction}
                isList={supportedBrowserList()}
              />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default App;
