import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import style from "./loadingView.module.scss";
import LabelHelper from "helpers/labelHelper";

const LoadingView = ({ text, show, slowAPIText, lang }) => {
  return (
    <div
      className={cs(
        {
          [style.loadingView]: true,
          [style.show]: show,
        },
        `d-none`
      )}
      data-cy="loading-view"
    >
      <div className={style.box}></div>
      <div className={style.box}></div>
      {text ? (
        <>
          <div className="container text-center">
            <h1 className="mt-4 mb-5 text-center" data-cy="loading-view-info">
              {text}
            </h1>
            {slowAPIText ? (
              <p className="text-justify mx-4">
                {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.LONGER_WAITING_TEXT}
              </p>
            ) : (
              <p className="text-center mx-4">
                {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.WAIT_TEXT}
              </p>
            )}
          </div>
          <div className={style.noteCls}>
            {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.NOTE_TEXT}
          </div>
        </>
      ) : null}
    </div>
  );
};

LoadingView.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool,
  slowAPIText: PropTypes.bool,
};

LoadingView.defaultProps = {
  show: false,
  slowAPIText: false,
};

export default LoadingView;
