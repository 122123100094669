import CONFIG from "../config";
import LabelHelper from "./labelHelper";

const TenantHelper = (page, tenant, ...res) => {
  switch (page) {
    case "DASHBOARD":
      HelperFunctions.dashboardPage(tenant, res);
      break;
    case "RESULT":
      HelperFunctions.resultPage(tenant, res);
      break;

    default:
      break;
  }
};

const HelperFunctions = {
  dashboardPage: (tenant, res) => {
    switch (tenant) {
      case CONFIG.TenantCodeThemeMapping.ORANGE_FR.name:
        if (
          HelperFunctions.testsCompleted(res[0]?.testArray)?.length ===
          res[0]?.testArray?.length
        ) {
          const element = document.getElementById("c2p-dashboardDoneArea");
          element.style.setProperty("display", "block", "important");
        }
        break;
      case CONFIG.TenantCodeThemeMapping.BNPPARIBAS_FR.name:
        if (
          HelperFunctions.testsCompleted(res[0]?.testArray)?.length ===
          res[0]?.testArray?.length
        ) {
          const element = document.getElementById("c2p-dashboardDoneArea");
          element.style.setProperty("display", "block", "important");
        }
        break;
      default:
        break;
    }
  },
  testsCompleted: (testArray) => {
    return (
      (testArray.length &&
        testArray.filter(
          (test) => test.status === CONFIG.TEST_STATUS_LABELS.FINAL
        )) ||
      []
    );
  },
  resultPage: (tenant, res) => {
    switch (tenant) {
      case CONFIG.TenantCodeThemeMapping.ORANGE_FR.name:
      case CONFIG.TenantCodeThemeMapping.BNPPARIBAS_FR.name:
      case CONFIG.TenantCodeThemeMapping.WINDTRE_IT.name:
        document.getElementById("GDPRtext").innerHTML =
          LabelHelper[res[0]].DIAGNOSE_LABEL_COMMON.GDPR_TEXT;
        document.getElementById("GDPRtext").classList.add("mt-3");
        break;

      default:
        break;
    }
  },
};

export default TenantHelper;
