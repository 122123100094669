import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import LabelHelper from "helpers/labelHelper";
import style from "./modalPopup.module.scss";
import CONFIG from "config";
import C2PButton from "components/button";

const ModalPopup = ({
  lang,
  show,
  closeModal,
  message,
  title,
  CTA,
  secondaryCTA,
  clickEvent,
  secondaryClickEvent,
  isCancellable,
  isList,
  popupType,
}) => {
  const bulletedList = () => {
    return isList.map((i, index) => <li key={i}>{i}</li>);
  };

  return (
    <Modal
      centered
      keyboard={false}
      show={show}
      backdrop="static"
      data-cy="error-modal"
      className={style.modalContainer + " px-2"}
      onHide={closeModal}
    >
      <Modal.Header
        className="border-0"
        closeButton={isCancellable}
      ></Modal.Header>

      <div className={style.imgContainer + " text-center"}>
        {popupType === CONFIG.MODAL_POPUP.POPUP_TYPES.DEVICE_ORIENTATION ? (
          <svg
            className={style.foldImg}
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2.5"
              y="28.5"
              width="59"
              height="33"
              stroke="#170F4F"
              strokeOpacity="0.5"
              strokeWidth="5"
            />
            <rect
              className={style.rectStroke}
              x="28.5"
              y="2.5"
              width="33"
              height="59"
              stroke="#170F4F"
              strokeWidth="5"
            />
            <path
              d="M10 18C10 13.5817 13.5817 10 18 10"
              stroke="#00BAC7"
              strokeWidth="5"
            />
          </svg>
        ) : null}
        {popupType === CONFIG.MODAL_POPUP.POPUP_TYPES.SECONDARY_2_PRIMARY ? (
          <svg
            className={style.foldImg}
            width="182"
            height="64"
            viewBox="0 0 182 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              className={style.rectStroke}
              x="113.5"
              y="2.5"
              width="33"
              height="59"
              stroke="#170F4F"
              strokeOpacity="0.5"
              strokeWidth="5"
            />
            <rect
              className={style.rectStroke}
              x="146.5"
              y="2.5"
              width="33"
              height="59"
              stroke="#170F4F"
              strokeWidth="5"
            />
            <rect
              className={style.rectStroke}
              x="2.5"
              y="2.5"
              width="33"
              height="59"
              stroke="#170F4F"
              strokeWidth="5"
            />
            <path d="M59 32H89" stroke="#33C8D2" strokeWidth="5" />
            <rect x="139" y="59" width="5" height="5" fill="white" />
            <rect x="139" width="5" height="5" fill="white" />
            <path
              d="M81.4854 23.5L89.9706 31.9853L81.4854 40.4706"
              stroke="#33C8D2"
              strokeWidth="5"
            />
          </svg>
        ) : null}
      </div>

      <Modal.Title data-cy="error-modal-heading" as={"h1"}>
        {title ? title : LabelHelper[lang].ERRORS.GENERIC_TITLE}
      </Modal.Title>

      <Modal.Body>
        <div className={style.modalBody}>
          <p>{message ? message : LabelHelper[lang].ERRORS.GENERIC_MESSAGE}</p>
          {isList ? (
            <ol className={style.orderList}>{bulletedList()}</ol>
          ) : null}
        </div>
      </Modal.Body>

      <Modal.Footer className="border-0 pt-0 d-block text-center">
        {CTA ? (
          <C2PButton
            text={CTA ? CTA : LabelHelper[lang].ERRORS.OK}
            dataHelper="error-modal-button"
            clickEvent={clickEvent}
          />
        ) : null}

        {secondaryCTA ? (
          <C2PButton
            text={secondaryCTA ? secondaryCTA : LabelHelper[lang].ERRORS.OK}
            dataHelper="error-modal-button2"
            clickEvent={secondaryClickEvent}
          />
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

ModalPopup.propTypes = {
  show: PropTypes.bool,
  isCancellable: PropTypes.bool,
  secondaryCTA: PropTypes.string,
  message: PropTypes.string,
  clickEvent: PropTypes.func,
  hidePopup: PropTypes.func,
  secondaryClickEvent: PropTypes.func,
  isCTAdisabled: PropTypes.bool,
  isList: PropTypes.array,
};

ModalPopup.defaultProps = {
  lang: "en",
  show: false,
  secondaryCTA: "",
  secondaryClickEvent: null,
  isCancellable: false,
  isCTAdisabled: false,
};

export default ModalPopup;
