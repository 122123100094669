import axios from "axios";
import authService from "services/auth";

const isS3UploadUrl = (url) => url.match(/\.s3\..*\.amazonaws\.com/);

const Interceptors = {
  init: ({ unauthorizedAction }) => {
    axios.interceptors.request.use(
      (config) => {
        const { token, keyIdentifier } = authService.bearerToken();
        if (token && !isS3UploadUrl(config.url)) {
          config.headers["Authorization"] = "Bearer " + token;
          config.headers["X-Key-Identifier"] = keyIdentifier;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          unauthorizedAction();
        } else {
          return Promise.reject(error);
        }
      }
    );
  },
};

export default Interceptors;
