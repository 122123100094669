import CONFIG from '../../config'

export const sendGTMDLPageView = async (lang, tenantkey, pageName, pageSubType) => {
  window.dataLayer.push({
    'event': 'C2PPageView',
    'lang': lang,
    'tenantkey': tenantkey,
    'pageTitle': pageName,
    'pageSubType': pageSubType,
  });
}

export const sendGTMDLModelError = async (lang, tenantkey, pageName) => {
  window.dataLayer.push({
    'event': 'C2PModelError',
    'lang': lang,
    'tenantkey': tenantkey,
    'pageTitle': pageName,
  });
}

export const sendGTMDLConversion = async (lang, tenantkey, conversionEvent, pageTitle) => {
  window.dataLayer.push({
    'event': conversionEvent,
    'lang': lang,
    'tenantkey': tenantkey,
    'pageTitle': pageTitle,
  });
}

export const sendTransactionId = async (transactionID) => {
  window.dataLayer.push({
    'transactionId': transactionID
  })
}
export const sendCompletionScore = async (lang, tenantkey, testCompleted, pageTitle, crackedProbability) => {
  window.dataLayer.push({
    'event': testCompleted,
    'lang': lang,
    'tenantkey': tenantkey,
    'pageTitle': pageTitle,
    'crackedProbability': crackedProbability
  });
}
export const getTenantKey = () => {
  return localStorage.getItem(CONFIG.TENANTKEY)
}
export const imagePredictionForGA = async (lang, tenantkey, imageUploaded, testName, classdetected, score) => {
  window.dataLayer.push({
    'event': imageUploaded,
    'lang': lang,
    'tenantkey': tenantkey,
    'tetsName': testName,
    'classDetected': classdetected,
    'classProbability': score
  });
}