import React from "react";
import {
  Route,
  Routes,
  Navigate,
  useParams,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import authService from "services/auth";
import Instruction from "./views/instruction";
import Dashboard from "./views/dashboard";
import MainDiagnosis from "./views/main-diagnosis";
import SpeakerDiagnosis from "./views/speaker-diagnosis";
import Completion from "./views/completion";
import MicDiagnosis from "./views/mic-diagnosis";
import Expired from "./views/expired";
import Error4xxPage from "./views/_4xx";
import Error5xxPage from "./views/_5xx";
import MainDiagnosisResult from "./views/main-diagnosis-result";
import Header from "components/header";
import "./style/transition.scss";
import CONFIG from "config";
import { useSelector } from "react-redux";
import { checkReduxForTestData } from "services/middlewareModel";

const C2PRoutes = (props) => {
  return (
    <>
      <Routes>
        <Route element={<ParentLayout {...props} />}>
          <Route index element={<Dashboard {...props} />} />
          <Route path="expired" element={<Expired {...props} />} />
          <Route path="4xx" element={<Error4xxPage {...props} />} />
          <Route path="5xx" element={<Error5xxPage {...props} />} />

          <Route element={<RestrictedRoute {...props} />}>
            <Route
              path="instruction/:type"
              element={<Instruction {...props} />}
            />
            <Route
              path="diagnosis/:type"
              element={<SelectDiagnosisTest {...props} />}
            />
            <Route
              path="result/:type"
              element={<MainDiagnosisResult {...props} />}
            />
            <Route
              path="completion/:type"
              element={<Completion {...props} />}
            />
          </Route>

          <Route path="*" element={<Navigate to="/4xx" />} />
        </Route>
      </Routes>
    </>
  );
};

const useAuth = () => {
  if (!authService.bearerToken()) return false;
  return true;
};

const RestrictedRoute = (props) => {
  const auth = useAuth();
  const { type: testName } = useParams();
  const { pathname } = useLocation();

  const pagePath = pathname.split("/")[1] || "";
  const isAcceptablePath = CONFIG.ACCEPTABLE_AUTH_PATHS.includes(pagePath);

  const isTestNameValid = Object.values(CONFIG.TEST_LABELS).some(
    (test) => test.name === testName
  );

  return auth && isAcceptablePath && isTestNameValid ? (
    <Outlet />
  ) : (
    <Navigate to="/4xx" />
  );
};

const SelectDiagnosisTest = (props) => {
  const params = useParams();

  const test = () => {
    switch (params?.type) {
      case CONFIG.TEST_LABELS.FRONT_SCREEN_CRACK_TEST.name:
        return <MainDiagnosis {...props} />;
      case CONFIG.TEST_LABELS.BACK_SCREEN_CRACK_TEST.name:
        return <MainDiagnosis {...props} />;
      case CONFIG.TEST_LABELS.PIXEL_DISPLAY_TEST.name:
        return <MainDiagnosis {...props} />;
      case CONFIG.TEST_LABELS.SPEAKER_TEST.name:
        return <SpeakerDiagnosis {...props} />;
      case CONFIG.TEST_LABELS.MIC_TEST.name:
        return <MicDiagnosis {...props} />;

      default:
        return <Error4xxPage {...props} />;
    }
  };

  return test();
};

const ParentLayout = (props) => {
  const { pathname } = useLocation();
  const { type: testName } = useParams();
  const navigate = useNavigate();
  const reduxTestData =
    useSelector((state) => state.DeviceAssessmentsReducer.testArray) || [];

  React.useEffect(() => {
    if (!pathname) return;

    const pagePath = pathname.split("/")[1] || "";
    const isAcceptablePath = CONFIG.ACCEPTABLE_AUTH_PATHS.includes(pagePath);
    if (isAcceptablePath && !checkReduxForTestData(testName, reduxTestData))
      navigate({
        pathname: `/`,
        search: props?.history?.location?.search,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={props?.history?.location}
          timeout={{ enter: 200, exit: 200 }}
          classNames="fade"
        >
          <>
            <Header pathname={pathname} {...props} />
            <Outlet />
          </>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default C2PRoutes;
