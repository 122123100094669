import RouteHelper from "helpers/routeHelper";
import {
  capturePhoneImage,
  startCaptureTimer,
} from "actions/deviceAssessmentsAction";
import CONFIG from "../../config";

export const checkReduxForTestData = (testName, reduxTestData) => {
  if (!reduxTestData.length) return false;

  const testStatus = reduxTestData.filter(
    (test) =>
      test.name === testName &&
      test.status === CONFIG.TEST_STATUS_LABELS.INITIAL
  );
  if (testStatus.length) {
    return true;
  }

  return false;
};

export const startNextTest = (testArray, isFirstRun) => {
  const noOfTests = testArray.length;
  let testName = "";

  if (!testName) {
    if (isFirstRun) {
      testName = testArray[0]?.name;
    } else {
      let temp = 0;
      testArray.forEach((e, i) => {
        if (e.status === CONFIG.TEST_STATUS_LABELS.FINAL) {
          let nextElement;
          if (i + 1 === noOfTests) {
            nextElement = testArray[temp];
          } else {
            nextElement = testArray[i + 1];
          }

          if (nextElement.status !== CONFIG.TEST_STATUS_LABELS.FINAL) {
            testName = nextElement.name;
          } else {
            temp += 1;
          }
        }
      });
    }
    return testName;
  }
};

export const resetDiagnosisReducers = (dispatch) => {
  dispatch(capturePhoneImage(false));
  dispatch(startCaptureTimer(false));
};

export const isAutomatedTest = (tenantCode) => {
  const checkLocalStorage = localStorage.getItem("isAutomatedTest");
  if (
    CONFIG.AUTOMATED_TEST_TENANT_CODE.includes(tenantCode) ||
    checkLocalStorage === "true"
  ) {
    return true;
  }
  return false;
};

export const routeToDashboard = (history, navigate) => {
  RouteHelper.replaceUrl({
    navigate,
    pathname: "/",
    search: history.location.search,
  });
};

export const isFeatureEnabled = (testName, featureName) => {
  return Object.values(CONFIG.TEST_LABELS).some(
    (test) => test.name === testName && test.features[featureName]
  );
};
