import worker from "workerize-loader!./odmWorker"; // eslint-disable-line import/no-webpack-loader-syntax
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import DeviceHelper from "helpers/deviceHelper";

const mockPredictions = [
  {
    bbox: [300, 250, 1200, 550],
    class: "cell phone",
    score: 0.9503824615478516,
  },
];
let ODMWorker;

const loadODMWorker = (callback) => {
  if (!ODMWorker) {
    if (DeviceHelper.isAppleDevice()) {
      loadODMForIOS()
        .then((result) => {
          ODMWorker = result;
          callback({ data: { modelStatus: "LOADED" } });
        })
        .catch((error) => {
          callback({ data: { modelStatus: "FAILED" } });
        });
    } else {
      ODMWorker = worker();
      ODMWorker.loadModel();
      ODMWorker.onmessage = (message) => {
        callback(message);
      };
    }
  }
};

const loadODMForIOS = () => {
  return cocoSsd.load({
    base: "lite_mobilenet_v2",
    modelUrl: "/model.json",
  });
};

const getODMWorker = () => {
  return ODMWorker;
};

const detect = (payload, isAutomatedTest = false) => {
  if (isAutomatedTest) return mockPredictions;
  else if (DeviceHelper.isAppleDevice()) return ODMWorker.detect(payload);
  else if (ODMWorker) ODMWorker.detect(payload);
};

const deviceDetection = {
  loadODMWorker,
  getODMWorker,
  detect,
};

export default deviceDetection;
