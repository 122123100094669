import LabelHelper from "../../helpers/labelHelper";
import DeviceHelper from "../../helpers/deviceHelper";

const synth = window.speechSynthesis;

const speak = (lang, locale, value) => {
  let voices = null;

  if (synth && synth.getVoices()) {
    voices = synth.getVoices();
  } else {
    alert("Speaker test is not supported in this browser");
    return false;
  }

  if (!LabelHelper[lang]) {
    console.error("[lang] unavailable");
    return false;
  }
  if (synth.speaking) {
    console.error("[speechSynthesis.speaking] Already Speaking");
    return false;
  }

  let finalLocale = locale;
  let defaultLocale = "en-US";
  let enUSInstance = null;

  if (DeviceHelper.isAndroid()) {
    finalLocale =
      (LabelHelper[lang].LOCALE[locale] &&
        LabelHelper[lang].LOCALE[locale]["ANDROID"]) ||
      "en_US";
    defaultLocale = "en_US";
  } else if (DeviceHelper.isAppleDevice()) {
    finalLocale =
      (LabelHelper[lang].LOCALE[locale] &&
        LabelHelper[lang].LOCALE[locale]["IOS"]) ||
      "en-US";
  }

  let finalValue = value.toString().split("");
  finalValue = finalValue.map((item) => {
    return LabelHelper[lang].DIGITS[item];
  });
  finalValue = finalValue.join(" ");

  let utterThis = new SpeechSynthesisUtterance(finalValue);
  utterThis.onend = function () {};

  utterThis.onerror = function (event) {
    console.error("[speechUtternace] ", event);
  };
  for (let i = 0; i < voices.length; i++) {
    if (voices[i].lang === defaultLocale) {
      enUSInstance = voices[i];
    }
    if (voices[i].lang === finalLocale) {
      utterThis.voice = voices[i];
      break;
    } else if (i === voices.length - 1) {
      utterThis.voice = enUSInstance;
    }
  }
  utterThis.lang = finalLocale;
  utterThis.pitch = 1;
  utterThis.rate = 0.5;
  synth.speak(utterThis);
};

export const randomValueGenerator = () => {
  let value = Math.floor(Math.random() * 1000);
  while (value.toString().length !== 3) {
    value = Math.floor(Math.random() * 1000);
  }
  return value;
};

const handleLocale = (locale) => {
  if (locale.includes("-")) {
    return locale;
  } else {
    let tempLocale = locale.split("_");
    return tempLocale.join("-");
  }
};

export const startSpeaker = (lang, locale, value) => {
  speak(lang, handleLocale(locale), value);
};
