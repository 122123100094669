import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import PageLoader from "../page-loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { faCallIcon, faChevronLeftIcon } from "assets/C2Picons";
import RouteHelper from "helpers/routeHelper";
import style from "./header.module.scss";
import LabelHelper from "helpers/labelHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CONFIG from "config";

const Header = ({
  pathname,
  helpLine,
  logoSrc,
  isHeaderVisible,
  helpLineVisibility,
  lang,
  history,
  logoPosition,
  ...res
}) => {
  const navigate = useNavigate();
  const fullPageLoaderReducer =
    useSelector((state) => state.DeviceAssessmentsReducer.fullPageLoader) || {};
  const [backButtonView, setBackButtonView] = React.useState(false);
  const [hideHeader, setHideHeader] = React.useState(false);

  React.useEffect(() => {
    if (pathname !== "/") {
      setBackButtonView(true);
    } else {
      setBackButtonView(false);
    }
    handleHideHeader(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleHideHeader = (pathname) => {
    const paths = (pathname && pathname.split("/")) || [];

    if (
      !hideHeader &&
      paths[1] === "diagnosis" &&
      [
        CONFIG.TEST_LABELS.FRONT_SCREEN_CRACK_TEST.name,
        CONFIG.TEST_LABELS.BACK_SCREEN_CRACK_TEST.name,
        CONFIG.TEST_LABELS.PIXEL_DISPLAY_TEST.name,
      ].includes(paths[2])
    ) {
      setHideHeader(true);
    } else if (hideHeader) {
      setHideHeader(false);
    }
  };

  const showLogoData = () => {
    return (
      <img
        src={logoSrc.default}
        alt="app-logo"
        aria-label="logo"
        className={style.logo}
        id="logo"
      />
    );
  };

  const showHelpData = () => {
    return (
      <button
        type="button"
        aria-label="call button"
        className={style.helpButton + " btn btn-link text-decoration-none p-0"}
        onClick={() => RouteHelper.callToHelp(helpLine)}
      >
        {LabelHelper[lang].HELP.TITLE}
        <FontAwesomeIcon
          className="ms-2"
          aria-label={"HELP"}
          icon={faCallIcon}
        />
      </button>
    );
  };

  const getHeaderContent = (position) => {
    switch (position) {
      case "LEFT":
        if (backButtonView)
          return (
            <FontAwesomeIcon
              onClick={() => navigate(-1)}
              className={style.chevronLeft}
              aria-label={"LEFT ICON"}
              icon={faChevronLeftIcon}
            />
          );
        else if (logoSrc.default && logoPosition === "left")
          return showLogoData();
        else if (helpLineVisibility && logoPosition === "right")
          return showHelpData();
        break;
      case "CENTER":
        if (logoSrc.default && logoPosition === "center") return showLogoData();
        break;
      case "RIGHT":
        if (
          helpLineVisibility &&
          !backButtonView &&
          (logoPosition === "left" || logoPosition === "center")
        )
          return showHelpData();
        else if (
          logoSrc.default &&
          ((backButtonView && logoPosition === "left") ||
            logoPosition === "right")
        )
          return showLogoData();
        break;
      default:
        return showLogoData();
    }
  };

  return (
    <>
      {!hideHeader && (
        <header
          id="header"
          aria-label="header"
          className={cs({
            [style.header]: true,
            "d-none": !isHeaderVisible,
            "container-fluid": true,
          })}
        >
          <div
            className={cs({
              [style.headerContainer + " row align-items-center"]: true,
              [style.shortHeader]: backButtonView,
            })}
          >
            <div className={style.headerItem + " col gx-2 text-start"}>
              {getHeaderContent("LEFT")}
            </div>
            <div className={style.headerItem + " col gx-2 text-center"}>
              {getHeaderContent("CENTER")}
            </div>
            <div className={style.headerItem + " col gx-2 text-end"}>
              {getHeaderContent("RIGHT")}
            </div>
          </div>
        </header>
      )}

      <PageLoader
        show={fullPageLoaderReducer.show}
        text={fullPageLoaderReducer.mainText}
        subText={fullPageLoaderReducer.subText}
        dynamicCountdown={fullPageLoaderReducer.dynamicCountdown}
        countDownText={fullPageLoaderReducer.countDownText}
        lang={lang}
      />
    </>
  );
};

Header.propTypes = {
  logo: PropTypes.string,
};

Header.defaultProps = {
  pathname: "/",
  logoSrc: "",
  isHeaderVisible: true,
  helpLineVisibility: true,
  lang: "en",
  logoPosition: "center",
};

export default Header;
