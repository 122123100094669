import CONFIG from "config";
import ActionsHelper from "helpers/actionsHelper";

const initialState = {
  slowAPI: false,
  testArray: [],
  startCaptureTimer: false,
  capturePhoneImage: false,
  ODMModel: false,
  OCRData: [],
  fullPageLoader: {
    show: true,
    mainText: "",
    subText: "",
    dynamicCountdown: 0,
    countDownText: "",
  },
  c2pSaaS: {
    "device-make-model": true,
    "blurr-detection": true,
    "dull-detection": true,
    "finger-detection": true,
    "face-detection": true,
    "fraud-detection": true,
  },
  cameraDeviceID: {
    "pixel-display-test": null,
    "is-back-screen-cracked": null,
  },
};

const DeviceAssessmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionsHelper.DEVICE_ASSESSMENTS.TEST_LIST_SUCCESS:
      return { ...state, testArray: action.response };
    case ActionsHelper.DEVICE_ASSESSMENTS.UPDATE_TESTS:
      const updatedArray = state.testArray.map((e) => {
        if (e.name === action.response) {
          e.status = CONFIG.TEST_STATUS_LABELS.FINAL;
        }
        return e;
      });
      return { ...state, testArray: updatedArray };
    case ActionsHelper.DEVICE_ASSESSMENTS.START_CAPTURE_TIMER:
      return {
        ...state,
        startCaptureTimer: action.response,
      };
    case ActionsHelper.DEVICE_ASSESSMENTS.CAPTURE_PHONE_IMAGE:
      return {
        ...state,
        capturePhoneImage: action.response,
      };
    case ActionsHelper.DEVICE_ASSESSMENTS.COCO_SSD_MODEL:
      return {
        ...state,
        ODMModel: action.response,
      };
    case ActionsHelper.DEVICE_ASSESSMENTS.OCR_DATA:
      return {
        ...state,
        OCRData: action.response,
      };
    case ActionsHelper.DEVICE_ASSESSMENTS.PAGE_LOADER:
      return {
        ...state,
        fullPageLoader: action.response,
      };
    case ActionsHelper.DEVICE_ASSESSMENTS.C2PSAAS:
      return {
        ...state,
        c2pSaaS: {
          "device-make-model": action.response["device-make-model"]
            ? true
            : false,
          "blurr-detection": action.response["blurr-detection"] ? true : false,
          "dull-detection": action.response["dull-detection"] ? true : false,
          "finger-detection": action.response["finger-detection"]
            ? true
            : false,
          "face-detection": action.response["face-detection"] ? true : false,
          "fraud-detection": action.response["fraud-detection"] ? true : false,
        },
      };
    case ActionsHelper.DEVICE_ASSESSMENTS.CAMERA_DEVICE_ID:
      return {
        ...state,
        cameraDeviceID: action.response,
      };
    case ActionsHelper.DEVICE_ASSESSMENTS.SLOW_API:
      return {
        ...state,
        slowAPI: action.response,
      };
    default:
      return state;
  }
};

export default DeviceAssessmentsReducer;
