const FRONT_SCREEN_CRACK_TEST = "is-front-screen-cracked";
const BACK_SCREEN_CRACK_TEST = "is-back-screen-cracked";
const PIXEL_DISPLAY_TEST = "pixel-display-test";
const SPEAKER_TEST = "speaker-test";
const MIC_TEST = "mic-test";

const CONFIG = {
  // APP
  SUPPORTED_LANGUAGES: [
    "en",
    "zh",
    "ko",
    "th",
    "it",
    "pt",
    "fr",
    "zhtw",
    "en_paymayaPH",
  ],
  ACCEPTABLE_AUTH_PATHS: ["instruction", "diagnosis", "completion", "result"],
  TEST_LABELS: {
    FRONT_SCREEN_CRACK_TEST: {
      name: FRONT_SCREEN_CRACK_TEST,
      features: {
        "device-make-model": true,
        "blurr-detection": true,
        "dull-detection": true,
        "finger-detection": true,
        "fraud-detection": true,
        "face-detection": true,
        "opaque-screen-detection": true,
      },
    },
    BACK_SCREEN_CRACK_TEST: {
      name: BACK_SCREEN_CRACK_TEST,
      features: {
        "device-make-model": false,
        "blurr-detection": false,
        "dull-detection": false,
        "finger-detection": false,
        "fraud-detection": false,
        "face-detection": false,
        "opaque-screen-detection": false,
      },
    },
    PIXEL_DISPLAY_TEST: {
      name: PIXEL_DISPLAY_TEST,
      features: {
        "device-make-model": false,
        "blurr-detection": false,
        "dull-detection": false,
        "finger-detection": false,
        "fraud-detection": false,
        "face-detection": false,
        "opaque-screen-detection": false,
      },
    },
    SPEAKER_TEST: {
      name: SPEAKER_TEST,
      features: {},
    },
    MIC_TEST: {
      name: MIC_TEST,
      features: {},
    },
  },
  TenantCodeThemeMapping: {
    TEST: {
      name: "bolttech",
      helpLine: "+852 5803 2496",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: true,
      },
    },
    BOLTTECH_HK: {
      name: "bolttech",
      helpLine: "+852 5803 2496",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: true,
      },
    },
    BOLT: {
      name: "bolttech",
      helpLine: "+852 5803 2496",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "left",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: true,
      },
    },
    RECONNECTS_HK: {
      name: "bolttech",
      helpLine: "+852 5803 2496",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    WIND: {
      name: "wind",
      helpLine: "+852 3008 8282",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    HKT: {
      name: "bolttech",
      helpLine: "+852 5803 2496",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    UPLUS: {
      name: "lguplus",
      helpLine: "1544 6622",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    FRANK_FS_TH: {
      name: "linefrank",
      helpLine: "+6624304077",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "left",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    FWD_HK: {
      name: "fwdGI",
      helpLine: " +852 3123 3123",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    FWDLIFE_HK: {
      name: "fwdLIFE",
      helpLine: " +852 5803 2496",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    THREEHK_HK: {
      name: "threehk",
      helpLine: "+852 5803 2497",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    WINDTRE_IT: {
      name: "windtreIT",
      helpLine: "+852 5803 2497",
      isHeaderVisible: true,
      helpLineVisibility: false,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    HOMECREDIT_FS_PH: {
      name: "homecreditPh",
      helpLine: "",
      isHeaderVisible: false,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    THREEIE_IE: {
      name: "threeIe",
      helpLine: "",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    HSBC_FS_MY: {
      name: "bolttech",
      helpLine: "03-92124314",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    PAYMAYA_PH: {
      name: "paymayaPh",
      helpLine: "+63 2 84643488",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "left",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: true,
      },
      hasUniqueTranslations: true,
      conflictingLocale: {
        en_PH: "en_paymayaPH",
      },
    },
    BNPPARIBAS_FR: {
      name: "bnpParibasFR",
      helpLine: "",
      isHeaderVisible: true,
      helpLineVisibility: false,
      logoPosition: "left",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: true,
      },
    },
    ORANGE_FR: {
      name: "orangeFR",
      helpLine: "",
      isHeaderVisible: true,
      helpLineVisibility: false,
      logoPosition: "left",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: true,
      },
    },
    HYUNDAICARD_KR: {
      name: "hyundaiCard",
      helpLine: "+82-1670-8884",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "center",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
    AIS_TH: {
      name: "aisTh",
      helpLine: " 098-585-1175",
      isHeaderVisible: true,
      helpLineVisibility: true,
      logoPosition: "left",
      hasColouredIllustrations: {
        [FRONT_SCREEN_CRACK_TEST]: false,
      },
    },
  },
  TEST_STATUS_LABELS: {
    INITIAL: "PENDING",
    FINAL: "SUBMITTED",
  },
  AUTOMATED_TEST_TENANT_CODE: ["TEST", "SMOKE_TEST"],
  GA_TAGS: {
    [FRONT_SCREEN_CRACK_TEST]: "CrackScreenTest",
    [BACK_SCREEN_CRACK_TEST]: "BackScreenTest",
    [PIXEL_DISPLAY_TEST]: "PixelTest",
    [SPEAKER_TEST]: "SpeakerTest",
    [MIC_TEST]: "MicTest",
  },
  TENANTKEY: "TENANT_CODE",
  MAKEANDMODEL: "MAKE_AND_MODEL",
  MODAL_POPUP: {
    POPUP_TYPES: {
      DEVICE_ORIENTATION: "DEVICE_ORIENTATION",
      PRIMARY_2_SECONDARY: "PRIMARY_2_SECONDARY",
      SECONDARY_2_PRIMARY: "SECONDARY_2_PRIMARY",
    },
  },
  // DIAGNOSIS
  ACCEPTABLE_PREDICTION_SCORE: 0.7,
  TIMER_COUNTDOWN: 3,
  OVERLAY_TIMEOUT: 200,
  ODM_FPS: 5,
  IMAGE_CROP_DETECTION_CHECK: 5,
  BoundaryBoxColors: {
    FAIL: "#FF0000",
    PASS: "#2FFF00",
  },
  ACCEPTABLE_PREDICTION_CLASSES: {
    video: ["cell phone", "laptop", "tv"],
    image: {
      [FRONT_SCREEN_CRACK_TEST]: [
        "cell phone",
        "laptop",
        "tv",
        "suitcase",
        "microwave",
        "oven",
      ],
      [BACK_SCREEN_CRACK_TEST]: ["cell phone", "laptop", "tv"],
      [PIXEL_DISPLAY_TEST]: ["cell phone", "laptop", "tv"],
    },
  },
  LETTER_LIST: [
    "A",
    "B",
    "D",
    "E",
    "G",
    "H",
    "K",
    "M",
    "N",
    "R",
    "S",
    "T",
    "U",
    "X",
    "Z",
  ],
  MAIN_DIAGNOSIS_LABELS: {
    CANVAS1: "canvas1",
    CANVAS2: "canvas2",
  },
  SUPPORTED_BROWSERS: [
    "Google Chrome",
    "Safari",
    "Samsung Internet",
    "Edge",
    "Brave",
    "Firefox",
  ],
  // RESULT PAGE
  SHOW_TEST_IMAGE_IN_RESULT: false,
  DEVICE_MAKE_AND_MODEL: {
    IS_ANDROID: true,
    IS_IOS: true,
  },
  isExperimentalFoldableCaptureAllowed: true,
};

Object.freeze(CONFIG);

export default CONFIG;
