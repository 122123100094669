import CONFIG from "config";
import { faMirrorIcon, faLitroomIcon } from "assets/C2Picons";

const en_DIGITS = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
};

const LabelHelper = {
  en: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "Cracked Screen",
        BACK_SCREEN_CRACKED: "Back Cover Test",
        PIXEL_DAMAGE: "Pixel Test",
        SPEAKER_TEST: "Speaker Test",
        MIC_TEST: "Mic Test",
      },
      STARTING: {
        HEADING: "Welcome to your device assessment tool.",
        SUBCONTENT:
          "To help us determine your device protection eligibility, some assessments may be required.",
      },
      INPROCESS: {
        HEADING:
          "You have <> tests remaining before finishing your application",
        SUBCONTENT:
          "You can click on the tests icons you have not finished yet or can directly click on “Continue test”.",
      },
      FINISHED: {
        HEADING: "Congratulations! You have completed all tests.",
        HEADING2: "Thank you for completing cracked screen test.",
        SUBCONTENT:
          "You will soon receive an email with the results of your test.",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        SUBCONTENT4: "We are analyzing your screen. Please wait.",
      },
      MISC: {
        DONE: "DONE",
        CTA: "Let’s begin",
        ASSESSMENT: "Assessment",
        REMAINING_ASSESSMENTS: "Assessments remaining: ",
        COMPLETED: "COMPLETED",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Cracked Screen Test",
          DESCRIPTION:
            "The following test will detect if your device contains any cracks on your front screen. It will take about <b>3 minutes</b> to complete.",
          LIST: {
            SHOW: true,
            HEADING: "What will you need?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "A clean medium-sized mirror",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "A well-lit room",
              },
            ],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "First, face your phone in front of a mirror.",
              SECONDARY_TEXT:
                "Hold your phone in front of a mirror, and stand approximately an arms length away.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Make sure your phone is tilted forward.",
              SECONDARY_TEXT:
                "Slightly tilt your phone forward to make phone detection and photo-taking easier.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT:
                "Align your phone within the white frame, and hold still.",
              SECONDARY_TEXT:
                "Slowly move the phone around until the <span> green </span> border is visible, then hold still to capture the photo.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Finally, review your photo and submit.",
              SECONDARY_TEXT:
                "Ensure that the entire phone is in the picture, and submit the photo",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Cracked Screen Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Remove the phone case",
              SECONDARY_TEXT:
                'First, please remove the phone case and read the instructions. After that, please click on the "Start Back Cover Test" to initiate the test.',
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Let's test your phone's back cover",
              SECONDARY_TEXT: "Please find a mirror to implement the test.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display the phone back cover on the mirror and looking into the screen, try to fit the phone image in given frame until phone boundaries turn green.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the current position until the picture is taken and the result is displayed back to you.",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "Review and Submit",
              SECONDARY_TEXT:
                'Ensure the photo is clear and complete. You can then submit the picture by clicking on "Yes, Upload this photo".',
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone display",
              SECONDARY_TEXT:
                "First, please find a mirror and read the instructions. After that, please click on “Start Pixel Test” to initiate the test.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display your phone’s screen within the displayed frame. Once your device is in the correct position, the frame will turn green.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the correct position until the picture is taken and the result is displayed white to you.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Review and submit",
              SECONDARY_TEXT:
                "Ensure the photo is clear and completely within the frame. You can then submit the picture by clicking on “Yes, upload this photo”.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone speaker",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Listen and input the digits",
              SECONDARY_TEXT:
                "Carefully listen the 3 digits number played on your speaker and input the same number when asked. You can listen the 3 digits again if you like.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone Mic",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Speak displayed digits into the microphone",
              SECONDARY_TEXT:
                "Find a noise-free place and keep the mic near your mouth.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Screen Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full screen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s screen within the frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Back Cover Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full backscreen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point the phone backscreen at a mirror",
        POSITION_PHONE_IN_FRAME: "Position your phone in this frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Pixel Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full screen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s display within the frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Speaker Test",
        TYPE_NUMBERS: "Type the 3 digits number you just heard, in order",
        SUBMIT: "Submit",
        LISTEN_AGAIN: "Listen Again",
        LISTEN_TO_DIGITS: "Please click on 'Begin' button when you are ready",
        POPUP_HEADING: "Are you sure ! You entered <numbers>",
        POPUP_HEADING_2: "Are you sure !",
        BUTTON_TO_LISTEN: "Begin",
        CTA_Yes: "Yes",
        CTA_No: "No",
        CTA_Change: "Change",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Mic Test",
        MAIN_TEXT: "Please clearly speak the below digits into the phone",
        PLAY_RECORDING: "Play recording",
        SECONDARY_TEXT: "Say",
        BEGIN_BUTTON: "Tap & Speak",
        FINISH_BUTTON: "Done",
        CHECKBOX_TEXT: "My mic doesn't work",
        SUBMIT_BUTTON: "Yes, submit",
        RETRY_BUTTON: "No, speak again",
        MIC_PERMISSION_DENIED:
          "Please allow the app to access phone's Microphone for the test.",
        MIC_PERMISSION_DENIED_CTA: "Allow",
        MIC_IN_USE:
          "Some other app/s is using the mic, please close the other app/s before proceeding further",
        MIC_IN_USE_CTA: "Confirm",
        BEFORE_YOU_BEGIN: "BEFORE YOU BEGIN",
        BEFORE_YOU_BEGIN_TEXT:
          "You will be asked to allow  microphone access and we highly recommend you to do so else you will not be able to complete the mic test",
        BEFORE_YOU_BEGIN_CTA: "Allow",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      SCREEN_TEST: "SCREEN TEST",
      HOW_IT_WORKS: "How it works",
      START_TEST: "Start test",
      GENERIC_WAITING: "One moment please",
      UPLOAD_PHOTO: "Yes, Upload this photo",
      NO_RETAKE_PHOTO: "No, Retake the photo",
      RETAKE_PHOTO: "Retake the photo",
      SHOW_TIPS: "Show tips",
      CAMERA_STARTING_TXT: "Wait while the camera is starting",
      MOVE_PHONE_LEFT: "Slowly move your phone left.",
      MOVE_PHONE_RIGHT: "Slowly move your phone right.",
      MOVE_PHONE_TOP:
        "Slowly tilt your phone top a bit upwards to fit it in frame",
      MOVE_PHONE_BOTTOM:
        "Slowly tilt your phone top a bit downwards and fit it in frame",
      TOO_FAR_MOVE_CLOSER: "Too far away, Move the phone closer to the mirror.",
      ABOUT_TO_CAPTURE: "Hold your phone still until the image is captured",
      VIDEO_SELECT_HEADING: "BEFORE YOU BEGIN",
      VIDEO_SELECT_PARAGRAPH:
        "Make sure your phone's main camera is being used to perform the test.",
      VIDEO_SELECT_QUESTION: "Is this your phone's main camera?",
      VIDEO_SELECT_ACCEPT_BUTTON: "Yes, use this camera",
      VIDEO_SELECT_REJECT_BUTTON: "No, change camera",
      EXPERIMENTAL_FOLDABLE_TEXT:
        "We are unable to detect a device, your screen will be automatically captured in 5 seconds.",
      GDPR_TEXT:
        "Please ensure that nothing appears in the image except your phone. Ensure that your face is not within the frame.",
      NO_DEVICE_DETECTED:
        "Sorry, the device can't be detected in image. Please try again.",
      LOW_CONFIDENCE_SCORE:
        "Sorry, the image is blurry. Please try keeping the device steady when the photo is being captured.",
      PERSON_DETECTED:
        "Sorry, it seems there is a face on the picture. Please make sure that only your device is captured in the photo",
      FINGER_DETECTED:
        "Sorry, there is (a) finger(s) on the picture. Please ensure that no finger is on screen during the test",
      MULTIPLE_CELLPHONE_DETECTED:
        "Multiple devices are detected, please capture again.",
      DULL_AND_BLURRY_IMAGE:
        "The image is blurred and dark. Please try again and keep your phone steady while taking the photo.",
      BLURRY_IMAGE:
        "The image is blurred. Please try again and keep your phone steady while taking the photo.",
      DULL_IMAGE:
        "The image is dark. Please try again and keep your phone steady while taking the photo.",
      MIRROR_FRAUD: "Caution ! Please capture the right device using a mirror",
      OPAQUE_SCREEN_CHECK:
        "Device screen must be completely black in captured image, please re-take and hold your phone still unless image is completely captured.",
      API_IMAGE_PII_CHECK:
        "Sorry, it seems there is a face on the picture. Please make sure that only your device is captured in the photo",
      FRAUD_USER_INFO:
        "Please hold your phone still until the image is captured",
      DEVICE_NOT_DETECTED: "Sorry, the device could not be detected.",
      DIAGNOSIS_HELP_HEADING: "You may try to perform the following:",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE: "Clean the camera lens and mirror for better results.",
          IMG: "icon_camera.svg",
        },
        {
          TITLE: "Ensure the background does not contain any other devices.",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "Avoid covering the screen with fingers.",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "Perform the test in well lit environment.",
          IMG: "icon_lit.svg",
        },
        {
          TITLE:
            "Make sure you keep your phone steady and within the white frame.",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "Not sure how to perform the test?",
        TEXT: "View instructions",
        CTA: "Try Again",
      },
      CROPPED_IMAGE:
        "Unable to capture full device, please ensure device is within white border. Try again.",
      IOS_DIALOG_BOX: "You can now perform the test.",
      NEXT: "Next",
      UPLOADING_IMAGE: "Uploading Image...",
      LONGER_WAITING_TEXT:
        "This may take longer than usual as we are currently experiencing high traffic in our server. please wait a moment",
      WAIT_TEXT: "Please wait a moment",
      NOTE_TEXT:
        "Note: Please perform upload again if it is taking more than 60 seconds",
    },
    COMPLETION: {
      TITLE: "Done",
      THANK_YOU:
        "Thank You for submitting the test. Please click on start next test to perform next test or go to dashboard to select the next test.",
      START_NEXT_TEST: "Start next test",
      GO_TO_DASHBOARD: "Go to dashboard",
      CLOSE: "You can now continue browsing or simply close your browser.",
    },
    ERRORS: {
      GENERIC_TITLE: "Sorry, something went wrong.",
      GENERIC_MESSAGE:
        "We are working on getting this fixed as soon as we can. ",
      OK: "OK",
      UNSUPPORTED_BROWSER_TITLE: "Important Message",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "The camera could not be accessed in your current browser. Please re-open the link using Safari browser.",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "The camera could not be accessed in your current browser. Please re-open the link using Chrome browser.",
      UNSUPPORTED_BROWSER_CTA: "Copy URL",
      FULLSCREEN_TITLE: "Important Message",
      IMPORTANT_MESSAGE: "Important Message",
      FULLSCREEN_SUBTEXT:
        "The diagnosis test need to perform in the full screen mode.",
      FULLSCREEN_CTA: "Open Full Screen Mode",
      CAMERA_ERROR: "Error in accessing camera",
      ENABLE_CAMERA: "Please allow the app to access phone's camera.",
      DEVICE_ORIENTATION_HEADING: "Please rotate your device to portrait mode",
      DEVICE_ORIENTATION:
        "It appears that you are currently on landscape mode, please rotate your device to portrait mode in order to proceed.",
      FOLDABLE_TO_MAINSCREEN_HEADING:
        "Please unfold your device and continue on your main screen",
      FOLDABLE_TO_MAINSCREEN:
        "It appears that you are currently viewing this on your secondary screen, please unfold your device and view this on the main screen in order to proceed.",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Please continue the test on the secondary screen",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "HELP",
    },
    EXPIRED: {
      TITLE: "The link has expired",
      PRIMARY_TEXT: "To generate a new link, please call our contact center",
    },
    _4xx: {
      TITLE: "404 - PAGE NOT FOUND",
      DESCRIPTION:
        "The page you are looking for cannot be found. Please ensure that you have entered the address correctly. If the address is correct, the page may be temporarily unavailable or may have been removed.",
      RETURN: "Back to Homepage",
      HELPLINE: "For any assistance, please call our Service Hotline: ",
    },
    _5xx: {
      TITLE: "500 - Sorry, something went wrong.",
      DESCRIPTION: "We are working on getting this fixed as soon as we can.",
      RETURN: "Back to Homepage",
      HELPLINE: "For any assistance, please call our Service Hotline: ",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "Loading...",
      COCO_MODAL_LOADING_TEXT:
        "Please wait a moment. Your test will start soon.",
      COCO_MODAL_LOADING_SUBTEXT: "Please stay on this page.",
      REDIRECT_LOADING_TEXT: "We're checking your phone's condition now.",
      REDIRECT_LOADING_SUBTEXT: "Please stay on this page.",
      COUNTDOWN_TEXT:
        "Please wait for <count> seconds as we’re analyzing your phone’s condition now.",
      IMAGE_CHECK_TEXT:
        "Please wait for a moment while we are analyzing the image.",
    },
    DIGITS: en_DIGITS,
    LOCALE: {
      "en-HK": {
        ANDROID: "en_US",
        IOS: "en-US",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "Sorry, this browser is not supported",
      MESSAGE: `Please re-launch link in one of the following:`,
      COPYURL: "Copy Link",
    },
  },
  zh: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "屏幕破裂",
        BACK_SCREEN_CRACKED: "電話背面測試",
        PIXEL_DAMAGE: "螢幕亮點測試",
        SPEAKER_TEST: "揚聲器測試",
        MIC_TEST: "麥克風測試",
      },
      STARTING: {
        HEADING: "歡迎來到你的設備測試工具。",
        SUBCONTENT: "請按圖示或「開始測試」按鈕開始進行診斷。",
        BELOW_CONTENT: "開始測試",
      },
      INPROCESS: {
        HEADING: "你還需要進行 <> 個測試才可完成整個申請。",
        SUBCONTENT:
          "你可以按尚未完成的測試圖標或直接按 “繼續測試” 的按鈕以進行測試。",
        BELOW_CONTENT: "繼續測試",
      },
      FINISHED: {
        HEADING: "恭喜你! 你已完成所有測試。",
        SUBCONTENT: "我們會盡快通知您測試結果。 你現在可關閉此視窗。",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        BELOW_CONTENT: "你已完成的測試:",
      },
      MISC: {
        DONE: "完成",
        CTA: "開始",
        ASSESSMENT: "評估",
        REMAINING_ASSESSMENTS: "檢測未完成: ",
        COMPLETED: "完成",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "屏幕測試",
          DESCRIPTION:
            "以下測試將檢測您的裝置前屏幕上有沒有任何裂縫。需時約 <b> 3 分鐘 </b>。",
          LIST: {
            SHOW: true,
            HEADING: "你需要什麼？",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "乾淨的鏡子",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "光線充足的房間",
              },
            ],
          },
          CTA: "開始教程",
          CTA_2: "跳過教程",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "分步 骤指南",
          CTA: "下一步",
          CTA2: "後退",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "首先，將手機對著鏡子。",
              SECONDARY_TEXT: "把手機放在鏡子前，站在大約一臂遠的地方。",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "確保您的手機向前傾斜。",
              SECONDARY_TEXT:
                "將手機稍微向前傾斜，以便更輕鬆地檢測手機和拍照。",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "將手機對準白框，並保持不動。",
              SECONDARY_TEXT:
                "慢慢移動手機，直到看到 <span>綠色</span>邊框，然後保持不動以拍攝照片。",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "最後，查看您的照片並提交。",
              SECONDARY_TEXT: "確保整部手機都在圖片中，並提交照片",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "您已準備好開始了！",
          DESCRIPTION: "您已經完成了屏幕測試的教程。準備好後即可開始測試。",
          CTA: "開始測試",
          CTA_2: "再次閱讀教程",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "移除電話保護殼",
              SECONDARY_TEXT:
                "首先, 請移除電話保護殼並閱讀整個用法說明，然後按 “開始電話背面測試”進行此測試",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "測試你的電話背面",
              SECONDARY_TEXT: "請找一塊鏡子。",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "對準你的電話背面",
              SECONDARY_TEXT:
                "請把鏡子上電話背面的倒影對準白色框。當綠色框出現時, 代表電話背面的位置正確。",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "保持手機不動",
              SECONDARY_TEXT: "請保持手機在正確位置直至拍照。",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "查看及提交",
              SECONDARY_TEXT:
                "當你確定上載時，請確保整個電話背面能清晰顯示在照片上，並按 “對，請上載這張照片”。",
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "測試你的電話螢幕像素",
              SECONDARY_TEXT:
                "請先找一塊鏡子並閱讀整個用法說明，然後按 “開始螢幕亮點測試 ”進行此測試。",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "對準你的電話屏幕",
              SECONDARY_TEXT:
                "請把鏡子上電話屏幕的倒影對準白色框。當綠色框出現時, 代表電話屏幕的位置正確。",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "保持手機不動",
              SECONDARY_TEXT:
                "請保持手機在正確位置直至電話屏幕轉為白色及拍照。",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "查看及提交",
              SECONDARY_TEXT:
                "當你確定上載時，請確保整個電話屏幕能清晰顯示在照片上，並按 “對，請上載這張照片”。",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "讓我們測試一下您的手機揚聲器",
              SECONDARY_TEXT: "請卸下已連接的耳機和任何藍牙設備",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "聆聽並輸入數字",
              SECONDARY_TEXT:
                "仔細聆聽揚聲器上播放的3位數字，並在詢問時輸入相同的數字。如有需要，您可以再次聽那3位數字多一次。",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "讓我們測試一下您的手機麥克風",
              SECONDARY_TEXT: "請卸下已連接的耳機和任何藍牙設備",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "請向麥克風說出所顯示的數字",
              SECONDARY_TEXT: "請找個無噪音的地方並將麥克風靠近您的嘴",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "開始屏幕測試",
        TOO_CLOSE_MOVE_FRAME: "太近了，請確保整個電話螢幕對準框架中",
        POINT_SCREEN_AT_MIRROR: "將電話螢幕面向鏡子",
        POSITION_PHONE_IN_FRAME: "對準你的電話屏幕",
        CONFIRM: "您可以在這張照片中清楚地看到您的手機<device>嗎？",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "開始電話背面測試",
        TOO_CLOSE_MOVE_FRAME: "太近了，請確保整個電話背面對準框架中",
        POINT_SCREEN_AT_MIRROR: "將電話背面面向鏡子",
        POSITION_PHONE_IN_FRAME: "對準你的電話於框架",
        CONFIRM: "您可以在這張照片中清楚地看到您的手機<device>嗎？",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "開始螢幕亮點測試",
        TOO_CLOSE_MOVE_FRAME: "太近了，請確保整個電話螢幕對準框架中",
        POINT_SCREEN_AT_MIRROR: "將電話螢幕面向鏡子",
        POSITION_PHONE_IN_FRAME: "對準你的電話屏幕",
        CONFIRM: "您可以在這張照片中清楚地看到您的手機<device>嗎？",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "開始揚聲器測試",
        TYPE_NUMBERS: "依次輸入您剛才聽到的3位數字",
        SUBMIT: "提交",
        BUTTON_TO_LISTEN: "開始",
        CTA_Yes: "是",
        CTA_No: "不是",
        CTA_Change: "更改",
        LISTEN_TO_DIGITS: "準備好後，請點擊開始按鈕",
        LISTEN_AGAIN: "再聽一遍",
        POPUP_HEADING: " 您確定輸入的信息嗎？ 您輸入的信息是 <numbers>",
        POPUP_HEADING_2: "您確定 !",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "開始麥克風測試",
        MAIN_TEXT: "請向電話清楚地說出以下數字",
        PLAY_RECORDING: "播放錄音",
        SECONDARY_TEXT: "請說出",
        BEGIN_BUTTON: "點擊並完成",
        FINISH_BUTTON: "完成了",
        CHECKBOX_TEXT: "我的麥克風用不了",
        SUBMIT_BUTTON: "是，請提交",
        RETRY_BUTTON: "不，要求再錄",
        MIC_PERMISSION_DENIED: "請允許此應用程式使用您手機的麥克風以進行測試",
        MIC_PERMISSION_DENIED_CTA: "允許",
        MIC_IN_USE:
          "有其他的應用程式正在使用麥克風，請先關閉其他程式，然後再繼續操作",
        MIC_IN_USE_CTA: "確認",
        BEFORE_YOU_BEGIN: "在你開始之前",
        BEFORE_YOU_BEGIN_TEXT:
          "系統將要求您允許使用麥克風，強烈建議您這樣做，否則您將無法完成麥克風測試",
        BEFORE_YOU_BEGIN_CTA: "允許",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      SCREEN_TEST: "屏幕測試",
      HOW_IT_WORKS: "怎樣測試",
      SCREEN: "將屏幕對準鏡子z",
      START_TEST: "開始測試",
      GENERIC_WAITING: "請稍後",
      UPLOAD_PHOTO: "是, 上載相片",
      NO_RETAKE_PHOTO: "不是, 重新拍照",
      RETAKE_PHOTO: "重新拍照",
      SHOW_TIPS: "顯示提示",
      CAMERA_STARTING_TXT: "請等待相機開啟",
      MOVE_PHONE_LEFT: "把你的電話向左面移動",
      MOVE_PHONE_RIGHT: "把你的電話向右面移動",
      MOVE_PHONE_TOP: "慢慢將手機底部向上傾斜並對準框架",
      MOVE_PHONE_BOTTOM: "慢慢將手機頂部向下傾斜並對準框架",
      TOO_FAR_MOVE_CLOSER: "太遠了，請把你的電話移近鏡子",
      ABOUT_TO_CAPTURE: "靜止不動，直到完成拍攝為止",
      GDPR_TEXT:
        "Please ensure that nothing appears in the image except your phone. Ensure that your face is not within the frame.",
      NO_DEVICE_DETECTED: "抱歉，無法在相片中檢測到您的裝置。 請再試一次。",
      LOW_CONFIDENCE_SCORE:
        "抱歉，圖像模糊。 請在拍攝照片時，嘗試保持裝置穩定。",
      EXPERIMENTAL_FOLDABLE_TEXT:
        "我們無法偵測到任何裝置，將會於5秒內擷取您的屏幕畫面",
      PERSON_DETECTED:
        "抱歉，照片上好像拍到了一張臉。 請確保相片中僅拍了您的裝置",
      FINGER_DETECTED:
        "抱歉，照片上好像拍到了手指。 請確保在測試期間沒有手指在屏幕上",
      MULTIPLE_CELLPHONE_DETECTED: "檢測到多部裝置，請重新拍照。",
      DULL_AND_BLURRY_IMAGE: "相片暗淡模糊，請穩握手機重新拍攝。",
      BLURRY_IMAGE: "相片模糊，請穩握手機重新拍攝。",
      DULL_IMAGE: "相片暗淡，請穩握手機重新拍攝。",
      MIRROR_FRAUD: "警告！請使用鏡子為正確的手機屏幕拍照。",
      OPAQUE_SCREEN_CHECK:
        "照片中的手機屏幕必須是全黑的，如不是，請保持手機穩定並重新拍攝。",
      API_IMAGE_PII_CHECK:
        "抱歉，照片上好像拍到了一張臉。 請確保相片中僅拍了您的裝置",
      FRAUD_USER_INFO: "在拍攝之前，請不要移動手機",
      DEVICE_NOT_DETECTED: "抱歉，無法在相片中檢測到您的裝置。 請再試一次",
      DIAGNOSIS_HELP_HEADING: "抱歉，無法檢測到裝置。",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE: "您可以嘗試執行以下操作：",
          IMG: "icon_camera.svg",
        },
        {
          TITLE: "清潔相機鏡頭以獲得更好的效果。",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "確保相片中的背景不包含其他裝置。",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "避免用手指遮住屏幕。",
          IMG: "icon_lit.svg",
        },
        {
          TITLE: "在光線充足的環境中進行測試。",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "確保您的手機保持穩定並置於白框內。",
        TEXT: "不確定如何進行測試？",
        CTA: "查看說明",
      },
      CROPPED_IMAGE: "無法拍到完整裝置，請確保裝置在白色框內，然後再試一次。",
      IOS_DIALOG_BOX: "您現在可以進行測試",
      NEXT: "下一步",
      UPLOADING_IMAGE: "上傳圖片..",
      LONGER_WAITING_TEXT:
        "這可能需要比平時更長的時間，因為伺服器現有比較繁忙。 請稍等片刻",
      WAIT_TEXT: "請稍後",
      NOTE_TEXT: "注意：如果超過60秒請重新上傳",
    },
    COMPLETION: {
      TITLE: "完成",
      THANK_YOU:
        "感謝您已提交測試。 請按”開始下一個測試”以進行下個測試，或回到主畫面以選擇測試。",
      START_NEXT_TEST: "開始下一個測試",
      GO_TO_DASHBOARD: "回到主畫面",
      CLOSE: "您可以繼續瀏覽或直接關閉瀏覽器。",
    },
    ERRORS: {
      GENERIC_TITLE: "抱歉，出了一些問题",
      GENERIC_MESSAGE: "我们正在努力修復此問题",
      OK: "知道",
      UNSUPPORTED_BROWSER_TITLE: "重要訊息",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "你正在使用的瀏覽器並不授權使用電話相機，請使用Safari瀏覽器重新打開連結。",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "您當前的瀏覽器無法使用相機。 請使用Chrome瀏覽器重新打開網站連結。",
      UNSUPPORTED_BROWSER_CTA: "複製連結",
      FULLSCREEN_TITLE: "重要訊息",
      IMPORTANT_MESSAGE: "重要訊息",
      FULLSCREEN_SUBTEXT: "測試需在全屏模式下執行。",
      FULLSCREEN_CTA: "開啟全屏模式",
      CAMERA_ERROR: "啟動相機時發生錯誤",
      ENABLE_CAMERA: "請在您的設定中授權本軟件使用相機",
      DEVICE_ORIENTATION_HEADING: "請將您的裝置旋轉到縱向垂直模式。",
      DEVICE_ORIENTATION:
        "您當前似乎處於橫向模式，請將您的裝置旋轉到縱向垂直模式以繼續",
      FOLDABLE_TO_MAINSCREEN_HEADING: "請在主屏幕上繼續測試",
      FOLDABLE_TO_MAINSCREEN:
        "您目前正在輔助屏幕上查看此內容，請切換到主屏幕以繼續。",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Please continue the test on the secondary screen",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "協助",
    },
    EXPIRED: {
      TITLE: "此鏈接已過期",
      PRIMARY_TEXT: "請致電我們的客戶服務中心重新取得鏈接。",
    },
    _4xx: {
      TITLE: "404 - 访问页面不存在",
      DESCRIPTION:
        "無法查找頁面。請確保您已正確輸入地址。如果地址正確，頁面可能暫時不可用或可能已被刪除。",
      RETURN: "回到主頁",
      HELPLINE: "如需要任何助理，請致電我們的客服熱綫: ",
    },
    _5xx: {
      TITLE: "500 - 抱歉，出了一些問题",
      DESCRIPTION: "我们正在努力修復此問题",
      RETURN: "回到主頁",
      HELPLINE: "如需要任何助理，請致電我們的客服熱綫: ",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "載入中...",
      COCO_MODAL_LOADING_TEXT: "請稍等片刻。您的手機測試即將開始。",
      COCO_MODAL_LOADING_SUBTEXT: "請留守此頁。",
      REDIRECT_LOADING_TEXT: "我們正在檢查您的手機狀況。",
      REDIRECT_LOADING_SUBTEXT: "請留守此頁。",
      COUNTDOWN_TEXT: "我們正在審核您手機的狀態，請等待<count>秒。.",
      IMAGE_CHECK_TEXT: "我們正在分析圖像，請稍候。",
    },
    DIGITS: {
      0: "零",
      1: "一",
      2: "二",
      3: "三",
      4: "四",
      5: "五",
      6: "六",
      7: "七",
      8: "八",
      9: "九",
    },
    LOCALE: {
      "zh-HK": {
        ANDROID: "yue_HK",
        IOS: "zh-HK",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "抱歉，我們不支持此瀏覽器",
      MESSAGE: `請選以下一個瀏覽器重新啟動鏈接：`,
      COPYURL: "複製鏈接",
    },
  },
  // KOREAN
  ko: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "전면 상태 확인",
        BACK_SCREEN_CRACKED: "후면 상태 확인",
        PIXEL_DAMAGE: "픽셀 확인",
        SPEAKER_TEST: "스피커 확인",
        MIC_TEST: "마이크 확인",
      },
      STARTING: {
        HEADING: "안녕하세요 고객님 휴대폰 진단 프로그램입니다.",
        SUBCONTENT:
          '파손 여부를 확인 하겠습니다, 아래의 "진단 시작" 버튼을 클릭해 주세요.',
        BELOW_CONTENT: "진단 시작",
      },
      INPROCESS: {
        HEADING: "고객님 <> 건의 진단이 완료되지 않았습니다.",
        SUBCONTENT: "아래의 “진단 계속” 버튼을 클릭하여 완료해 주세요.",
        BELOW_CONTENT: "진단 계속",
      },
      FINISHED: {
        HEADING: "고객님, 모든 진단이 완료되었습니다.",
        SUBCONTENT: "진단 결과 확인페이지로 연결됩니다.",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        BELOW_CONTENT: "고객님께서 완료하신 진단은:",
      },
      MISC: {
        DONE: "완료",
        CTA: "시작합니다.",
        ASSESSMENT: "평가",
        REMAINING_ASSESSMENTS: "남은 진단: ",
        COMPLETED: "완료",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "파손 액정 테스트",
          DESCRIPTION:
            "다음 테스트는 휴대폰 전면 화면에 파손 여부를 확인합니다. 완료하는 데에 약 <b> 3분이 </b> 소요됩니다.",
          LIST: {
            SHOW: true,
            HEADING: "무엇이 필요할까요?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "중간 크기 정도의 깨끗한 거울",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "조명 좋은 방",
              },
            ],
          },
          CTA: "튜토리얼 시작",
          CTA_2: "튜토리얼 건너뛰기",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "단계별 안내사항",
          CTA: "다음",
          CTA2: "뒤로",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "먼저, 거울 앞에서 휴대폰을 들어주세요.",
              SECONDARY_TEXT:
                "거울 앞에서 팔 길이 정도 떨어져서 휴대폰을 들어주세요.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "휴대폰이 앞으로 기울어져 있는 지 확인해주세요.",
              SECONDARY_TEXT:
                "휴대폰을 약간 앞으로 기울이면 휴대폰 감지와 사진 촬영이 더 쉬워집니다.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "휴대폰을 흰색 테두리 안으로 맞추고 기다려주세요.",
              SECONDARY_TEXT:
                "<span>녹색</span> 테두리로 변할 때까지 휴대폰을 천천히 움직인 다음, 사진이 캡쳐될 때까지 기다려주세요.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "마지막으로 사진 검토 후 제출 버튼을 눌러주세요.",
              SECONDARY_TEXT:
                "화면 안에 휴대폰이 전체 모습이 보이는 지 확인하고 제출 버튼을 눌러주세요.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "시작할 준비가 되었습니다!",
          DESCRIPTION:
            "파손 액정 테스트 튜토리얼을 완료 하셨습니다. 준비가 되면 테스트를 시작할 수 있습니다.",
          CTA: "테스트 시작하기",
          CTA_2: "튜토리얼 다시 읽기",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "휴대폰 케이스를 제거해 주세요",
              SECONDARY_TEXT:
                "휴대폰 케이스 제거 후 안내에 따라 진단을 진행해 주세요.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "휴대폰 후면을 확인하겠습니다",
              SECONDARY_TEXT: "거울 앞에서 안내에 따라 진단을 진행해 주세요.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "휴대폰 후면을 프레임에 맞춰 주세요",
              SECONDARY_TEXT:
                "휴대폰 후면이 프레임 안에 들어올 수 있도록 위치를 조정해 주세요, 올바로 위치될 경우 프레임이 녹색으로 변경됩니다",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "휴대폰이 흔들리지 않도록 고정해 주세요.",
              SECONDARY_TEXT:
                "사진이 찍힐 동안 휴대폰 후면이 프레임을 벗어나지 않도록 잘 고정해 주세요.",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT:
                "촬영된 휴대폰 후면을 확인 후 등록 버튼을 눌러 주세요",
              SECONDARY_TEXT:
                "촬영된 화면이 프레임 내에 맞춰 제대로 찍혔는지 확인해 주세요. 휴대폰 후면이 잘 찍혔다면 “네, 등록합니다” 버튼을 클릭하여 사진을 등록해 주세요.",
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "고객님의 휴대폰 전면 상태를 확인하겠습니다",
              SECONDARY_TEXT:
                "거울 앞에서 안내에 따라 진단을 진행해 주세요. “휴대폰 픽셀 진단 시작”을 클릭하면 진단이 시작됩니다.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "휴대폰 전면을 프레임에 맞춰 주세요",
              SECONDARY_TEXT:
                "휴대폰 전면이 프레임 안에 들어올 수 있도록 위치를 조정해 주세요. 올바로 위치될 경우, 프레임이 녹색으로 변경됩니다.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "휴대폰이 흔들리지 않도록 고정해 주세요",
              SECONDARY_TEXT:
                "사진이 찍힐 동안 휴대폰 전면이 프레임을 벗어나지 않도록 잘 고정해 주세요.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT:
                "촬영된 휴대폰 픽셀을 확인 후 등록 버튼을 눌러 주세요",
              SECONDARY_TEXT:
                "촬영된 화면이 프레임 내에 맞춰 제대로 찍혔는지 확인해 주세요. 휴대폰 픽셀이 잘 찍혔다면 “네, 등록합니다” 버튼을 클릭하여 사진을 등록해 주세요.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "고객님의 단말기 스피커를 확인 하겠습니다",
              SECONDARY_TEXT:
                "만약 단말기에 연결된 헤드폰, 이어폰 및 블루투스 기기가 있을 경우, 이를 모두 연결 해제해 주시기 바랍니다.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "숫자를 듣고 번호를 입력해 주세요",
              SECONDARY_TEXT:
                "스피커를 통해 들리는 번호 세 자리를 잘 듣고 요청 시 해당 번호를 입력해 주세요. 번호 세 자리는 다시 들을 수 있습니다.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "고객님의 휴대폰 마이크를 확인하겠습니다",
              SECONDARY_TEXT:
                "고객님 단말기에 헤드폰, 이어폰 및 블루투스 기기가 연결되어 있을 경우 이를 모두 해재해 주시기 바랍니다",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "표시된 숫자를 마이크에 대고 말해 주세요",
              SECONDARY_TEXT:
                "소음이 없는 곳에서 마이크를 입 가까이 대고 진단을 진행해 주세요",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "휴대폰 전면 진단 시작",
        TOO_CLOSE_MOVE_FRAME: "너무 가까이 있습니다, 거리를 조정해 주세요.",
        POINT_SCREEN_AT_MIRROR: "본 화면을 거울에 비춰 주세요.",
        POSITION_PHONE_IN_FRAME: "휴대폰 전면을 프레임에 맞춰 주세요.",
        CONFIRM: "<device> 휴대폰 전면 화면이 선명하게 나왔나요?",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "휴대폰 후면 진단 시작",
        TOO_CLOSE_MOVE_FRAME: "너무 가까이 있습니다, 거리를 조정해 주세요.",
        POINT_SCREEN_AT_MIRROR: "본 화면을 거울에 비춰 주세요.",
        POSITION_PHONE_IN_FRAME: "휴대폰 후면을 프레임에 맞춰 주세요",
        CONFIRM: "<device> 휴대폰 전면 화면이 선명하게 나왔나요?",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "픽셀 진단 시작",
        TOO_CLOSE_MOVE_FRAME: "너무 가까이 있습니다, 거리를 조정해 주세요.",
        POINT_SCREEN_AT_MIRROR: "본 화면을 거울에 비춰 주세요.",
        POSITION_PHONE_IN_FRAME: "휴대폰 전면을 프레임에 맞춰 주세요.",
        CONFIRM: "<device> 휴대폰 전면 화면이 선명하게 나왔나요?",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "스피커 진단 시작",
        TYPE_NUMBERS: "방금 들으신 번호 세 자리를, 순서대로 입력해 주세요",
        SUBMIT: "제출",
        LISTEN_AGAIN: "다시 듣기",
        LISTEN_TO_DIGITS: "준비되셨으면 시작 버튼을 클릭해 주세요",
        POPUP_HEADING: "입력하신 정보가 확실한가요? 입력하신 정보는 <numbers>",
        POPUP_HEADING_2: "확실한가 !",
        BUTTON_TO_LISTEN: "시작",
        CTA_Yes: "네",
        CTA_No: "아니오",
        CTA_Change: "변경",
        CHECKBOX_TEXT: "스피커에서 재생되는 숫자가 들리지 않습니다",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "마이크 진단 시작",
        MAIN_TEXT: "아래 표시된 번호를 휴대폰에 대고 명확히 말해 주세요",
        PLAY_RECORDING: "녹음 내역 재생",
        SECONDARY_TEXT: "말씀해 주세요",
        BEGIN_BUTTON: "녹음시작",
        FINISH_BUTTON: "완료",
        CHECKBOX_TEXT: "제 마이크가 작동하지 않습니다",
        SUBMIT_BUTTON: "네 제출합니다",
        RETRY_BUTTON: "아니오 재녹음 하겠습니다",
        MIC_PERMISSION_DENIED:
          "진단을 위해 해당 앱의 휴대폰 마이크 접근을 허용해 주세요",
        MIC_PERMISSION_DENIED_CTA: "허용",
        MIC_IN_USE:
          "다른 앱에서 마이크를 사용하고 있습니다, 해당 앱을 종료한 뒤 진단을 진행해 주세요",
        MIC_IN_USE_CTA: "확인",
        BEFORE_YOU_BEGIN: "진단 시작 전",
        BEFORE_YOU_BEGIN_TEXT:
          "마이크 액세스 허용 권한을 묻는 메시지가 표시되며 만약 허용하지 않을 경우 마이크 테스트를 완료할 수 없습니다",
        BEFORE_YOU_BEGIN_CTA: "허용",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      SCREEN_TEST: "화면 진단",
      HOW_IT_WORKS: "작동 방법",
      START_TEST: "진단 시작",
      GENERIC_WAITING: "잠시만 기다려 주세요",
      UPLOAD_PHOTO: "네, 등록하겠습니다",
      NO_RETAKE_PHOTO: "아니오, 재촬영 하겠습니다",
      RETAKE_PHOTO: "재촬영 하겠습니다",
      SHOW_TIPS: "팁 보기",
      CAMERA_STARTING_TXT: "카메라가 작동하는 동안 잠시만 기다려 주세요",
      MOVE_PHONE_LEFT:
        "휴대폰을 왼쪽으로 이동해 프레임 내에 전면이 들어오도록 해주세요",
      MOVE_PHONE_RIGHT:
        "휴대폰을 오른쪽으로 이동해 프레임 내에 전면이 들어오도록 해주세요",
      MOVE_PHONE_TOP:
        "휴대폰 상단을 뒤로 기울여 프레임 내에 전면이 들어오도록 해주세요",
      MOVE_PHONE_BOTTOM:
        "휴대폰 상단을 앞으로 기울여 프레임 내에 전면이 들어오도록 해주세요",
      TOO_FAR_MOVE_CLOSER: "너무 멀리 있습니다, 거리를 조정해 주세요.",
      ABOUT_TO_CAPTURE: "화면이 캡쳐될 때까지 휴대폰을 그대로 유지해주세요.",
      GDPR_TEXT:
        "Please ensure that nothing appears in the image except your phone. Ensure that your face is not within the frame.",
      NO_DEVICE_DETECTED:
        "죄송합니다, 촬영된 사진에서 휴대폰을 감지 할 수 없습니다. 다시 시도해주세요.",
      LOW_CONFIDENCE_SCORE:
        "죄송합니다, 촬영된 사진이 흐릿합니다. 사진을 찍을 때 휴대폰을 안정되게 유지해주세요.",
      PERSON_DETECTED:
        "사진 상에서 얼굴이 확인됩니다. 기기만 확인될 수 있도록 촬영해주세요.",
      FINGER_DETECTED:
        "사진 상에서 진단을 방해하는 요소인 손가락이 확인됩니다. 진단 시 기기만 확인될 수 있도록 촬영해주세요.",
      MULTIPLE_CELLPHONE_DETECTED:
        "여러 기기가 감지되었습니다, 다시 촬영해 주세요.",
      DULL_AND_BLURRY_IMAGE: "사진이 흐릿하고 어둡습니다. 다시 시도해 주세요.",
      BLURRY_IMAGE: " 사진이 흐릿합니다. 다시 시도해 주세요.",
      DULL_IMAGE: " 사진이 어둡습니다. 다시 시도해 주세요.",
      MIRROR_FRAUD:
        "주의! 거울을 사용하여 올바른 휴대폰 화면을 찍어 주시기 바랍니다.",
      OPAQUE_SCREEN_CHECK:
        "사진 속 기기 화면은 완전히 검은색이어야 합니다. 사진이올바르게찍힐때까지휴대폰을들고그대로유지해주세요.",
      API_IMAGE_PII_CHECK:
        "사진 상에서 얼굴이 확인됩니다. 기기만 확인될 수 있도록 촬영해주세요.",
      EXPERIMENTAL_FOLDABLE_TEXT:
        "휴대폰을 감지할 수 없어 5초 후에 화면이 자동으로 캡쳐됩니다.",
      FRAUD_USER_INFO: "화면이 캡쳐될 때까지 휴대폰을 그대로 유지해주세요.",
      DEVICE_NOT_DETECTED: "죄송합니다. 단말기를 감지할 수 없습니다.",
      DIAGNOSIS_HELP_HEADING: "다음 5가지를 시도해보세요:",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE: "더 나은 화질을 위해 카메라 렌즈와 거울을 닦아주세요",
          IMG: "icon_camera.svg",
        },
        {
          TITLE: "다른 휴대폰이 사진에 찍히지 않도록 주의해주세요.",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "손가락으로 화면을 가리지 않도록 주의해주세요.",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "밝은 곳에서 테스트를 진행해주세요.",
          IMG: "icon_lit.svg",
        },
        {
          TITLE: "휴대폰을 흰색 틀에 맞추고 움직이지 말아주세요.",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "아직 어떻게 테스트해야 할지 모르겠나요?",
        TEXT: "설명 보기",
        CTA: "다시 시도하기",
      },
      CROPPED_IMAGE:
        "휴대폰이 감지되지 않았습니다. 휴대폰을 흰색 테두리 안에 맞추고 다시 시도해주세요.",
      IOS_DIALOG_BOX: "이제 진단을 시작 하실 수 있습니다.",
      UPLOADING_IMAGE: "사진을 올리는 중…",
      NEXT: "다음",
      LONGER_WAITING_TEXT:
        "현재 서버에서 많은 트래픽이 발생하고 있어서 평소보다 오랜 시간이 소요될 수 있습니다. 잠시만 기다려 주세요.",
      WAIT_TEXT: "잠시만 기다려주세요",
      NOTE_TEXT:
        "참고: 사진 업로드에 60초 이상 걸리는 경우, 업로드를 다시 진행해주세요.",
    },
    COMPLETION: {
      TITLE: "완료",
      THANK_YOU:
        "제출해 주셔서 감사합니다. 다음 진단 시작 버튼을 클릭하여 다음 진단을 진행하시거나 메인 화면으로 돌아가 다음 진단을 선택해 주세요.",
      START_NEXT_TEST: "다음 진단 시작",
      GO_TO_DASHBOARD: "메인 화면으로 돌아가기",
      CLOSE: "계속해서 둘러보시거나 창을 닫으셔도 됩니다",
    },
    ERRORS: {
      GENERIC_TITLE: "죄송합니다, 일시적인 오류가 발생했습니다.",
      GENERIC_MESSAGE:
        "잠시 후 다시 이용해 주시기 바랍니다. 이용에 불편을 드려 진심으로 사과드리며, 고객 여러분의 양해 부탁드립니다.",
      OK: "OK",
      UNSUPPORTED_BROWSER_TITLE: "중요한 메시지",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "현재 브라우저에서 카메라 접근이 허용되지 않았습니다. Safari(사파리) 브라우저로 해당 링크를 재 연결해 주시기 바랍니다.",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "현재 브라우저에서 카메라 접근이 허용되지 않았습니다. Chrome (크롬) 브라우저로 해당 링크를 재 연결해 주시기 바랍니다.",
      UNSUPPORTED_BROWSER_CTA: "URL 복사",
      FULLSCREEN_TITLE: "중요한 메시지",
      IMPORTANT_MESSAGE: "중요한 메시지",
      FULLSCREEN_SUBTEXT: "휴대폰 진단은 전체 화면 모드에서 진행해야 합니다.",
      FULLSCREEN_CTA: "전체 화면 모드 열기",
      CAMERA_ERROR: "카메라 실행에 오류가 있습니다",
      ENABLE_CAMERA:
        "진단을 위해 해당 앱의 휴대폰 카메라 접근을 허용해 주세요.",
      DEVICE_ORIENTATION_HEADING: "핸드폰을 세로 모드로 전환해주세요.",
      DEVICE_ORIENTATION:
        "현재 가로 모드로 되어 있습니다. 계속 하시려면 핸드폰을 세로 모드로 전환해주세요.",
      FOLDABLE_TO_MAINSCREEN_HEADING:
        "메인 화면으로 테스트를 계속 진행해주세요.",
      FOLDABLE_TO_MAINSCREEN:
        "현재 보조 화면을 통해 보고 있는 것으로 확인됩니다. 계속 하시려면 기본 화면으로 전환해주세요.",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Please continue the test on the secondary screen",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "고객센터",
    },
    EXPIRED: {
      TITLE: "링크가 만료되었습니다",
      PRIMARY_TEXT: "신규 링크 생성은, 고객센터에 문의해 주시기 바랍니다.",
    },
    _4xx: {
      TITLE: "404 - 페이지를 찾을 수 없습니다",
      DESCRIPTION:
        "요청한 페이지를 찾을 수 없습니다. 웹 주소가 올바른지 확인하세요. 해당 페이지는 삭제된 주소이거나 현재는 사용할 수 없습니다.",
      RETURN: "홈페이지로 이동합니다",
      HELPLINE:
        "관련 문의사항은 고객센터에 문의해 주시면 친절히 안내해 드리겠습니다:",
    },
    _5xx: {
      TITLE: "500 - 죄송합니다, 일시적인 오류가 발생했습니다.",
      DESCRIPTION:
        "잠시 후 다시 이용해 주시기 바랍니다. 이용에 불편을 드려 진심으로 사과 드리며, 고객 여러분의 양해 부탁드립니다.",
      RETURN: "홈페이지로 이동합니다",
      HELPLINE:
        "관련 문의사항은 고객센터에 문의해 주시면 친절히 안내해 드리겠습니다:",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "로딩중...",
      COCO_MODAL_LOADING_TEXT: "로딩중...",
      COCO_MODAL_LOADING_SUBTEXT: "",
      REDIRECT_LOADING_TEXT: "고객님의 휴대폰 상태를 확인하고 있습니다",
      REDIRECT_LOADING_SUBTEXT: "",
      COUNTDOWN_TEXT:
        "고객님의 휴대폰 상태를 확인하고 있으므로 <count>초간 기다려 주시기 바랍니다.",
      IMAGE_CHECK_TEXT: "고객님의 휴대폰을 확인하는 동안 잠시만 기다려 주세요.",
    },
    DIGITS: {
      0: "영",
      1: "일",
      2: "이",
      3: "삼",
      4: "사",
      5: "오",
      6: "육",
      7: "칠",
      8: "팔",
      9: "구",
    },
    LOCALE: {
      "ko-KR": {
        ANDROID: "ko_KR",
        IOS: "ko-KR",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "죄송합니다. 이 브라우저는 지원되지 않는 브라우저 입니다.",
      MESSAGE: `다음 중 하나의 링크를 통해 다시 시작해주세요.`,
      COPYURL: "링크 복사",
    },
  },
  // THAI
  th: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "ตรวจสอบหน้าจอ",
        BACK_SCREEN_CRACKED: "การตรวจสอบ ฝาหลัง",
        PIXEL_DAMAGE: "การตรวจสอบ พิกเซล",
        SPEAKER_TEST: "การตรวจสอบ ลำโพง",
        MIC_TEST: "การตรวจสอบ ไมโครโฟน",
      },
      STARTING: {
        HEADING: "ยินดีต้อนรับสู่การตรวจสอบอุปกรณ์ออนไลน์",
        SUBCONTENT:
          "ขั้นตอนนี้เป็นการทดสอบสั้นๆ เพื่อให้แน่ใจว่าอุปกรณ์ของคุณสามารถสมัคแพ็กเกจนี้ได้",
        BELOW_CONTENT: "เริ่มตรวจสอบ",
      },
      INPROCESS: {
        HEADING: "คุณมี <> การตรวจสอบอุปกรณ์ที่ยังไม่ได้ดำเนินการ ก่อนการสมัคร",
        SUBCONTENT:
          "คุณสามารถกดปุ่ม ทำการตรวจสอบอุปกรณ์ที่ยังไม่เสร็จสิ้น หรือ สามารถกดปุ่ม “ตรวจสอบต่อไป”",
        BELOW_CONTENT: "ดำเนินการตรวจสอบต่อไป",
      },
      FINISHED: {
        HEADING: "ยินดีด้วย! คุณทำการทดสอบที่จำเป็นทั้งหมดแล้ว",
        SUBCONTENT:
          "คุณจะได้รับการแจ้งผลจากเราภายใน 36 ชั่วโมง คุณสามารถปิดหน้าต่างนี้ได้",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        BELOW_CONTENT: "การตรวจสอบของคุณเรียบร้อยแล้ว",
      },
      MISC: {
        DONE: "เรียบร้อยแล้ว",
        CTA: "มาเริ่มกันเลย",
        ASSESSMENT: "การตรวจสอบ",
        REMAINING_ASSESSMENTS: "การตรวจสอบที่เหลืออยู่: ",
        COMPLETED: "เสร็จสมบูรณ์",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "การทดสอบหน้าจอแตก",
          DESCRIPTION:
            "การทดสอบต่อไปนี้จะตรวจว่ามือถือของคุณมีรอยแตกบนหน้าจอของคุณหรือไม่ จะใช้เวลาประมาณ <b>3 นาที</b> จึงจะเสร็จสิ้น",
          LIST: {
            SHOW: true,
            HEADING: "คุณต้องเตรียมอะไรบ้าง?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "กระจกขนาดกลางที่สะอาด",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "ห้องที่มีแสงสว่างเพียงพอ",
              },
            ],
          },
          CTA: "ดูขั้นตอนการตรวจสอบ",
          CTA_2: "เริ่มการตรวจสอบ",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "คำแนะนำทีละขั้นตอน",
          CTA: "ต่อไป",
          CTA2: "ย้อนกลับ",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "ขั้นตอนแรกให้หันอุปกรณ์ของคุณไปที่หน้ากระจก",
              SECONDARY_TEXT:
                "ถืออุปกรณ์ของคุณที่หน้ากระจก แล้วยืนห่างจากกระจกประมาณหนึ่งช่วงแขน",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "เอียงอุปกรณ์ของคุณไปข้างหน้า",
              SECONDARY_TEXT:
                "เอียงอุปกรณ์ของคุณไปข้างหน้าเล็กน้อย เพื่อให้เราตรวจสอบอุปกรณ์และจับถ่ายภาพได้ง่ายขึ้น",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT:
                "จัดตำแหน่งอุปกรณ์ของคุณให้อยู่ในกรอบสีขาวและค้างไว้",
              SECONDARY_TEXT:
                "ค่อยๆ เลื่อนอุปกรณ์ของคุณไปรอบๆ  จนกว่าจะมองเห็นเส้นขอบ<span>สีเขียว</span> จากนั้นให้ค้างไว้เพื่อถ่ายภาพ",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "สุดท้ายให้ตรวจสอบรูปภาพของคุณและกดส่ง",
              SECONDARY_TEXT:
                "ตรวจสอบให้แน่ใจว่าอุปกรณ์ทั้งหมดอยู่ในภาพ และกดส่งรูปภาพ",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "คุณพร้อมที่จะเริ่มต้นแล้ว!",
          DESCRIPTION:
            "คุณได้อ่านขั้นตอนการตรวจสอบหน้าจอแตกทั้งหมดแล้ว คุณสามารถเริ่มการทดสอบได้เมื่อคุณพร้อม",
          CTA: "เริ่มการทดสอบ",
          CTA_2: "อ่านบทช่วยสอนอีกครั้ง",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "ดูขั้นตอนการตรวจสอบ",
          CTA_2: "เริ่มการตรวจสอบ",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "ต่อไป",
          CTA2: "ย้อนกลับ",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "กรุณาถอดเคสโทรศัพท์ของคุณ",
              SECONDARY_TEXT:
                "ขั้นแรก กรุณาถอดเคสโทรศัพท์ของคุณ และอ่านข้อแนะนำ หลังจากนั้น กรุณากดที่ปุ่ม “เริ่มต้นการตรวจสอบฝาหลัง”  เพื่อเริ่มต้นการตรวจสอบ",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "เริ่มต้นการตรวจสอบ ฝาหลัง",
              SECONDARY_TEXT: "กรุณายืนหน้ากระจกเพื่อทำการตรวจสอบ",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "จัดตำแหน่งฝาหลังโทรศัพท์ให้อยู่ในกรอบที่กำหนด",
              SECONDARY_TEXT:
                "หันด้านหลังของโทรศัพท์เข้าหากระจก เคลื่อนโทรศัพท์ให้อยู่ในกรอบที่กำหนด จนกว่ากรอบรอบโทรศัพท์เปลี่ยนเป็นสีเขียว",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "ถือโทรศัพท์ของคุณให้นิ่ง",
              SECONDARY_TEXT:
                "ถือโทรศัพท์ของคุณให้นิ่งในตำแหน่งปัจจุบัน รอจนกว่าระบบดำเนินการถ่ายรูปและแสดงผลการตรวจสอบ",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "ตรวจสอบ และ ส่งผลการตรวจสอบ",
              SECONDARY_TEXT:
                "กรุณาตรวจสอบให้แน่ใจว่ารูปภาพที่ได้นั้น ชัดเจนและสมบูรณ์ คุณสามารถส่งดำเนินการส่งรูปภาพโดยกดที่ปุ่ม “ตกลง, อัพโหลดรูปภาพนี้”",
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "คุณพร้อมที่จะเริ่มการตรวจสอบแล้ว!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "อ่านขั้นตอนการตรวจสอบอีกครั้ง",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "ดูขั้นตอนการตรวจสอบ",
          CTA_2: "เริ่มการตรวจสอบ",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "ต่อไป",
          CTA2: "ย้อนกลับ",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "เริ่มต้นการตรวจสอบหน้าจอโทรศัพท์",
              SECONDARY_TEXT:
                "ขั้นแรก กรุณายืนหน้ากระจกและอ่านคำแนะนำ หลังจากนั้น กรุณากดปุ่ม “เริ่มต้นการตรวจสอบพิกเซล” เพื่อเริ่มต้นการตรวจสอบ",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "จัดตำแหน่งหน้าจอโทรศัพท์ให้อยู่ในกรอบที่กำหนด",
              SECONDARY_TEXT:
                "หันหน้าจอโทรศัพท์เข้าหากระจกให้อยู่ในกรอบที่กำหนด เมื่อเครื่องอยู่ในตำแหน่งที่ถูกต้อง กรอบจะเปลี่ยนเป็นสีเขียว",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "ถือโทรศัพท์ของคุณให้นิ่ง",
              SECONDARY_TEXT:
                "ถือโทรศัพท์ของคุณให้นิ่งในตำแหน่งปัจจุบัน รอจนกว่าระบบดำเนินการถ่ายรูปและแสดงผลการตรวจสอบ",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "ตรวจสอบ และ ส่งผลการตรวจสอบ",
              SECONDARY_TEXT:
                "กรุณาตรวจสอบให้แน่ใจว่ารูปภาพที่ได้นั้น ชัดเจนและอยู่ในกรอบอย่างสมบูรณ์ คุณสามารถส่งดำเนินการส่งรูปภาพโดยกดที่ปุ่ม “ตกลง, อัพโหลดรูปภาพนี้”",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "ดูขั้นตอนการตรวจสอบ",
          CTA_2: "เริ่มการตรวจสอบ",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "ต่อไป",
          CTA2: "ย้อนกลับ",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "เริ่มต้นการทดสอบลำโพง",
              SECONDARY_TEXT:
                "กรุณาถอดหูฟังหรือยกเลิกการเชื่อมต่อหูฟังบลูทูธของคุณ",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "กรุณาฟังและกดหมายเลขที่คุณได้ยิน",
              SECONDARY_TEXT:
                "กรุณาฟังหมายเลข 3 หลักจากลำโพงของคุณ และกดหมายเลข 3 หลักที่ได้ยินเมื่อได้รับการถามจากระบบ คุณสามารถย้อนกลับเพื่อฟังหมายเลข 3 หลักอีกครั้งหากคุณต้องการ",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "ดูขั้นตอนการตรวจสอบ",
          CTA_2: "เริ่มการตรวจสอบ",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "ต่อไป",
          CTA2: "ย้อนกลับ",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "เริ่มต้นการตรวจสอบไมโครโฟน",
              SECONDARY_TEXT:
                "กรุณาถอดหูฟังหรือยกเลิกการเชื่อมต่อหูฟังบลูทูธของคุณ",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "พูดเลขที่ท่านเห็นบนหน้าจอใส่ไมโครโฟน",
              SECONDARY_TEXT:
                "ทำในสถานที่ที่เงียบและไม่มีเสียงรบกวน และ ให้ไมโครโฟนอยู่ใกล้กับริมฝีปาก",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "เริ่มต้นการตรวจเช็คจอ",
        TOO_CLOSE_MOVE_FRAME: "ใกล้เกินไป มือถือทั้งเครื่องต้องอยู่ภายในกรอบ",
        POINT_SCREEN_AT_MIRROR: "หันมือถือเข้าหากระจกใกล้ตัวคุณ",
        POSITION_PHONE_IN_FRAME:
          "หันหน้าจอมือถือเข้ากระจก พร้อมจัดให้อยู่ในกรอบ",
        CONFIRM:
          "คุณสามารถส่งภาพนี้ได้ หากคุณเห็นอุปกรณ์ <device> ของคุณในรูปนี้ชัดเจน หรือ ลองใหม่อีกครั้ง",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "เริ่มต้นการตรวจสอบ ฝาหลัง",
        TOO_CLOSE_MOVE_FRAME:
          "ใกล้เกินไป ,กรุณาถอยหลังให้ด้านหลังเครื่องอยู่ในกรอบที่กำหนด",
        POINT_SCREEN_AT_MIRROR: "หันด้านหลังโทรศัพท์เข้าหากระจก",
        POSITION_PHONE_IN_FRAME: "จัดตำแหน่งโทรศัพท์ของคุณให้อยู่ในกรอบนี้",
        CONFIRM:
          "คุณสามารถส่งภาพนี้ได้ หากคุณเห็นอุปกรณ์ <device> ของคุณในรูปนี้ชัดเจน หรือ ลองใหม่อีกครั้ง",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "เริ่มต้น การตรวจสอบพิกเซล",
        TOO_CLOSE_MOVE_FRAME:
          "ใกล้เกินไป ,กรุณาถอยหลังให้ด้านหลังเครื่องอยู่ในกรอบที่กำหนด",
        POINT_SCREEN_AT_MIRROR: "เลื่อนหน้าจอเข้าหากระจก",
        POSITION_PHONE_IN_FRAME: "ปรับตำแหน่งหน้าจอโทรศัพท์ให้อยู่ภายในกรอบนี้",
        CONFIRM:
          "คุณสามารถส่งภาพนี้ได้ หากคุณเห็นอุปกรณ์ <device> ของคุณในรูปนี้ชัดเจน หรือ ลองใหม่อีกครั้ง",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "เริ่มต้นการทดสอบลำโพง",
        TYPE_NUMBERS: "กรุณากดหมายเลข จำนวน 3 หลัก ที่คุณได้ฟัง",
        SUBMIT: "ตกลง",
        LISTEN_AGAIN: "ฟังอีกครั้ง",
        LISTEN_TO_DIGITS: 'หากคุณพร้อมแล้วกด "เริ่มต้น',
        POPUP_HEADING: "Are you sure ! You entered <numbers>",
        POPUP_HEADING_2: "Are you sure !",
        BUTTON_TO_LISTEN: "เริ่มต้น",
        CTA_Yes: "Yes",
        CTA_No: "No",
        CTA_Change: "Change",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "เริ่มต้นการทดสอบ ไมโครโฟน",
        MAIN_TEXT: "กรุณาพูดหมายเลขด้านล่างให้ชัดเจนครับ",
        PLAY_RECORDING: "ฟังเสียงที่บันทึกไว้",
        SECONDARY_TEXT: "พูด",
        BEGIN_BUTTON: "แตะเพื่อเสร็จสิ้นขั้นตอน",
        FINISH_BUTTON: "เสร็จสิ้น",
        CHECKBOX_TEXT: "ไมโครโฟนของคุณไม่ทำงาน",
        SUBMIT_BUTTON: "ส่งข้อมูล",
        RETRY_BUTTON: "กรุณาอย่าพูดซ้ำ",
        MIC_PERMISSION_DENIED:
          "กรุณาอนุญาติให้ app ใช้งาน ระบบไมโครโฟนของท่านเพื่อการทดสอบ",
        MIC_PERMISSION_DENIED_CTA: "Allow",
        MIC_IN_USE:
          "App อื่น อาจกำลังใช้งาน ไมโครโฟน อยู่ ดังนั้นกรุณาปิด app อื่นๆให้หมดก่อนทดลองอีกครั้ง",
        MIC_IN_USE_CTA: "Confirm",
        BEFORE_YOU_BEGIN: "BEFORE YOU BEGIN",
        BEFORE_YOU_BEGIN_TEXT:
          "You will be asked to allow  microphone access and we highly recommend you to do so else you will not be able to complete the mic test",
        BEFORE_YOU_BEGIN_CTA: "Allow",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      SCREEN_TEST: "การทดสอบหน้าจอ",
      HOW_IT_WORKS: "ทดสอบอย่างไร",
      START_TEST: "เริ่มทดสอบ",
      GENERIC_WAITING: "กรุณารอสักครู่",
      UPLOAD_PHOTO: "ส่งภาพนี้",
      NO_RETAKE_PHOTO: "ลองใหม่อีกครั้ง",
      RETAKE_PHOTO: "ถ่ายใหม่อีกครั้ง",
      SHOW_TIPS: "แสดงคำแนะนำ",
      CAMERA_STARTING_TXT: "กรุณาเปิดมือถือ และ แสดงหน้าจอ",
      MOVE_PHONE_LEFT: "กรุณาเลื่อนมือถือไปทางซ้าย",
      MOVE_PHONE_RIGHT: "กรุณาเลื่อนมือถือไปทางขวา",
      MOVE_PHONE_TOP: "กรุณาหงายมือถือ ให้ด้านบนชิดกับกรอบ",
      MOVE_PHONE_BOTTOM: "กรุณาคว่ำ ให้ด้านบนชิดกับกรอบ",
      TOO_FAR_MOVE_CLOSER: "ไกลเกินไปครับ กรุณาเลื่อนมือถือเข้าใกล้กระจก",
      ABOUT_TO_CAPTURE: "ถือโทรศัพท์จนกว่าภาพถ่ายจะถูกบันทึก",
      GDPR_TEXT:
        "Please ensure that nothing appears in the image except your phone. Ensure that your face is not within the frame.",
      NO_DEVICE_DETECTED:
        "ขออภัย เราไม่สามารถจับภาพอุปกรณ์ของคุณได้ กรุณาลองใหม่",
      LOW_CONFIDENCE_SCORE:
        "ขออภัย รูปของเบลอเกินไป กรุณาถืออุปกรณ์ของคุณให้นิ่งขณะจับภาพ",
      PERSON_DETECTED:
        "ขออภัย ดูเหมือนว่ามีใบหน้าบนรูปภาพ โปรดตรวจสอบให้แน่ใจว่าได้จับภาพเฉพาะอุปกรณ์ของคุณเท่านั้นในภาพถ่าย",
      FINGER_DETECTED:
        "ขออภัย มีนิ้วอยู่บนรูปภาพ โปรดตรวจสอบให้แน่ใจว่าไม่มีนิ้วอยู่บนหน้าจอระหว่างการทดสอบ",
      EXPERIMENTAL_FOLDABLE_TEXT:
        "เราไม่สามารถตรวจจับอุปกรณ์ได้ หน้าจอของคุณจะจับภาพโดยอัตโนมัติ ภายใน 5 วินาที",
      MULTIPLE_CELLPHONE_DETECTED:
        "พบอุปกรณ์มากกว่า 1 เครื่องในรูปถ่าย กรุณาถ่ายใหม่อีกครั้ง",
      DULL_AND_BLURRY_IMAGE:
        "รูปถ่ายมืด และ เบลอเกินไปครับ กรุณาถืออุปกรณ์ให้นิ่งและถ่ายรูปใหม่อีกครั้ง",
      BLURRY_IMAGE:
        "รูปถ่าย เบลอเกินไปครับ กรุณาถืออุปกรณ์ให้นิ่งและถ่ายรูปใหม่อีกครั้ง",
      DULL_IMAGE:
        "รูปถ่าย มืดเกินไปครับ กรุณาถืออุปกรณ์ให้นิ่งและถ่ายรูปใหม่อีกครั้ง",
      MIRROR_FRAUD: "กรุณาถ่ายภาพด้วยมือถือที่รับความคุ้มครอง ด้วยการใช้กระจก ",
      OPAQUE_SCREEN_CHECK:
        "หน้าจออุปกรณ์ต้องเป็นสีดำสนิทในภาพที่ถ่าย กรุณาถ่ายภาพใหม่อีกครั้งและถือโทรศัพท์ไว้นิ่งๆ เว้นแต่จะจับภาพได้ทั้งหมด",
      API_IMAGE_PII_CHECK:
        "ขออภัย ดูเหมือนว่ามีใบหน้าบนรูปภาพ โปรดตรวจสอบให้แน่ใจว่าได้จับภาพเฉพาะอุปกรณ์ของคุณเท่านั้นในภาพถ่าย",
      FRAUD_USER_INFO: "กรุณาถือโทรศัพท์ให้นิ่ง จนกว่าภาพถ่ายจะถูกบันทึก",
      DEVICE_NOT_DETECTED: "ขออภัย ตรวจไม่พบอุปกรณ์",
      DIAGNOSIS_HELP_HEADING: "คุณสามารถทำตามขั้นตอนต่อไปนี้ :",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE: "เช็ด ทำความสะอาดเลนส์และกระจกกล้องเพื่อให้ได้ผลที่ดีขึ้น",
          IMG: "icon_camera.svg",
        },
        {
          TITLE: "เช็กให้มั่นใจว่าไม่มีอุปกรณ์อื่นอยู่ด้านหลัง",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "ห้ามให้นิ้วมือบังส่วนใดส่วนหนึ่งของหน้าจอ",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "ทำการทดสอบนี้ในที่ที่มีแสงสว่างเพียงพอ",
          IMG: "icon_lit.svg",
        },
        {
          TITLE: "พยายามถือโทรศัพท์มือถือให้อยู่ภายในกรอบสีขาว",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "ไม่รู้วิธีการทดสอบ ?",
        TEXT: "ดูวิธีทำ",
        CTA: "ลองใหม่",
      },
      CROPPED_IMAGE:
        "ไม่สามารถเก็บภาพอุปกรณ์ได้อย่างสมบูรณ์ โปรดลองใหม่อีกครั้ง โดยให้อุปกรณ์ของคุณอยู่ภายในกรอบสีขาวครบทุกส่วน",
      IOS_DIALOG_BOX: "คุณสามารถเริ่มทำการทดสอบได้แล้ว",
      NEXT: "ย้อนกลับ",
      UPLOADING_IMAGE: "กำลังอัพโหลดภาพ..",
      LONGER_WAITING_TEXT:
        "การดำเนินการอาจใช้เวลานานกว่าปกติ เนื่องจากขณะนี้เราพบปัญหาการรับส่งข้อมูลในเซิร์ฟเวอร์ของเรา โปรดรอสักครู่",
      WAIT_TEXT: "กรุณารอซักครู่",
      NOTE_TEXT: "หมายเหตุ: โปรดอัปโหลดอีกครั้งหากรอนานกว่า 60 วินาที",
    },
    COMPLETION: {
      TITLE: "เรียบร้อยแล้ว",
      THANK_YOU:
        "ขอขอบคุณสำหรับการดำเนินการตรวจสอบ กรุณากดปุ่ม “เริ่มต้นการตรวจสอบถัดไป” เพื่อดำเนินการการตรวจสอบถัดไป หรือ ไปยังหน้าควบคุมเพื่อเลือกการตรวจสอบต่อไป",
      START_NEXT_TEST: "เริ่มต้นการตรวจสอบถัดไป",
      GO_TO_DASHBOARD: "ไปยังหน้าควบคุม",
      CLOSE: "คุณสามารถดำเนินการต่อไป หรือ ปิดเบราเซอร์นี้",
    },
    ERRORS: {
      GENERIC_TITLE: "ขอโทษค่ะ มีบางอย่างผิดพลาด",
      GENERIC_MESSAGE: "เรากำลังดำเนินการแก้ไขปัญหานี้โดยเร็วที่สุด",
      OK: "ตกลง",
      UNSUPPORTED_BROWSER_TITLE: "ข้อความสำคัญ",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "กล้องถ่ายรูปไม่สามารถใช้งานในเบราเซอร์ที่คุณเลือกใช้งานได้ กรุณาเปลี่ยนไปใช้งาน ซาฟารีเบราเซอร์",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "กล้องไม่สามารถเข้าถึงเบราเซอร์ปัจจุบันของคุณ กรุณาเปิดลิงค์อีกครั้งโดยการใช้เบราเซอร์ Chrome",
      UNSUPPORTED_BROWSER_CTA: "คัดลอก URL",
      FULLSCREEN_TITLE: "ข้อความสำคัญ",
      IMPORTANT_MESSAGE: "ข้อความสำคัญ",
      FULLSCREEN_SUBTEXT:
        "การทดสอบและวินิจฉัย จำเป็นจะต้องใช้การแสดงหน้าจอแบบเต็มจอ (Full screen)",
      FULLSCREEN_CTA: "เปิดโหมดแสดงเต็มจอ (Full screen)",
      CAMERA_ERROR: "มีข้อผิดพลาดในการใช้งานกล้องถ่ายรูป",
      ENABLE_CAMERA:
        "กรุณาเปิดคำสั่งอนุญาตการใช้งานกล้องถ่ายรูปที่เมนูการตั้งค่าเครื่อง",
      DEVICE_ORIENTATION_HEADING: "โปรดหมุนอุปกรณ์ของคุณเป็นโหมดแนวตั้ง",
      DEVICE_ORIENTATION:
        "ดูเหมือนว่าคุณกำลังอยู่ในโหมดแนวนอน โปรดหมุนอุปกรณ์ของคุณเป็นโหมดแนวตั้งเพื่อดำเนินการต่อ",
      FOLDABLE_TO_MAINSCREEN_HEADING: "โปรดทำการทดสอบบนหน้าจอหลัก",
      FOLDABLE_TO_MAINSCREEN:
        "ดูเหมือนว่าคุณกำลังอยู่ในหน้าจอที่สองของคุณ โปรดสลับไปที่หน้าจอหลักเพื่อดำเนินการต่อ",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Please continue the test on the secondary screen",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "ช่วยเหลือ",
    },
    EXPIRED: {
      TITLE: "ลิงค์สำหรับการเชื่อมต่อหมดอายุ",
      PRIMARY_TEXT:
        "หากต้องการจะสร้างลิงค์สำหรับการเชื่อมต่อใหม่ กรุณาติดต่อเจ้าหน้าที่ฝ่ายบริการของเรา",
    },
    _4xx: {
      TITLE: "404 - ไม่พบหน้าที่คุณต้องการ",
      DESCRIPTION:
        "ไม่พบหน้าที่คุณต้องการ กรุณาตรวจสอบความถูกต้องของที่อยู่ หากแน่ใจว่าที่อยู่นั้นถูกต้องแล้ว หน้านี้อาจจะถูกระงับการใช้งานชั่วคราวหรือถูกยกเลิกหน้านี้ไปแล้ว",
      RETURN: "กลับไปยังหน้าแรก",
      HELPLINE:
        "สำหรับความช่วยเหลือใด ๆ กรุณาติดต่อเจ้าหน้าที่ฝ่ายบริการลูกค้าที่หมายเลข: ",
    },
    _5xx: {
      TITLE: "500 - ขอโทษค่ะ มีบางอย่างผิดพลาด",
      DESCRIPTION: "เรากำลังดำเนินการแก้ไขปัญหานี้โดยเร็วที่สุด",
      RETURN: "กลับไปยังหน้าแรก",
      HELPLINE:
        "สำหรับความช่วยเหลือใด ๆ กรุณาติดต่อเจ้าหน้าที่ฝ่ายบริการลูกค้าที่หมายเลข: ",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "กำลังดำเนินการ …",
      COCO_MODAL_LOADING_TEXT: "กรุณารอสักครู่ การทดสอบกำลังจะเริ่มขึ้น",
      COCO_MODAL_LOADING_SUBTEXT: "กรุณาอย่าปิดหน้านี้",
      REDIRECT_LOADING_TEXT: "เรากำลังส่งคุณไปหน้าผลการตรวจสอบ",
      REDIRECT_LOADING_SUBTEXT: "กรุณาอย่าปิดหน้านี้",
      COUNTDOWN_TEXT:
        "ทางเรากำลังดำเนินการตรวจสอบสภาพมือถือของคุณ กรุณารอ <count> วินาที",
      IMAGE_CHECK_TEXT: "กรุณารอซักครู่ เรากำลังทำการตรวจสอบภาพถ่าย",
    },
    DIGITS: en_DIGITS,
    LOCALE: {
      "th-TH": {
        ANDROID: "th_TH",
        IOS: "th-TH",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: " ขออภัย บราวเซอร์ ที่ท่านใช้ไม่ได้รับ.",
      MESSAGE: `การสนับสนุนจากระบบ กรุณาคัดลอกลิงก์เพื่อเปิดเว็บบราวเซอร์ใหม่ :`,
      COPYURL: "คัดลอกลิงก์",
    },
  },
  // ITALIAN
  it: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "Schermo frontale",
        BACK_SCREEN_CRACKED: "Test parte posteriore del telefono",
        PIXEL_DAMAGE: "Test dei pixel",
        SPEAKER_TEST: "Test del vivavoce",
        MIC_TEST: "Test del microfono",
      },
      STARTING: {
        HEADING: "Benvenuto nell'area di test del tuo smartphone.",
        SUBCONTENT: 'Puoi cliccare "Iniza il test" per iniziare il test.',
        BELOW_CONTENT: "Inizia il test",
      },
      INPROCESS: {
        HEADING: "Hai <> test rimanenti prima di completare la tua richiesta.",
        SUBCONTENT:
          "Puoi cliccare sulle icone dei test non ancora completati o puoi direttamente cliccare su “Continua il test”.",
        BELOW_CONTENT: "Continua il test",
      },
      FINISHED: {
        HEADING: "Congratulazioni! Hai completato tutti i test.",
        SUBCONTENT: "Verrai reindirizzato alla pagina dei risultati del test.",
        SUBCONTENT2: "",
        SUBCONTENT3: "",
        BELOW_CONTENT: "I test che hai completato:",
      },
      MISC: {
        DONE: "Fatto",
        CTA: "Iniziamo",
        ASSESSMENT: "Valutazione",
        REMAINING_ASSESSMENTS: "Valutazioni rimanenti: ",
        COMPLETED: "Completato",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Test dello schermo",
          DESCRIPTION:
            "Il seguente test rileva se il dispositivo presenta crepe sullo schermo anteriore. Il completamento richiede circa <b>3 minuti</b>.",
          LIST: {
            SHOW: true,
            HEADING: "Di cosa avrai bisogno?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "Uno specchio pulito di medie dimensioni",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "Una stanza ben illuminata",
              },
            ],
          },
          CTA: "Avvia il tutorial",
          CTA_2: "Salta il tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "Guida",
          CTA: "Continua",
          CTA2: "Indietro",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT:
                "Per prima cosa, metti il tuo telefono di fronte a uno specchio.",
              SECONDARY_TEXT:
                "Tieni il telefono davanti allo specchio a una distanza di circa un braccio.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT:
                "Assicurarsi che il telefono sia inclinato in avanti.",
              SECONDARY_TEXT:
                "Inclinare leggermente il telefono in avanti per facilitare il rilevamento del telefono e lo scatto di foto.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT:
                "Allineare il telefono all'interno della cornice bianca e tenerlo fermo.",
              SECONDARY_TEXT:
                "Muovi lentamente il telefono fino a quando la cornice diventa  <span>verde</span>, quindi rimani immobile per scattare la foto.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Infine, controllare la foto e inviarla.",
              SECONDARY_TEXT:
                "Assicurarsi che l'intero telefono sia presente nell'immagine e inviare la foto.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "Sei pronto per iniziare!",
          DESCRIPTION:
            "Hai completato il tutorial per il test dello schermo. È possibile iniziare il test quando si è pronti.",
          CTA: "Inizia il test",
          CTA_2: "Rileggi il tutorial",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Rimuovi la tua cover",
              SECONDARY_TEXT:
                "Per effettuare questo test avrai bisogno di rimuovere la cover e di seguire le istruzioni che trovi nelle prossime schermate.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT:
                "Ora testiamo insieme la parte posteriore del tuo telefono",
              SECONDARY_TEXT: "Adesso trova uno specchio e inizia il test",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Allinea il tuo schermo alla cornice",
              SECONDARY_TEXT:
                "Inclina leggermente lo smartphone allineando lo schermo con la cornice che visualizzi finchè non diventerà verde.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT:
                "Attendi qualche secondo tenendo il tuo smartphone fermo",
              SECONDARY_TEXT:
                "Una volta che la cornice diventerà verde mantieni lo smartphone in quella posizione finchè non visualizzerai la foto sullo schermo.",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "Controlla e conferma",
              SECONDARY_TEXT:
                'Assicurati che la foto sia chiara e completamente all\'interno della cornice. Se va bene, procedi a caricarla cliccando "Si, invia foto". ORA SEI PRONTO AD INIZIARE IL TEST!.',
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Ora testiamo insieme il tuo schermo",
              SECONDARY_TEXT:
                "Per effettuare questo test avrai bisogno di uno specchio e di seguire le istruzioni che trovi nelle prossime schermate.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Allinea il tuo schermo alla cornice",
              SECONDARY_TEXT:
                "Display your phone’s screen within the displayed frame. Once your device is in the correct position, the frame will turn green.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT:
                "Attendi qualche secondo tenendo il tuo smartphone fermo",
              SECONDARY_TEXT:
                "Una volta che la cornice diventerà verde mantieni lo smartphone in quella posizione finchè non visualizzerai la foto sullo schermo.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Controlla e conferma",
              SECONDARY_TEXT:
                'Assicurati che la foto sia chiara e completamente all\'interno della cornice. Se va bene, procedi a caricarla cliccando "Si, invia foto". ORA SEI PRONTO AD INIZIARE IL TEST!.',
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone speaker",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Listen and input the digits",
              SECONDARY_TEXT:
                "Carefully listen the 3 digits number played on your speaker and input the same number when asked. You can listen the 3 digits again if you like.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone Mic",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Speak displayed digits into the microphone",
              SECONDARY_TEXT:
                "Find a noise-free place and keep the mic near your mouth.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Inizia il test dello schermo",
        TOO_CLOSE_MOVE_FRAME:
          "Troppo vicino, assicurati che l'intero schermo sia all'interno della cornice",
        POINT_SCREEN_AT_MIRROR: "Inquadra lo schermo sullo specchio",
        POSITION_PHONE_IN_FRAME: "Allinea il tuo schermo alla cornice",
        CONFIRM:
          "Il tuo smartphone <device>  è chiaramente visibile in questa foto?",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON:
          "Inizia il test della parte posteriore del telefono",
        TOO_CLOSE_MOVE_FRAME:
          "Troppo vicino, assicurati che la parte posteriore del telefono sia all'interno della cornice.",
        POINT_SCREEN_AT_MIRROR:
          "Inquadra lo parte posteriore del telefono su uno specchio",
        POSITION_PHONE_IN_FRAME: "Inquadra il telefono nella cornice",
        CONFIRM:
          "Il tuo smartphone <device>  è chiaramente visibile in questa foto?",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Pixel Test",
        TOO_CLOSE_MOVE_FRAME:
          "Troppo vicino, assicurati che l'intero schermo sia all'interno della cornice",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s display within the frame.",
        CONFIRM:
          "Il tuo smartphone <device>  è chiaramente visibile in questa foto?",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Speaker Test",
        TYPE_NUMBERS: "Type the 3 digits number you just heard, in order",
        SUBMIT: "Submit",
        LISTEN_AGAIN: "Listen Again",
        LISTEN_TO_DIGITS: "Please click on 'Begin' button when you are ready",
        POPUP_HEADING: "Are you sure ! You entered <numbers>",
        POPUP_HEADING_2: "Are you sure !",
        BUTTON_TO_LISTEN: "Begin",
        CTA_Yes: "Yes",
        CTA_No: "No",
        CTA_Change: "Change",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Mic Test",
        MAIN_TEXT: "Please clearly speak the below digits into the phone",
        PLAY_RECORDING: "Play recording",
        SECONDARY_TEXT: "Say",
        BEGIN_BUTTON: "Tap & Speak",
        FINISH_BUTTON: "Done",
        CHECKBOX_TEXT: "My mic doesn't work",
        SUBMIT_BUTTON: "Yes, submit",
        RETRY_BUTTON: "No, speak again",
        MIC_PERMISSION_DENIED:
          "Please allow the app to access phone's Microphone for the test.",
        MIC_PERMISSION_DENIED_CTA: "Allow",
        MIC_IN_USE:
          "Some other app/s is using the mic, please close the other app/s before proceeding further",
        MIC_IN_USE_CTA: "Confirm",
        BEFORE_YOU_BEGIN: "BEFORE YOU BEGIN",
        BEFORE_YOU_BEGIN_TEXT:
          "You will be asked to allow  microphone access and we highly recommend you to do so else you will not be able to complete the mic test",
        BEFORE_YOU_BEGIN_CTA: "Allow",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      EXPERIMENTAL_FOLDABLE_TEXT:
        "Non siamo in grado di rilevare un dispositivo, lo schermo verrà catturato automaticamente tra 5 secondi",
      SCREEN_TEST: "SCREEN TEST",
      HOW_IT_WORKS: "How it works",
      START_TEST: "Start test",
      GENERIC_WAITING: "Rimani un momento in attesa",
      UPLOAD_PHOTO: "Si, invia foto",
      NO_RETAKE_PHOTO: "No, scatta nuovamente la foto",
      RETAKE_PHOTO: "scatta nuovamente la foto",
      SHOW_TIPS: "Show tips",
      CAMERA_STARTING_TXT: "Aspettare che la telecamera si avvii",
      MOVE_PHONE_LEFT: "Spostare lentamente il telefono a sinistra",
      MOVE_PHONE_RIGHT: "Spostare lentamente il telefono a destra",
      MOVE_PHONE_TOP:
        "Inclinare lentamente la parte superiore del telefono un po' verso l'alto per inserirlo nella cornice",
      MOVE_PHONE_BOTTOM:
        "Inclinare lentamente la parte superiore del telefono un po' verso il basso e inserirlo nella cornice",
      TOO_FAR_MOVE_CLOSER:
        "Troppo lontano, avvicina il telefono allo specchio.",
      ABOUT_TO_CAPTURE:
        "Tieni fermo il telefono finché non viene scattata la foto",
      GDPR_TEXT:
        "Assicurati che nell'immagine compaia solo il telefono, escludendo dall’inquadratura il tuo viso.",
      NO_DEVICE_DETECTED:
        "Siamo spiacenti, il dispositivo non può essere rilevato nell’immagine caricata. Si prega di riprovare.",
      LOW_CONFIDENCE_SCORE:
        " Siamo spiacenti, l'immagine è sfocata. Si prega di riprovare mantenendo il dispositivo fermo quando la foto viene scattata.",
      PERSON_DETECTED:
        "Siamo spiacenti ma  sembra che ci sia un volto nella foto. Assicurati che solo il vostro dispositivo sia immortalato nella foto.",
      FINGER_DETECTED:
        "Siamo spiacenti  ma ci sono delle dita nell’immagine. Assicurati che nessun dito copra lo schermo durante il test.",
      MULTIPLE_CELLPHONE_DETECTED:
        "Sono stati rilevati più dispositivi, si prega di riscattare la foto.",
      DULL_AND_BLURRY_IMAGE:
        "L'immagine è sfocata e scura. Ti chiediamo di riprovare tenendo il telefono fermo mentre scatti la foto",
      BLURRY_IMAGE:
        "L'immagine è sfocata. Ti chiediamo di riprovare tenendo il telefono fermo mentre scatti la foto",
      DULL_IMAGE:
        "L'immagine è scura. Ti chiediamo di riprovare tenendo il telefono fermo mentre scatti la foto",
      MIRROR_FRAUD:
        "Attenzione! si prega di fotografare il dispositivo in possesso utilizzando uno specchio.",
      OPAQUE_SCREEN_CHECK:
        "Lo schermo del dispositivo deve essere completamente nero, ti chiediamo di riscattare la foto tenendo fermo il telefono nel caso in cui l’immagine non fosse corretta.",
      API_IMAGE_PII_CHECK:
        "Siamo spiacenti ma  sembra che ci sia un volto nella foto. Assicurati che solo il vostro dispositivo sia immortalato nella foto.",
      FRAUD_USER_INFO:
        "Si prega di tenere fermo il telefono finché l'immagine non viene catturata",
      DEVICE_NOT_DETECTED:
        "Siamo spiacenti, il dispositivo non può essere rilevato.",
      DIAGNOSIS_HELP_HEADING: "Puoi provare ad eseguire quanto segue:",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE:
            "Pulire la lente della fotocamera e lo specchio per ottenere risultati migliori.",
          IMG: "icon_camera.svg",
        },
        {
          TITLE:
            "Assicurarti che l’immagine non contenga altri dispositivi sullo sfondo.",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "Evitare di coprire lo schermo con le dita.",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "Eseguire il test in un ambiente ben illuminato.",
          IMG: "icon_lit.svg",
        },
        {
          TITLE:
            "Assicurati di tenere il telefono fermo e all'interno della cornice bianca.",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "Non sei sicuro di come eseguire il test?",
        TEXT: "Visualizza le istruzioni",
        CTA: "Prova di nuovo",
      },
      CROPPED_IMAGE:
        "Non è stato possibile  acquisire l’immagine dell'intero dispositivo, assicurarsi che il dispositivo sia all'interno del bordo bianco. Prova di nuovo.",
      IOS_DIALOG_BOX: "Puoi ora effettuare il test.",
      NEXT: "Avanti",
      UPLOADING_IMAGE: "Caricamento dell'immagine...",
      LONGER_WAITING_TEXT:
        "Potrebbe richiedere più tempo del previsto in quanto stiamo avendo un traffico elevato sul nostro server. Si prega di attendere un momento.",
      WAIT_TEXT: "Rimani un momento in attesa",
      NOTE_TEXT:
        "Nota: Si prega di eseguire nuovamente l'upload se sta impiegando più di 60 secondi",
    },
    COMPLETION: {
      TITLE: "Fatto",
      THANK_YOU:
        'Grazie per aver completato il test. Clicca su "Inizia il prossimo test" per eseguire il prossimo test o andare sulla pagina iniziale per selezionare il test successivo.',
      START_NEXT_TEST: "Inzia il prossimo test",
      GO_TO_DASHBOARD: "Vai alla home",
      CLOSE:
        "Ora puoi continuare a navigare o semplicemente chiudere il browser",
    },
    ERRORS: {
      GENERIC_TITLE: "Ci scusiamo, qualcosa è andato storto.",
      GENERIC_MESSAGE:
        "Stiamo lavorando per risolvere il problema il prima possibile.",
      OK: "OK",
      UNSUPPORTED_BROWSER_TITLE: "Messaggio Importante",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "Non è stato possibile accedere alla fotocamera nel browser corrente. Si prega di riaprire il link utilizzando il browser Safari.",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "Non è stato possibile accedere alla fotocamera nel browser attuale. Si prega di riaprire il link utilizzando il browser Chrome.",
      UNSUPPORTED_BROWSER_CTA: "Copia URL",
      FULLSCREEN_TITLE: "Messaggio Importante",
      IMPORTANT_MESSAGE: "Messaggio Importante",
      FULLSCREEN_SUBTEXT:
        "Il test diagnostico deve essere eseguito in modalità a schermo intero.",
      FULLSCREEN_CTA: "Apri in schermo intero",
      CAMERA_ERROR: "Errore nell'accesso alla telecamera",
      ENABLE_CAMERA:
        "Consenti all'applicazione di accedere alla fotocamera del telefono.",
      DEVICE_ORIENTATION_HEADING:
        "Ruota il tuo dispositivo in modalità verticale.",
      DEVICE_ORIENTATION:
        "Sembra che tu sia attualmente in modalità orizzontale, ruota il tuo dispositivo in modalità verticale per procedere.",
      FOLDABLE_TO_MAINSCREEN_HEADING:
        "Si prega di continuare il test sullo  schermo principale",
      FOLDABLE_TO_MAINSCREEN:
        "Sembra che tu stia visualizzando questo sullo schermo secondario, per favore passa allo schermo principale per procedere.",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Please continue the test on the secondary screen",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "Aiuto",
    },
    EXPIRED: {
      TITLE: "Il link è scaduto",
      PRIMARY_TEXT: "",
    },
    _4xx: {
      TITLE: "404 - Pagina non trovata",
      DESCRIPTION:
        "La pagina che stai cercando non può essere trovata. Assicuratevi di aver inserito correttamente l'indirizzo. Se l'indirizzo è corretto, la pagina potrebbe essere temporaneamente non disponibile o potrebbe essere stata rimossa.",
      RETURN: "Torna alla Home",
      HELPLINE: "",
    },
    _5xx: {
      TITLE: "500 - Ci scusiamo, qualcosa è andato storto.",
      DESCRIPTION:
        "Stiamo lavorando per risolvere il problema il prima possibile.",
      RETURN: "Torna alla Home",
      HELPLINE: "",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "Caricamento...",
      COCO_MODAL_LOADING_TEXT:
        "Ti chiediamo di attendere un momento. Il test inizierà a breve.",
      COCO_MODAL_LOADING_SUBTEXT: "Ti chiediamo di rimanere su questa pagina.",
      REDIRECT_LOADING_TEXT:
        "Verrai reindirizzato alla pagina dei risultati del test.",
      REDIRECT_LOADING_SUBTEXT: "Ti chiediamo di rimanere su questa pagina.",
      COUNTDOWN_TEXT:
        "Attendi per <count>secondi mentre verifichiamo le condizioni del tuo telefono.",
      IMAGE_CHECK_TEXT:
        "Ti chiediamo di attendere un momento mentre analizziamo l'immagine.",
    },
    DIGITS: en_DIGITS,
    LOCALE: {
      "it-IT": {
        ANDROID: "it_IT",
        IOS: "it-IT",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "Siamo spiacenti, questo browser non è supportato",
      MESSAGE: `Ti chiediamo di collegarti al link utilizzando uno dei seguenti:`,
      COPYURL: "Copia link",
    },
  },
  // PORTUGUESE
  pt: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "Écran partido",
        BACK_SCREEN_CRACKED: "Teste da contracapa",
        PIXEL_DAMAGE: "Teste de pixel",
        SPEAKER_TEST: "Teste de alto-falante",
        MIC_TEST: "Teste de microfone",
      },
      STARTING: {
        HEADING: "Bem- vindo à sua ferramenta de avaliação de dispositivos.",
        SUBCONTENT:
          "Para nos ajudar a determinar a qualificação para a avaliação do seu dispositivo, algumas avaliações podem ser necessárias.",
      },
      INPROCESS: {
        HEADING: "Faltam <> testes para terminar seu processo",
        SUBCONTENT:
          "Você pode clicar nos ícones dos testes que ainda não completou ou selecionar “Continuar diagnóstico”",
      },
      FINISHED: {
        HEADING: "Parabéns! Você completou todos os testes.",
        HEADING2: "Obrigado por completar o teste de tela quebrada",
        SUBCONTENT:
          "You will soon receive an email with the results of your test.",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        SUBCONTENT4:
          "Estamos analisando a tela do seu celular. Por favor, aguarde",
      },
      MISC: {
        DONE: "Terminado",
        CTA: "Vamos começar",
        ASSESSMENT: "Teste",
        REMAINING_ASSESSMENTS: "Avaliações restantes: ",
        COMPLETED: "Concluído",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Teste de écran partido",
          DESCRIPTION:
            "O teste a seguir detetará se o seu dispositivo tem o écran frontal partido. Levará cerca de <b>3 minutos</b> para ser concluído.",
          LIST: {
            SHOW: true,
            HEADING: "O que você vai precisar?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "Um espelho de tamanho médio, limpo",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "Um quarto bem iluminado",
              },
            ],
          },
          CTA: "Iniciar o tutorial",
          CTA_2: "Saltar o tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "Um guia passo a passo",
          CTA: "Próximo",
          CTA2: "Voltar",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Primeiro, coloque o telefone em frente ao espelho",
              SECONDARY_TEXT:
                "Segure o telefone em frente a um espelho, a cerca de um braço de distância.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT:
                "Certifique-se que o telefone está inclinado para a frente",
              SECONDARY_TEXT:
                "Incline ligeiramente o telefone para a frente para facilitar a detecção do telefone e a captura de fotos.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT:
                "Alinhe seu telefone dentro da moldura branca e mantenha-o imóvel.",
              SECONDARY_TEXT:
                "Mova lentamente o telefone até que a moldura <span>verde</span> fique visível e mantenha-o imóvel para capturar a foto.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Por fim, verifique a sua foto e envie.",
              SECONDARY_TEXT:
                "Certifique-se de que todo o telefone esteja na imagem e envie a foto.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "Você está pronto para começar!",
          DESCRIPTION:
            "Você concluiu o tutorial. Pode iniciar o teste quando estiver pronto.",
          CTA: "Iniciar teste",
          CTA_2: "Leia o tutorial novamente",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Remove the phone case",
              SECONDARY_TEXT:
                'First, please remove the phone case and read the instructions. After that, please click on the "Start Back Cover Test" to initiate the test.',
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Let's test your phone's back cover",
              SECONDARY_TEXT: "Please find a mirror to implement the test.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display the phone back cover on the mirror and looking into the screen, try to fit the phone image in given frame until phone boundaries turn green.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the current position until the picture is taken and the result is displayed back to you.",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "Review and Submit",
              SECONDARY_TEXT:
                'Ensure the photo is clear and complete. You can then submit the picture by clicking on "Yes, Upload this photo".',
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone display",
              SECONDARY_TEXT:
                "First, please find a mirror and read the instructions. After that, please click on “Start Pixel Test” to initiate the test.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display your phone’s screen within the displayed frame. Once your device is in the correct position, the frame will turn green.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the correct position until the picture is taken and the result is displayed white to you.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Review and submit",
              SECONDARY_TEXT:
                "Ensure the photo is clear and completely within the frame. You can then submit the picture by clicking on “Yes, upload this photo”.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone speaker",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Listen and input the digits",
              SECONDARY_TEXT:
                "Carefully listen the 3 digits number played on your speaker and input the same number when asked. You can listen the 3 digits again if you like.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone Mic",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Speak displayed digits into the microphone",
              SECONDARY_TEXT:
                "Find a noise-free place and keep the mic near your mouth.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Começar teste da tela",
        TOO_CLOSE_MOVE_FRAME:
          "Muito próximo. Se certifique que a tela está totalmente dentro da moldura",
        POINT_SCREEN_AT_MIRROR: "Coloque a tela em frente a um espelho.",
        POSITION_PHONE_IN_FRAME: "Enquadre a tela do seu celular na moldura.",
        CONFIRM: "Você consegue ver seu <device> nitidamente nesta imagem?",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Back Cover Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full backscreen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point the phone backscreen at a mirror",
        POSITION_PHONE_IN_FRAME: "Position your phone in this frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Pixel Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full screen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s display within the frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Speaker Test",
        TYPE_NUMBERS: "Type the 3 digits number you just heard, in order",
        SUBMIT: "Submit",
        LISTEN_AGAIN: "Listen Again",
        LISTEN_TO_DIGITS: "Please click on 'Begin' button when you are ready",
        POPUP_HEADING: "Are you sure ! You entered <numbers>",
        POPUP_HEADING_2: "Are you sure !",
        BUTTON_TO_LISTEN: "Begin",
        CTA_Yes: "Yes",
        CTA_No: "No",
        CTA_Change: "Change",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Mic Test",
        MAIN_TEXT: "Please clearly speak the below digits into the phone",
        PLAY_RECORDING: "Play recording",
        SECONDARY_TEXT: "Say",
        BEGIN_BUTTON: "Tap & Speak",
        FINISH_BUTTON: "Done",
        CHECKBOX_TEXT: "My mic doesn't work",
        SUBMIT_BUTTON: "Yes, submit",
        RETRY_BUTTON: "No, speak again",
        MIC_PERMISSION_DENIED:
          "Please allow the app to access phone's Microphone for the test.",
        MIC_PERMISSION_DENIED_CTA: "Allow",
        MIC_IN_USE:
          "Some other app/s is using the mic, please close the other app/s before proceeding further",
        MIC_IN_USE_CTA: "Confirm",
        BEFORE_YOU_BEGIN: "BEFORE YOU BEGIN",
        BEFORE_YOU_BEGIN_TEXT:
          "You will be asked to allow  microphone access and we highly recommend you to do so else you will not be able to complete the mic test",
        BEFORE_YOU_BEGIN_CTA: "Allow",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      GENERIC_WAITING: "Um momento por favor",
      UPLOAD_PHOTO: "Sim, enviar esta foto",
      NO_RETAKE_PHOTO: "Não, tirar nova foto",
      RETAKE_PHOTO: "tirar nova foto",
      SHOW_TIPS: "Mostre as dicas",
      CAMERA_STARTING_TXT: "Espere enquanto a câmera inicia",
      MOVE_PHONE_LEFT: "Mova lentamente o seu celular para a esquerda.",
      MOVE_PHONE_RIGHT: "Mova lentamente o seu celular para a direita.",
      MOVE_PHONE_TOP:
        "Incline lentamente a parte superior do seu celular para cima e encaixe-o na moldura",
      MOVE_PHONE_BOTTOM:
        "Incline lentamente a parte superior do seu celular para baixo e encaixe-o na moldura",
      TOO_FAR_MOVE_CLOSER: "Muito longe, aproxime o celular do espelho.",
      ABOUT_TO_CAPTURE:
        "Mantenha o seu celular imóvel até que a foto seja tirada",
      VIDEO_SELECT_HEADING: "ANTES DE VOCÊ COMEÇAR",
      VIDEO_SELECT_PARAGRAPH:
        "Certifique-se de que a câmera principal do seu telefone esteja sendo usada para realizar o teste.",
      VIDEO_SELECT_QUESTION: "Esta é a câmera principal do seu telefone?",
      VIDEO_SELECT_ACCEPT_BUTTON: "Sim, use esta camera",
      VIDEO_SELECT_REJECT_BUTTON: "Não, mude de câmera",
      EXPERIMENTAL_FOLDABLE_TEXT:
        "Não conseguimos detetar um dispositivo, a sua tela será capturada automaticamente em 5 segundos.",
      GDPR_TEXT:
        "Por favor, se assegure que nada aparece na imagem exceto o seu celular. Se assegure que a sua cara não aparece na moldura.",
      NO_DEVICE_DETECTED:
        "Desculpe, o dispositivo não pôde ser detectado na imagem. Por favor, tente novamente.",
      LOW_CONFIDENCE_SCORE:
        "Desculpe, a imagem está desfocada. Tente manter o dispositivo estável enquanto a foto estiver sendo capturada.",
      PERSON_DETECTED:
        "Desculpe, parece que há um rosto na foto. Certifique-se de que apenas o seu dispositivo seja capturado na foto",
      FINGER_DETECTED:
        "Desculpe, há (um) dedo(s) na imagem. Certifique-se de que nenhum dedo cubra o ecrã durante o teste",
      MULTIPLE_CELLPHONE_DETECTED:
        "Foram detectados vários dispositivos. Por favor fotografe outra vez.",
      DULL_AND_BLURRY_IMAGE:
        "A imagem está desfocada e escura. Por favor tente novamente e mantenha seu celular imovel enquanto tira a foto.",
      BLURRY_IMAGE:
        "A imagem está desfocada. Por favor tente novamente e mantenha seu celular imóvel enquanto tira a foto.",
      DULL_IMAGE:
        "A imagem está desfocada. Por favor tente novamente e mantenha seu celular imóvel enquanto tira a foto.",
      MIRROR_FRAUD:
        "Atenção! Por favor, capture o dispositivo certo usando um espelho.",
      OPAQUE_SCREEN_CHECK:
        "O ecrã do dispositivo deve estar completamente preto na imagem capturada. Tente de novo e mantenha seu telefone imóvel até que a imagem seja completamente capturada.",
      API_IMAGE_PII_CHECK:
        "Desculpe, parece que há um rosto na foto. Certifique-se de que apenas o seu dispositivo seja capturado na foto",
      FRAUD_USER_INFO:
        "Por favor, mantenha seu celular imóvel até que a foto seja tirada",
      DEVICE_NOT_DETECTED: "Desculpe, o dispositivo não pôde ser detetado.",
      DIAGNOSIS_HELP_HEADING: "Você pode tentar efetuar o seguinte:",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE:
            "Limpe a lente da câmera e o espelho para melhores resultados.",
          IMG: "icon_camera.svg",
        },
        {
          TITLE:
            "Certifique-se de que o fundo não contém nenhum outro dispositivo.",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "Evite cobrir a tela com os dedos.",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "Execute o teste em um ambiente bem iluminado.",
          IMG: "icon_lit.svg",
        },
        {
          TITLE:
            "Certifique-se que mantém o celular estável e dentro da moldura branca.",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "Não tem certeza de como realizar o teste?",
        TEXT: "Veja as instruções",
        CTA: "Tente novamente",
      },
      CROPPED_IMAGE:
        "Não foi possível capturar o dispositivo completo, certifique-se que o dispositivo está dentro da moldura branca. Tente novamente.",
      IOS_DIALOG_BOX: "Pode fazer o diagnóstico.",
      NEXT: "Próximo",
      UPLOADING_IMAGE: "Carregando imagem ..",
      LONGER_WAITING_TEXT:
        "Isso pode demorar mais do que o normal, pois estamos enfrentando elevado tráfego em nosso servidor. Por favor aguarde um momento.",
      WAIT_TEXT: "Um momento, por favor.",
      NOTE_TEXT:
        "Nota: execute o upload novamente se estiver levando mais de 60 segundos",
    },
    COMPLETION: {
      TITLE: "Terminado",
      THANK_YOU:
        "Obrigado(a) por ter submetido o teste. Para continuar clique em “próximo teste” ou vá ao painel de controlo para seleccionar o próximo teste.",
      START_NEXT_TEST: "Começar próximo teste",
      GO_TO_DASHBOARD: "Ir para painel de controle",
      CLOSE:
        "Você pode continuar a explorar ou simplesmente fechar o seu motor de busca.",
    },
    ERRORS: {
      GENERIC_TITLE: "Sorry, Alguma coisa deu errado.",
      GENERIC_MESSAGE:
        "Estamos trabalhando para resolver este problema o mais rápido possivel. ",
      OK: "OK",
      UNSUPPORTED_BROWSER_TITLE: "Mensagem importante",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "A câmera não pôde ser acessada em seu navegador atual. Por favor, abra novamente o link usando o navegador Safari.",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "A câmera não pôde ser acessada em seu navegador atual. Por favor, abra novamente o link usando o navegador Chrome.",
      UNSUPPORTED_BROWSER_CTA: "Copiar link",
      FULLSCREEN_TITLE: "Mensagem importante",
      IMPORTANT_MESSAGE: "Mensagem importante",
      FULLSCREEN_SUBTEXT:
        "O teste de diagnóstico necessita ser realizado em modo “tela inteira”.",
      FULLSCREEN_CTA: "Abrir modo “tela inteira”",
      CAMERA_ERROR: "Erro ao tentar acessar a câmara",
      ENABLE_CAMERA:
        "Permita que o aplicativo tenha acesso a câmera do celular.",
      DEVICE_ORIENTATION_HEADING:
        "Por favor gire seu dispositivo para o modo retrato",
      DEVICE_ORIENTATION:
        "Parece que você está no modo paisagem, gire seu dispositivo para o modo retrato para continuar.",
      FOLDABLE_TO_MAINSCREEN_HEADING:
        "Desdobre o seu dispositivo e continue na tela principal",
      FOLDABLE_TO_MAINSCREEN:
        "Parece que você está visualizando isto na tela secundária, desdobre seu dispositivo e visualize-o na tela principal para continuar.",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Por favor continue o teste na tela secundária",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "AJUDA",
    },
    EXPIRED: {
      TITLE: "O link expirou",
      PRIMARY_TEXT:
        "Para gerar um novo link, entre em contato com o nosso apoio ao cliente",
    },
    _4xx: {
      TITLE: "404 - Página não encontrada",
      DESCRIPTION:
        "A página que você procura não foi encontrada. Certique-se que colocou o endereço correto. Se o endereço está correto a página poderá estar temporariamente indisponível ou foi removida.",
      RETURN: "Voltar à página inicial",
      HELPLINE:
        "Se necessitar de ajuda, poderá entrar em contato através do nosso serviço de apoio ao cliente: ",
    },
    _5xx: {
      TITLE: "500 - Desculpe, alguma coisa deu errado.",
      DESCRIPTION:
        "Estamos trabalhando para resolver este problema o mais rápido possivel.",
      RETURN: "Voltar à página inicial",
      HELPLINE:
        "Se necessitar de ajuda, poderá entrar em contato através do nosso serviço de apoio ao cliente: ",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "Em processamento...",
      COCO_MODAL_LOADING_TEXT:
        "Por favor aguarde. O seu teste começará em breve.",
      COCO_MODAL_LOADING_SUBTEXT: "Por favor fique nessa página.",
      REDIRECT_LOADING_TEXT: "Estamos verificando a condição do seu celular.",
      REDIRECT_LOADING_SUBTEXT: "Por favor fique nessa página.",
      COUNTDOWN_TEXT:
        "Aguarde <count> segundos, pois estamos analisando a condição do seu telefone agora.",
      IMAGE_CHECK_TEXT: "Aguarde um momento enquanto analisamos a imagem.",
    },
    DIGITS: en_DIGITS,
    LOCALE: {
      "en-HK": {
        ANDROID: "en_US",
        IOS: "en-US",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "Desculpe, este navegador não é compatível ",
      MESSAGE: `Reinicie o link num dos seguintes:`,
      COPYURL: "Copiar o link",
    },
  },
  // FRENCH
  fr: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "Ecran / caméra avant",
        BACK_SCREEN_CRACKED: "Test Arrière Téléphone",
        PIXEL_DAMAGE: "Test Pixel",
        SPEAKER_TEST: "Test Haut-Parleur",
        MIC_TEST: "Test Microphone",
      },
      STARTING: {
        HEADING: "Ecran / caméra avant",
        SUBCONTENT:
          "Veuillez effectuer le test écran ci-dessous s’il vous plait.",
      },
      INPROCESS: {
        HEADING: "Vous avez <> tests restants pour finir votre demande.",
        SUBCONTENT:
          "Vous pouvez cliquer sur les icônes de test que vous n’avez pas finis directement sur “Continuer le test”",
      },
      FINISHED: {
        HEADING: "Congratulations! You have completed all tests.",
        HEADING2: "Merci d’avoir complété le test écran.",
        SUBCONTENT:
          "You will soon receive an email with the results of your test.",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        SUBCONTENT4:
          "Nous sommes en train d’analyser votre écran. Patientez s’il vous plaît.",
      },
      MISC: {
        DONE: "Terminé",
        CTA: "Commencer",
        ASSESSMENT: "Test",
        REMAINING_ASSESSMENTS: "Évaluations restantes: ",
        COMPLETED: "Complété",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Test écran / caméra avant",
          DESCRIPTION:
            "Ce test permet de déterminer si votre écran présente des fissures.",
          LIST: {
            SHOW: true,
            HEADING: "De quoi aurez-vous besoin ?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "Un miroir propre, de taille moyenne",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "Une pièce suffisamment éclairée",
              },
            ],
          },
          CTA: "Commencer le tutoriel",
          CTA_2: "Passer tutoriel",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "Un guide étape par étape",
          CTA: "Suivant",
          CTA2: "Retour",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT:
                "Tout d'abord, placez votre téléphone devant le miroir",
              SECONDARY_TEXT:
                "Tenez votre téléphone devant un miroir, à une distance d’environ un bras.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT:
                "Assurez-vous que votre téléphone est incliné vers l'avant.",
              SECONDARY_TEXT:
                "Inclinez légèrement votre téléphone vers l'avant pour faciliter la détection et la prise de photo du téléphone.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT:
                "Alignez votre téléphone dans le cadre vert et maintenez-le sans bouger.",
              SECONDARY_TEXT:
                "Déplacez lentement le téléphone jusqu'à ce que la bordure verte soit visible, puis maintenez-le sans bouger pour prendre la photo.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Pour finir, examinez votre photo et soumettez-là",
              SECONDARY_TEXT:
                "Assurez-vous que tout le téléphone est sur la photo et soumettez la photo",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "Vous êtes prêt à commencer !",
          DESCRIPTION:
            "Vous avez terminé le tutoriel. Vous pouvez commencer le test lorsque vous êtes prêt.",
          CTA: "Démarrer le test",
          CTA_2: "Relire le tutoriel",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Remove the phone case",
              SECONDARY_TEXT:
                'First, please remove the phone case and read the instructions. After that, please click on the "Start Back Cover Test" to initiate the test.',
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Let's test your phone's back cover",
              SECONDARY_TEXT: "Please find a mirror to implement the test.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display the phone back cover on the mirror and looking into the screen, try to fit the phone image in given frame until phone boundaries turn green.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the current position until the picture is taken and the result is displayed back to you.",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "Review and Submit",
              SECONDARY_TEXT:
                'Ensure the photo is clear and complete. You can then submit the picture by clicking on "Yes, Upload this photo".',
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone display",
              SECONDARY_TEXT:
                "First, please find a mirror and read the instructions. After that, please click on “Start Pixel Test” to initiate the test.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display your phone’s screen within the displayed frame. Once your device is in the correct position, the frame will turn green.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the correct position until the picture is taken and the result is displayed white to you.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Review and submit",
              SECONDARY_TEXT:
                "Ensure the photo is clear and completely within the frame. You can then submit the picture by clicking on “Yes, upload this photo”.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone speaker",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Listen and input the digits",
              SECONDARY_TEXT:
                "Carefully listen the 3 digits number played on your speaker and input the same number when asked. You can listen the 3 digits again if you like.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone Mic",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Speak displayed digits into the microphone",
              SECONDARY_TEXT:
                "Find a noise-free place and keep the mic near your mouth.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Démarrer le test de l'écran",
        TOO_CLOSE_MOVE_FRAME:
          "Trop près, assurez-vous que l’écran entier soit dans le cadre.",
        POINT_SCREEN_AT_MIRROR: "Pointer cet écran vers le miroir.",
        POSITION_PHONE_IN_FRAME:
          "Alignez l’écran de votre téléphone dans le cadre.",
        CONFIRM:
          "Confirmez-vous que la photo permet de vérifier l'état de votre écran <device>?",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Back Cover Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full backscreen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point the phone backscreen at a mirror",
        POSITION_PHONE_IN_FRAME: "Position your phone in this frame.",
        CONFIRM:
          "Confirmez-vous que la photo permet de vérifier l'état de votre écran <device>?",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Pixel Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full screen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s display within the frame.",
        CONFIRM:
          "Confirmez-vous que la photo permet de vérifier l'état de votre écran <device>?",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Speaker Test",
        TYPE_NUMBERS: "Type the 3 digits number you just heard, in order",
        SUBMIT: "Submit",
        LISTEN_AGAIN: "Listen Again",
        LISTEN_TO_DIGITS: "Please click on 'Begin' button when you are ready",
        POPUP_HEADING: "Are you sure ! You entered <numbers>",
        POPUP_HEADING_2: "Are you sure !",
        BUTTON_TO_LISTEN: "Begin",
        CTA_Yes: "Yes",
        CTA_No: "No",
        CTA_Change: "Change",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Mic Test",
        MAIN_TEXT: "Please clearly speak the below digits into the phone",
        PLAY_RECORDING: "Play recording",
        SECONDARY_TEXT: "Say",
        BEGIN_BUTTON: "Tap & Speak",
        FINISH_BUTTON: "Done",
        CHECKBOX_TEXT: "My mic doesn't work",
        SUBMIT_BUTTON: "Yes, submit",
        RETRY_BUTTON: "No, speak again",
        MIC_PERMISSION_DENIED:
          "Please allow the app to access phone's Microphone for the test.",
        MIC_PERMISSION_DENIED_CTA: "Allow",
        MIC_IN_USE:
          "Some other app/s is using the mic, please close the other app/s before proceeding further",
        MIC_IN_USE_CTA: "Confirm",
        BEFORE_YOU_BEGIN: "BEFORE YOU BEGIN",
        BEFORE_YOU_BEGIN_TEXT:
          "You will be asked to allow  microphone access and we highly recommend you to do so else you will not be able to complete the mic test",
        BEFORE_YOU_BEGIN_CTA: "Allow",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      START_TEST: "Commencer",
      GENERIC_WAITING: "Un instant s’il vous plait",
      UPLOAD_PHOTO: "Oui – envoyer cette photo",
      NO_RETAKE_PHOTO: "Non – prendre une nouvelle photo",
      RETAKE_PHOTO: "Prendre une nouvelle photo.",
      CAMERA_STARTING_TXT: "Attendez que l’appareil photo s’initialise",
      MOVE_PHONE_LEFT: "Déplacez doucement votre téléphone vers la gauche.",
      MOVE_PHONE_RIGHT: "Déplacez doucement votre téléphone vers la droite.",
      MOVE_PHONE_TOP:
        "Inclinez doucement le haut de votre téléphone vers le haut et faites-le entrer dans le cadre.",
      MOVE_PHONE_BOTTOM:
        "Inclinez doucement le haut de votre téléphone vers le bas et faites-le entrer dans le cadre.",
      TOO_FAR_MOVE_CLOSER: "Trop loin, rapprochez le téléphone du miroir.",
      ABOUT_TO_CAPTURE:
        "La position est correcte. Maintenant, gardez cette position.",
      VIDEO_SELECT_HEADING: "AVANT DE COMMENCER",
      VIDEO_SELECT_PARAGRAPH:
        "Assurez-vous que l'appareil photo principal de votre téléphone est utilisé pour effectuer le test.",
      VIDEO_SELECT_QUESTION:
        "Est-ce l'appareil photo principal de votre téléphone ?",
      VIDEO_SELECT_ACCEPT_BUTTON: "Oui, utilisez cet appareil photo",
      VIDEO_SELECT_REJECT_BUTTON: "Non, changer de caméra",
      EXPERIMENTAL_FOLDABLE_TEXT:
        "Nous ne parvenons pas à détecter un appareil, votre écran sera automatiquement capturé dans 5 secondes.",
      GDPR_TEXT:
        "Assurez-vous qu’aucune forme autre que votre appareil n’apparaisse sur l’image. Assurez-vous que votre visage ne soit pas dans le cadre.",
      NO_DEVICE_DETECTED:
        "Désolé, l'appareil ne peut pas être détecté dans l'image. Veuillez réessayer.",
      LOW_CONFIDENCE_SCORE:
        "Désolé, l’image est floue. Essayez de maintenir le téléphone sans bouger pendant la prise de photo s’il vous plaît.",
      PERSON_DETECTED:
        "Désolé, il semble qu'il y ait un visage sur la photo. Veuillez vous assurer que seul votre appareil est capturé sur la photo",
      FINGER_DETECTED:
        "Désolé, il y a un (des) doigt(s) sur la photo. Veuillez vous assurer qu'aucun doigt n'est sur l'écran pendant le test",
      MULTIPLE_CELLPHONE_DETECTED:
        "Plusieurs appareils sont détectés, s’il vous plaît prenez la photographie à nouveau.",
      DULL_AND_BLURRY_IMAGE:
        "L’image est floue et sombre. S’il vous plaît, maintenez le téléphone dans la même position lorsque la photographie est prise.",
      BLURRY_IMAGE:
        "L’image est floue. S’il vous plaît, maintenez le téléphone dans la même position lorsque la photographie est prise.",
      DULL_IMAGE:
        "L’image est sombre. S’il vous plaît, maintenez le téléphone dans la même position lorsque la photographie est prise.",
      MIRROR_FRAUD:
        "Attention ! Assurez-vous de prendre une photo de votre écran en utilisant un miroir.",
      OPAQUE_SCREEN_CHECK:
        "L'écran de l'appareil doit être complètement noir dans l'image capturée, veuillez reprendre et tenir votre téléphone immobile à moins que l'image ne soit complètement capturée.",
      API_IMAGE_PII_CHECK:
        "Désolé, il semble qu'il y ait un visage sur la photo. Veuillez vous assurer que seul votre appareil est capturé sur la photo",
      FRAUD_USER_INFO:
        "Maintenez votre téléphone jusqu’à ce que la photo soit prise s’il vous plait",
      DEVICE_NOT_DETECTED: "Désolé, votre appareil ne peut pas être détecté.",
      DIAGNOSIS_HELP_HEADING: "Pour résoudre ce problème:",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE:
            "Nettoyez votre miroir, ainsi que la lentille de votre objectif pour de meilleurs résultats.",
          IMG: "icon_camera.svg",
        },
        {
          TITLE:
            "Assurez-vous qu’il n’y ait aucun autre appareil en arrière-plan.",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "Evitez de couvrir votre écran avec votre main ou doigt(s).",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "Effectuez le test dans un endroit bien éclairé.",
          IMG: "icon_lit.svg",
        },
        {
          TITLE:
            "Assurez-vous de tenir votre appareil stable, à l’intérieur du cadre.",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "Vous ne savez pas comment effectuer le test?",
        TEXT: "Voir les instructions",
        CTA: "Essayer à nouveau",
      },
      CROPPED_IMAGE:
        "Impossible de détecter le mobile complet, s’il vous plait assurez-vous que le mobile se trouve dans le cadre blanc. Essayez à nouveau.",
      IOS_DIALOG_BOX: "Vous pouvez maintenant effectuer le test.",
      NEXT: "Suivant",
      UPLOADING_IMAGE: "Téléchargement de l'image...",
      LONGER_WAITING_TEXT:
        "Cela peut prendre plus de temps que d'habitude car nous avons actuellement un trafic élevé sur notre serveur. Patientez s'il-vous-plaît.",
      WAIT_TEXT: "Un instant s’il vous plait",
      NOTE_TEXT:
        "Remarque : veuillez effectuer à nouveau le téléchargement si cela prend plus de 60 secondes",
    },
    COMPLETION: {
      TITLE: "Terminé",
      THANK_YOU:
        "Merci d’avoir soumis votre test. S’il vous plait appuyez sur “Commencer le test suivant” pour réaliser le prochain test ou allez sur le rapport pour sélectionner le prochain test.",
      START_NEXT_TEST: "Commencer le test suivant",
      GO_TO_DASHBOARD: "Allez sur le rapport",
      CLOSE:
        "Vous pouvez maintenant continuer votre navigation ou simplement fermer votre navigateur",
    },
    ERRORS: {
      GENERIC_TITLE: "Désolé, un problème est survenu.",
      GENERIC_MESSAGE: "Nous travaillons à une résolution de ce problème. ",
      OK: "OK",
      UNSUPPORTED_BROWSER_TITLE: "Message important",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "L’appareil photographique est inaccessible. S’il vous plait ouvrez à nouveau le lien avec le navigateur Safari.",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "L’appareil photographique est inaccessible. S’il vous plait ouvrez à nouveau le lien avec le navigateur Chrome.",
      UNSUPPORTED_BROWSER_CTA: "Copier l’adresse",
      FULLSCREEN_TITLE: "Message important",
      IMPORTANT_MESSAGE: "Message important",
      FULLSCREEN_SUBTEXT:
        "Le diagnostic a besoin d’être réalisé en mode plein écran.",
      FULLSCREEN_CTA: "Ouvrir le mode plein écran",
      CAMERA_ERROR: "Erreur d’accès à l’appareil photographie.",
      ENABLE_CAMERA:
        "Autoriser l’application à accéder à l’appareil photo de votre téléphone s’il vous plait.",
      DEVICE_ORIENTATION_HEADING:
        "Veuillez pivoter votre appareil en mode portrait",
      DEVICE_ORIENTATION:
        "Il semble que vous êtes actuellement en mode paysage, veuillez faire pivoter votre appareil en mode portrait pour continuer.",
      FOLDABLE_TO_MAINSCREEN_HEADING:
        "Veuillez déplier votre appareil et continuer sur votre écran principal",
      FOLDABLE_TO_MAINSCREEN:
        "Il semble que vous visualisez actuellement ceci sur votre écran secondaire, veuillez déplier votre appareil et le visualiser sur l'écran principal afin de continuer.",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Veuillez continuer le test sur l'écran secondaire",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "AIDE",
    },
    EXPIRED: {
      TITLE: "Le lien a expiré",
      PRIMARY_TEXT:
        "Pour générer un nouveau lien, s’il vous plait appelez notre centre d’appel.",
    },
    _4xx: {
      TITLE: "404 - Page Non Trouvée",
      DESCRIPTION:
        "La page que vous cherchez ne peut pas être trouvée. S’il vous plait assurez-vous que vous avez utilisé l’adresse correcte. Si l’adresse est correcte, la page est peut-être temporairement inaccessible ou a été supprimée.",
      RETURN: "Retour à la page initiale",
      HELPLINE: "Pour assistance, merci d’appeler notre hotline : ",
    },
    _5xx: {
      TITLE: "500 - Désolé, un problème est survenu.",
      DESCRIPTION: "Nous travaillons à une résolution de ce problème.",
      RETURN: "Retour à la page initiale",
      HELPLINE: "Pour assistance, merci d’appeler notre hotline : ",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "Chargement...",
      COCO_MODAL_LOADING_TEXT:
        "Merci d’attendre un instant. Votre test va bientôt commencer.",
      COCO_MODAL_LOADING_SUBTEXT: "Merci de rester sur cette page.",
      REDIRECT_LOADING_TEXT: "Nous vérifions l’état de votre téléphone.",
      REDIRECT_LOADING_SUBTEXT: "Merci de rester sur cette page.",
      COUNTDOWN_TEXT:
        "Attendez <count> secondes s’il vous plaît. Nous vérifions l’état de votre téléphone.",
      IMAGE_CHECK_TEXT:
        "Attendez un instant s’il vous plait. Nous analysons l’image.",
    },
    DIGITS: en_DIGITS,
    LOCALE: {
      "en-HK": {
        ANDROID: "en_US",
        IOS: "en-US",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "Désolé, ce navigateur n'est pas supporté",
      MESSAGE: `Veuillez relancer le lien dans l'un des suivants:`,
      COPYURL: "Copier le lien",
    },
  },
  // Taiwan Chinese
  zhtw: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "螢幕測試",
        BACK_SCREEN_CRACKED: "Back Cover Test",
        PIXEL_DAMAGE: "Pixel Test",
        SPEAKER_TEST: "Speaker Test",
        MIC_TEST: "Mic Test",
      },
      STARTING: {
        HEADING: "歡迎進入裝置測試",
        SUBCONTENT: "請點擊測試項目或「開始測試」按鈕開始測試",
        BELOW_CONTENT: "開始測試",
      },
      INPROCESS: {
        HEADING: "你還有<> 個測試才能完成投保流程",
        SUBCONTENT:
          "請按尚未完成的測試項目或直接按 “繼續測試” 按鈕以進行測試。",
        BELOW_CONTENT: "繼續測試",
      },
      FINISHED: {
        HEADING: "恭喜你!已完成所有測試。",
        HEADING2: "Thank you for completing cracked screen test.",
        SUBCONTENT:
          "You will soon receive an email with the results of your test.",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        SUBCONTENT4: "We are analyzing your screen. Please wait.",
        BELOW_CONTENT: "已完成的測試:",
      },
      MISC: {
        DONE: "完成",
        CTA: "Let’s begin",
        ASSESSMENT: "Assessment",
        REMAINING_ASSESSMENTS: "檢測未完成: ",
        COMPLETED: "COMPLETED",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Cracked Screen Test",
          DESCRIPTION:
            "The following test will detect if your device contains any cracks on your front screen. It will take about <b>3 minutes</b> to complete.",
          LIST: {
            SHOW: true,
            HEADING: "What will you need?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "A clean medium-sized mirror",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "A well-lit room",
              },
            ],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "下一步",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "開始螢幕測試",
              SECONDARY_TEXT: "請先找一塊鏡子並閱讀說明，然後按 「開始測試」。",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "將裝置螢幕對準測試框",
              SECONDARY_TEXT:
                "請稍微傾斜裝置，將裝置營幕對準測試框。裝置在正確的位置後，測試框會變為綠色",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "保持裝置不動",
              SECONDARY_TEXT:
                "慢慢移動手機，直到看到<span>綠色</span>邊框，然後保持不動以拍攝照片。",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "查看及提交",
              SECONDARY_TEXT:
                "請確保相片清晰並完全在測試框內即可點擊「上傳相片」提交相片",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Cracked Screen Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Remove the phone case",
              SECONDARY_TEXT:
                'First, please remove the phone case and read the instructions. After that, please click on the "Start Back Cover Test" to initiate the test.',
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Let's test your phone's back cover",
              SECONDARY_TEXT: "Please find a mirror to implement the test.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display the phone back cover on the mirror and looking into the screen, try to fit the phone image in given frame until phone boundaries turn green.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the current position until the picture is taken and the result is displayed back to you.",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "Review and Submit",
              SECONDARY_TEXT:
                'Ensure the photo is clear and complete. You can then submit the picture by clicking on "Yes, Upload this photo".',
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone display",
              SECONDARY_TEXT:
                "First, please find a mirror and read the instructions. After that, please click on “Start Pixel Test” to initiate the test.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display your phone’s screen within the displayed frame. Once your device is in the correct position, the frame will turn green.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the correct position until the picture is taken and the result is displayed white to you.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Review and submit",
              SECONDARY_TEXT:
                "Ensure the photo is clear and completely within the frame. You can then submit the picture by clicking on “Yes, upload this photo”.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone speaker",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Listen and input the digits",
              SECONDARY_TEXT:
                "Carefully listen the 3 digits number played on your speaker and input the same number when asked. You can listen the 3 digits again if you like.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone Mic",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Speak displayed digits into the microphone",
              SECONDARY_TEXT:
                "Find a noise-free place and keep the mic near your mouth.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "開始測試",
        TOO_CLOSE_MOVE_FRAME: "太近了，請確保整個裝置螢幕在測試框中",
        POINT_SCREEN_AT_MIRROR: "將裝置螢幕面向鏡子",
        POSITION_PHONE_IN_FRAME: "對準裝置螢幕",
        CONFIRM: "若能清楚地看到 電話<device>，即可上傳照片或重拍.",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Back Cover Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full backscreen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point the phone backscreen at a mirror",
        POSITION_PHONE_IN_FRAME: "Position your phone in this frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Pixel Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full screen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s display within the frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Speaker Test",
        TYPE_NUMBERS: "Type the 3 digits number you just heard, in order",
        SUBMIT: "Submit",
        LISTEN_AGAIN: "Listen Again",
        LISTEN_TO_DIGITS: "Please click on 'Begin' button when you are ready",
        POPUP_HEADING: "Are you sure ! You entered <numbers>",
        POPUP_HEADING_2: "Are you sure !",
        BUTTON_TO_LISTEN: "Begin",
        CTA_Yes: "Yes",
        CTA_No: "No",
        CTA_Change: "Change",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Mic Test",
        MAIN_TEXT: "Please clearly speak the below digits into the phone",
        PLAY_RECORDING: "Play recording",
        SECONDARY_TEXT: "Say",
        BEGIN_BUTTON: "Tap & Speak",
        FINISH_BUTTON: "Done",
        CHECKBOX_TEXT: "My mic doesn't work",
        SUBMIT_BUTTON: "Yes, submit",
        RETRY_BUTTON: "No, speak again",
        MIC_PERMISSION_DENIED:
          "Please allow the app to access phone's Microphone for the test.",
        MIC_PERMISSION_DENIED_CTA: "Allow",
        MIC_IN_USE:
          "Some other app/s is using the mic, please close the other app/s before proceeding further",
        MIC_IN_USE_CTA: "Confirm",
        BEFORE_YOU_BEGIN: "BEFORE YOU BEGIN",
        BEFORE_YOU_BEGIN_TEXT:
          "You will be asked to allow  microphone access and we highly recommend you to do so else you will not be able to complete the mic test",
        BEFORE_YOU_BEGIN_CTA: "Allow",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      EXPERIMENTAL_FOLDABLE_TEXT:
        "我們無法偵測到任何裝置，將會於5秒內擷取您的畫面",
      SCREEN_TEST: "SCREEN TEST",
      HOW_IT_WORKS: "如何測試",
      START_TEST: "開始測試",
      GENERIC_WAITING: "請稍後",
      UPLOAD_PHOTO: "是, 上載相片",
      NO_RETAKE_PHOTO: "不，重考",
      RETAKE_PHOTO: "重拍",
      SHOW_TIPS: "顯示提示",
      CAMERA_STARTING_TXT: "開啟相機中",
      MOVE_PHONE_LEFT: "緩慢的將裝置向左移動",
      MOVE_PHONE_RIGHT: "緩慢的將裝置向右移動",
      MOVE_PHONE_TOP: "緩慢將裝置底部向上傾斜並對準測試框",
      MOVE_PHONE_BOTTOM: "緩慢將裝置頂部向下傾斜並對準測試框",
      TOO_FAR_MOVE_CLOSER: "太遠了，請把裝置靠近鏡子",
      ABOUT_TO_CAPTURE: "請勿移動手機至拍攝完成",
      VIDEO_SELECT_HEADING: "開始之前",
      VIDEO_SELECT_PARAGRAPH:
        "Make sure your phone's main camera is being used to perform the test.",
      VIDEO_SELECT_QUESTION: "Is this your phone's main camera?",
      VIDEO_SELECT_ACCEPT_BUTTON: "Yes, use this camera",
      VIDEO_SELECT_REJECT_BUTTON: "No, change camera",
      GDPR_TEXT:
        "Please ensure that nothing appears in the image except your phone. Ensure that your face is not within the frame.",
      NO_DEVICE_DETECTED: "無法在相片中檢測到裝置。 請再試一次。",
      LOW_CONFIDENCE_SCORE:
        "Sorry, the image is blurry. Please try keeping the device steady when the photo is being captured.",
      PERSON_DETECTED:
        "很抱歉，圖片中似乎拍攝到臉部， 請確保照片中僅拍攝了您的裝置設備",
      FINGER_DETECTED:
        "很抱歉，圖片上有手指。 請確保在檢測時間內沒有將手指放在螢幕上",
      MULTIPLE_CELLPHONE_DETECTED: "檢測到多部裝置，請重新拍照。",
      DULL_AND_BLURRY_IMAGE: "相片暗淡模糊，請穩握裝置重新拍攝。",
      BLURRY_IMAGE: "相片模糊，請穩握裝置重新拍攝。",
      DULL_IMAGE: "相片暗淡，請穩握裝置重新拍攝。",
      MIRROR_FRAUD: "注意！請將正確的裝置對準鏡子拍照",
      OPAQUE_SCREEN_CHECK:
        "裝置螢幕在照片中必須全黑 請重拍並保持裝置不動至相片拍完為止",
      API_IMAGE_PII_CHECK:
        "很抱歉，圖片中似乎拍攝到臉部， 請確保照片中僅拍攝了您的裝置設備",
      FRAUD_USER_INFO: "請勿移動手機至拍攝完成",
      IOS_DIALOG_BOX: "開始進行測試",
      NEXT: "下一步",
      UPLOADING_IMAGE: "上傳相片…",
      LONGER_WAITING_TEXT: "伺服器忙碌中，請稍後",
      WAIT_TEXT: "請稍後",
      NOTE_TEXT: "注意：若超過60秒請重新上傳",
    },
    COMPLETION: {
      TITLE: "完成",
      THANK_YOU:
        "Thank You for submitting the test. Please click on start next test to perform next test or go to dashboard to select the next test.",
      START_NEXT_TEST: "Start next test",
      GO_TO_DASHBOARD: "Go to dashboard",
      CLOSE: "You can now continue browsing or simply close your browser.",
    },
    OVERLAY_TIPS: {
      TITLE: "最佳測試效果提示",
      TIPS: [
        "使用乾淨的鏡子",
        "請將裝置外蓋和螢幕保護膜移除",
        "將螢幕對準鏡子",
        "與鏡子保持10公分距離",
      ],
    },
    ERRORS: {
      GENERIC_TITLE: "抱歉，出了一些問题",
      GENERIC_MESSAGE: "我們正在儘快修復此問題",
      OK: "OK",
      UNSUPPORTED_BROWSER_TITLE: "重要訊息",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "The camera could not be accessed in your current browser. Please re-open the link using Safari browser.",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "The camera could not be accessed in your current browser. Please re-open the link using Chrome browser.",
      UNSUPPORTED_BROWSER_CTA: "複製連結",
      FULLSCREEN_TITLE: "重要訊息",
      IMPORTANT_MESSAGE: "重要訊息",
      FULLSCREEN_SUBTEXT: "測試需在全螢幕模式下執行。",
      FULLSCREEN_CTA: "開啟全螢幕模式",
      CAMERA_ERROR: "啟動相機時發生錯誤",
      ENABLE_CAMERA: "請允許此應用程式使用手機的相機。",
    },
    HELP: {
      TITLE: "幫助",
    },
    EXPIRED: {
      TITLE: "此連結已過期",
      PRIMARY_TEXT: "請致電客服重新取得連結。",
    },
    _4xx: {
      TITLE: "404 -找不到網頁",
      DESCRIPTION:
        "找不到網頁。請確認輸入正確網址。若網址正確，頁面可能暫時不可用或已被刪除。",
      RETURN: "返回主頁",
      HELPLINE: "若需協助，請致電客服電話",
    },
    _5xx: {
      TITLE: "500 -抱歉，出了一些問题",
      DESCRIPTION: "我們正在儘快修復此問題",
      RETURN: "返回主頁",
      HELPLINE: "若需協助，請致電客服電話",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "載入中",
      COCO_MODAL_LOADING_TEXT: "請稍後 裝置測試即將開始",
      COCO_MODAL_LOADING_SUBTEXT: "請停留在此頁。",
      REDIRECT_LOADING_TEXT: "檢查裝置狀態中.",
      REDIRECT_LOADING_SUBTEXT: "請停留在此頁。",
      COUNTDOWN_TEXT: "裝置狀態審核中，請稍後<count>秒。",
      IMAGE_CHECK_TEXT: "照片分析中，請稍候。",
    },
    DIGITS: en_DIGITS,
    LOCALE: {
      "en-HK": {
        ANDROID: "en_US",
        IOS: "en-US",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "不支援此瀏覽器",
      MESSAGE: `請選擇以下任依一方式重啟連結:`,
      COPYURL: "複製連接",
    },
  },
  // ENGLISH PAYMAYA
  en_paymayaPH: {
    DASHBOARD: {
      TESTS: {
        FRONT_SCREEN_CRACKED: "Cracked Screen",
        BACK_SCREEN_CRACKED: "Back Cover Test",
        PIXEL_DAMAGE: "Pixel Test",
        SPEAKER_TEST: "Speaker Test",
        MIC_TEST: "Mic Test",
      },
      STARTING: {
        HEADING: "Welcome to your device assessment tool",
        SUBCONTENT:
          "To help us determine your device protection eligibility, some assessments may be required.",
      },
      INPROCESS: {
        HEADING:
          "You have <> tests remaining before finishing your application",
        SUBCONTENT:
          "You can click on the tests icons you have not finished yet or can directly click on “Continue test”.",
      },
      FINISHED: {
        HEADING: "Congratulations! You have completed all tests.",
        HEADING2: "Thank you for completing cracked screen test.",
        SUBCONTENT:
          "You will soon receive an email with the results of your test.",
        SUBCONTENT2:
          "We sent you an email with our diagnosis outcome. You can now close this window.",
        SUBCONTENT3:
          "Please check your email address for your subscription status.",
        SUBCONTENT4: "We are analyzing your screen. Please wait.",
      },
      MISC: {
        DONE: "DONE",
        CTA: "Let’s begin",
        ASSESSMENT: "Assessment",
        REMAINING_ASSESSMENTS: "Assessments remaining: ",
        COMPLETED: "COMPLETED",
      },
    },
    INSTRUCTIONS: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Cracked Screen Test",
          DESCRIPTION:
            "The following test will detect if your device contains any cracks on your front screen. It will take about <b>3 minutes</b> to complete.",
          LIST: {
            SHOW: true,
            HEADING: "What will you need?",
            DATA: [
              {
                ICON: faMirrorIcon,
                TITLE: "A clean medium-sized mirror",
              },
              {
                ICON: faLitroomIcon,
                TITLE: "A well-lit room",
              },
            ],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "First, face your phone in front of a mirror.",
              SECONDARY_TEXT:
                "Hold your phone in front of a mirror, and stand approximately an arms length away.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Make sure your phone is tilted forward.",
              SECONDARY_TEXT:
                "Slightly tilt your phone forward to make phone detection and photo-taking easier.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT:
                "Align your phone within the white frame, and hold still.",
              SECONDARY_TEXT:
                "Slowly move the phone around until the <span> green </span> border is visible, then hold still to capture the photo.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Finally, review your photo and submit.",
              SECONDARY_TEXT:
                "Ensure that the entire phone is in the picture, and submit the photo",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Cracked Screen Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Back Cover Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Remove the phone case",
              SECONDARY_TEXT:
                'First, please remove the phone case and read the instructions. After that, please click on the "Start Back Cover Test" to initiate the test.',
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Let's test your phone's back cover",
              SECONDARY_TEXT: "Please find a mirror to implement the test.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display the phone back cover on the mirror and looking into the screen, try to fit the phone image in given frame until phone boundaries turn green.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the current position until the picture is taken and the result is displayed back to you.",
              IMG_SRC: "intro-004.webp",
            },
            {
              PRIMARY_TEXT: "Review and Submit",
              SECONDARY_TEXT:
                'Ensure the photo is clear and complete. You can then submit the picture by clicking on "Yes, Upload this photo".',
              IMG_SRC: "intro-005.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Back Cover Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Pixel Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone display",
              SECONDARY_TEXT:
                "First, please find a mirror and read the instructions. After that, please click on “Start Pixel Test” to initiate the test.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Align your phone's screen within the frame",
              SECONDARY_TEXT:
                "Display your phone’s screen within the displayed frame. Once your device is in the correct position, the frame will turn green.",
              IMG_SRC: "intro-002.webp",
            },
            {
              PRIMARY_TEXT: "Hold your phone still",
              SECONDARY_TEXT:
                "Hold your phone in the correct position until the picture is taken and the result is displayed white to you.",
              IMG_SRC: "intro-003.webp",
            },
            {
              PRIMARY_TEXT: "Review and submit",
              SECONDARY_TEXT:
                "Ensure the photo is clear and completely within the frame. You can then submit the picture by clicking on “Yes, upload this photo”.",
              IMG_SRC: "intro-004.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Pixel Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Speaker Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone speaker",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone.",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Listen and input the digits",
              SECONDARY_TEXT:
                "Carefully listen the 3 digits number played on your speaker and input the same number when asked. You can listen the 3 digits again if you like.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Speaker Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        START_SCREEN: {
          TITLE: "Mic Test",
          DESCRIPTION: "",
          LIST: {
            SHOW: false,
            HEADING: "",
            DATA: [],
          },
          CTA: "Begin tutorial",
          CTA_2: "Skip tutorial",
        },
        INSTRUCTION_SCREEN: {
          STEP_BY_STEP: "A Step-by-Step Guide",
          CTA: "Next",
          CTA2: "Back",
          INSTRUCTIONS: [
            {
              PRIMARY_TEXT: "Let's test your phone Mic",
              SECONDARY_TEXT:
                "Please remove, if headphone, earphone and any blue-tooth device is connected to your phone",
              IMG_SRC: "intro-001.webp",
            },
            {
              PRIMARY_TEXT: "Speak displayed digits into the microphone",
              SECONDARY_TEXT:
                "Find a noise-free place and keep the mic near your mouth.",
              IMG_SRC: "intro-002.webp",
            },
          ],
        },
        END_SCREEN: {
          HEADING: "You’re ready to begin!",
          DESCRIPTION:
            "You have completed the tutorial for Mic Test. You can start the test when you are ready.",
          CTA: "Start test",
          CTA_2: "Read tutorial again",
        },
      },
    },
    DIAGNOSE_LABEL: {
      [CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Screen Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full screen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s screen within the frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Back Cover Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full backscreen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point the phone backscreen at a mirror",
        POSITION_PHONE_IN_FRAME: "Position your phone in this frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Pixel Test",
        TOO_CLOSE_MOVE_FRAME:
          "Too close, ensure the full screen is within frame",
        POINT_SCREEN_AT_MIRROR: "Point this screen at a mirror.",
        POSITION_PHONE_IN_FRAME: "Align your phone’s display within the frame.",
        CONFIRM: "Can you see your phone <device> clearly in this photo?",
      },
      [CONFIG.TEST_LABELS["SPEAKER_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Speaker Test",
        TYPE_NUMBERS: "Type the 3 digits number you just heard, in order",
        SUBMIT: "Submit",
        LISTEN_AGAIN: "Listen Again",
        LISTEN_TO_DIGITS: "Please click on 'Begin' button when you are ready",
        POPUP_HEADING: "Are you sure ! You entered <numbers>",
        POPUP_HEADING_2: "Are you sure !",
        BUTTON_TO_LISTEN: "Begin",
        CTA_Yes: "Yes",
        CTA_No: "No",
        CTA_Change: "Change",
        CHECKBOX_TEXT: "Can’t hear the digits played on speaker",
      },
      [CONFIG.TEST_LABELS["MIC_TEST"].name]: {
        GET_STARTED_BUTTON: "Start Mic Test",
        MAIN_TEXT: "Please clearly speak the below digits into the phone",
        PLAY_RECORDING: "Play recording",
        SECONDARY_TEXT: "Say",
        BEGIN_BUTTON: "Tap & Speak",
        FINISH_BUTTON: "Done",
        CHECKBOX_TEXT: "My mic doesn't work",
        SUBMIT_BUTTON: "Yes, submit",
        RETRY_BUTTON: "No, speak again",
        MIC_PERMISSION_DENIED:
          "Please allow the app to access phone's Microphone for the test.",
        MIC_PERMISSION_DENIED_CTA: "Allow",
        MIC_IN_USE:
          "Some other app/s is using the mic, please close the other app/s before proceeding further",
        MIC_IN_USE_CTA: "Confirm",
        BEFORE_YOU_BEGIN: "BEFORE YOU BEGIN",
        BEFORE_YOU_BEGIN_TEXT:
          "You will be asked to allow  microphone access and we highly recommend you to do so else you will not be able to complete the mic test",
        BEFORE_YOU_BEGIN_CTA: "Allow",
      },
    },
    DIAGNOSE_LABEL_COMMON: {
      SCREEN_TEST: "SCREEN TEST",
      HOW_IT_WORKS: "How it works",
      START_TEST: "Start test",
      GENERIC_WAITING: "One moment please",
      UPLOAD_PHOTO: "Yes, Upload this photo",
      NO_RETAKE_PHOTO: "No, Retake the photo",
      RETAKE_PHOTO: "Retake the photo",
      SHOW_TIPS: "Show tips",
      CAMERA_STARTING_TXT: "Wait while the camera is starting",
      MOVE_PHONE_LEFT: "Slowly move your phone left.",
      MOVE_PHONE_RIGHT: "Slowly move your phone right.",
      MOVE_PHONE_TOP:
        "Slowly tilt your phone top a bit upwards to fit it in frame",
      MOVE_PHONE_BOTTOM:
        "Slowly tilt your phone top a bit downwards and fit it in frame",
      TOO_FAR_MOVE_CLOSER: "Too far away, Move the phone closer to the mirror.",
      ABOUT_TO_CAPTURE: "Hold your phone still until the image is captured",
      VIDEO_SELECT_HEADING: "BEFORE YOU BEGIN",
      VIDEO_SELECT_PARAGRAPH:
        "Make sure your phone's main camera is being used to perform the test.",
      VIDEO_SELECT_QUESTION: "Is this your phone's main camera?",
      VIDEO_SELECT_ACCEPT_BUTTON: "Yes, use this camera",
      VIDEO_SELECT_REJECT_BUTTON: "No, change camera",
      EXPERIMENTAL_FOLDABLE_TEXT:
        "We are unable to detect a device, your screen will be automatically captured in 5 seconds.",
      GDPR_TEXT:
        "Please ensure that nothing appears in the image except your phone. Ensure that your face is not within the frame.",
      NO_DEVICE_DETECTED:
        "Sorry, the device can't be detected in image. Please try again.",
      LOW_CONFIDENCE_SCORE:
        "Sorry, the image is blurry. Please try keeping the device steady when the photo is being captured.",
      PERSON_DETECTED:
        "Sorry, it seems there is a face on the picture. Please make sure that only your device is captured in the photo",
      FINGER_DETECTED:
        "Sorry, there is (a) finger(s) on the picture. Please ensure that no finger is on screen during the test",
      MULTIPLE_CELLPHONE_DETECTED:
        "Multiple devices are detected, please capture again.",
      DULL_AND_BLURRY_IMAGE:
        "The image is blurred and dark. Please try again and keep your phone steady while taking the photo.",
      BLURRY_IMAGE:
        "The image is blurred. Please try again and keep your phone steady while taking the photo.",
      DULL_IMAGE:
        "The image is dark. Please try again and keep your phone steady while taking the photo.",
      MIRROR_FRAUD: "Caution ! Please capture the right device using a mirror",
      OPAQUE_SCREEN_CHECK:
        "Device screen must be completely black in captured image, please re-take and hold your phone still unless image is completely captured.",
      API_IMAGE_PII_CHECK:
        "Sorry, it seems there is a face on the picture. Please make sure that only your device is captured in the photo",
      FRAUD_USER_INFO:
        "Please hold your phone still until the image is captured",
      DEVICE_NOT_DETECTED: "Sorry, the device could not be detected.",
      DIAGNOSIS_HELP_HEADING: "You may try to perform the following:",
      DIAGNOSIS_HELP_STEPS: [
        {
          TITLE: "Clean the camera lens and mirror for better results.",
          IMG: "icon_camera.svg",
        },
        {
          TITLE: "Ensure the background does not contain any other devices.",
          IMG: "icon_background.svg",
        },
        {
          TITLE: "Avoid covering the screen with fingers.",
          IMG: "icon_fingers.svg",
        },
        {
          TITLE: "Perform the test in well lit environment.",
          IMG: "icon_lit.svg",
        },
        {
          TITLE:
            "Make sure you keep your phone steady and within the white frame.",
          IMG: "icon_steady.svg",
        },
      ],
      VIEW_INSTRUCTION: {
        HEADING: "Not sure how to perform the test?",
        TEXT: "View instructions",
        CTA: "Try Again",
      },
      CROPPED_IMAGE:
        "Unable to capture full device, please ensure device is within white border. Try again.",
      IOS_DIALOG_BOX: "You can now perform the test.",
      NEXT: "Next",
      UPLOADING_IMAGE: "Uploading Image...",
      LONGER_WAITING_TEXT:
        "This may take longer than usual as we are currently experiencing high traffic in our server. please wait a moment",
      WAIT_TEXT: "Please wait a moment",
      NOTE_TEXT:
        "Note: Please perform upload again if it is taking more than 60 seconds",
    },
    COMPLETION: {
      TITLE: "Done",
      THANK_YOU:
        "Thank You for submitting the test. Please click on start next test to perform next test or go to dashboard to select the next test.",
      START_NEXT_TEST: "Start next test",
      GO_TO_DASHBOARD: "Go to dashboard",
      CLOSE: "You can now continue browsing or simply close your browser.",
    },
    ERRORS: {
      GENERIC_TITLE: "Sorry, something went wrong.",
      GENERIC_MESSAGE:
        "We are working on getting this fixed as soon as we can. ",
      OK: "OK",
      UNSUPPORTED_BROWSER_TITLE: "Important Message",
      UNSUPPORTED_BROWSER_SUBTEXT:
        "The camera could not be accessed in your current browser. Please re-open the link using Safari browser.",
      ANDROID_UNSUPPORTED_BROWSER_SUBTEXT:
        "The camera could not be accessed in your current browser. Please re-open the link using Chrome browser.",
      UNSUPPORTED_BROWSER_CTA: "Copy URL",
      FULLSCREEN_TITLE: "Important Message",
      IMPORTANT_MESSAGE: "Important Message",
      FULLSCREEN_SUBTEXT:
        "The diagnosis test need to perform in the full screen mode.",
      FULLSCREEN_CTA: "Open Full Screen Mode",
      CAMERA_ERROR: "Error in accessing camera",
      ENABLE_CAMERA: "Please allow the app to access phone's camera.",
      DEVICE_ORIENTATION_HEADING: "Please rotate your device to portrait mode",
      DEVICE_ORIENTATION:
        "It appears that you are currently on landscape mode, please rotate your device to portrait mode in order to proceed.",
      FOLDABLE_TO_MAINSCREEN_HEADING:
        "Please unfold your device and continue on your main screen",
      FOLDABLE_TO_MAINSCREEN:
        "It appears that you are currently viewing this on your secondary screen, please unfold your device and view this on the main screen in order to proceed.",
      FOLDABLE_TO_SECONDARYSCREEN_HEADING:
        "Please continue the test on the secondary screen",
      FOLDABLE_TO_SECONDARYSCREEN: "",
    },
    HELP: {
      TITLE: "HELP",
    },
    EXPIRED: {
      TITLE: "The link has expired",
      PRIMARY_TEXT: "To generate a new link, please call our contact center",
    },
    _4xx: {
      TITLE: "404 - PAGE NOT FOUND",
      DESCRIPTION:
        "The page you are looking for cannot be found. Please ensure that you have entered the address correctly. If the address is correct, the page may be temporarily unavailable or may have been removed.",
      RETURN: "Back to Homepage",
      HELPLINE: "For any assistance, please call our Service Hotline: ",
    },
    _5xx: {
      TITLE: "500 - Sorry, something went wrong.",
      DESCRIPTION: "We are working on getting this fixed as soon as we can.",
      RETURN: "Back to Homepage",
      HELPLINE: "For any assistance, please call our Service Hotline: ",
    },
    PAGE_LOADER: {
      LOADING_TEXT: "Loading...",
      COCO_MODAL_LOADING_TEXT:
        "Please wait a moment. Your test will start soon.",
      COCO_MODAL_LOADING_SUBTEXT: "Please stay on this page.",
      REDIRECT_LOADING_TEXT: "We're checking your phone's condition now.",
      REDIRECT_LOADING_SUBTEXT: "Please stay on this page.",
      COUNTDOWN_TEXT:
        "Please wait for <count> seconds as we’re analyzing your phone’s condition now.",
      IMAGE_CHECK_TEXT:
        "Please wait for a moment while we are analyzing the image.",
    },
    DIGITS: en_DIGITS,
    LOCALE: {
      "en-HK": {
        ANDROID: "en_US",
        IOS: "en-US",
      },
    },
    NOT_SUPPORTED_BROWSER: {
      TITLE: "Sorry, this browser is not supported",
      MESSAGE: `Please re-launch link in one of the following:`,
      COPYURL: "Copy Link",
    },
  },
};

export default LabelHelper;

export const checkForConflictedLocale = (language, theme, tenantCode) => {
  return CONFIG.TenantCodeThemeMapping[tenantCode]?.hasUniqueTranslations &&
    CONFIG.TenantCodeThemeMapping[tenantCode]?.conflictingLocale.hasOwnProperty(
      language
    )
    ? true
    : false;
};
