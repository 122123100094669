import React, { useRef } from "react";
import LabelHelper from "helpers/labelHelper";
import cs from "classnames";
import Button from "components/button";
import RouteHelper from "helpers/routeHelper";
import { useDispatch, useSelector } from "react-redux";
import style from "./_5xx.module.scss";
import { setFullPageLoader } from "actions/deviceAssessmentsAction";
import { sendGTMDLPageView, getTenantKey } from "../../services/api/googleTag";
import { useNavigate } from "react-router-dom";

const currentErrorCode = 500;

const Error5xxPage = ({
  lang,
  helpLine,
  helpLineVisibility,
  history,
  ...res
}) => {
  const {
    location: { state: { errorCode = currentErrorCode } = {} } = { state: {} },
  } = res;
  const dispatch = useDispatch();
  const fullPageLoaderReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.fullPageLoader
  );
  let title = useRef(LabelHelper[lang]._5xx.TITLE);
  const navigate = useNavigate();

  React.useEffect(() => {
    let componentIsMounted = true;

    if (componentIsMounted) {
      sendGTMDLPageView(lang, getTenantKey(), "5xxError", '');
      if (errorCode !== currentErrorCode) {
        title.current = title.current.replace(currentErrorCode, errorCode);
      }
      dispatch(setFullPageLoader({ ...fullPageLoaderReducer, show: false }));
    }

    return () => {
      componentIsMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={cs({
        [style.page]: true,
        fullHeight: true,
      })}
    >
      <div
        className={cs({
          "container fullHeight": true,
          [style.container]: true,
        })}
      >
        <i className={`fa fa-exclamation-circle ${style.fontSizeAdjust}`}></i>
        <h1 data-cy="5xx-title">{title.current}</h1>
        <p>{LabelHelper[lang]._5xx.DESCRIPTION}</p>
        <br />
        {helpLineVisibility ? (
          <p>
            {LabelHelper[lang]._5xx.HELPLINE}
            {`<${helpLine}>`}
          </p>
        ) : null}
        <div
          data-cy="homepageButton"
          className={`${style.buttonGroup} fullWidth`}
        >
          <Button
            text={LabelHelper[lang]._5xx.RETURN}
            clickEvent={RouteHelper.routeToUrl.bind(this, {
              navigate,
              pathname: `/`,
              search: history.location.search,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default Error5xxPage;
