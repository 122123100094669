import axios from "axios";

export const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/v${process.env.REACT_APP_API_VERSION}`;

export const postRequest = async (url, payload, options) => {
  return axios.post(url, payload, options);
};

export const putRequest = async (url, payload, options) => {
  return axios.put(url, payload, options);
};

export const getRequest = async (url) => {
  return axios.get(url);
};
