import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import style from "./diagnosis-feedback-box.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { capturePhoneImage } from "../../actions/deviceAssessmentsAction";
import CONFIG from "config";

const FULL_DASH_ARRAY = 283;
let timeLeft = 0;

const DiagnosisFeedback = ({ feedbackText }) => {
  const [count, setCount] = useState(CONFIG.TIMER_COUNTDOWN);
  const [startTimer, setStartTimer] = useState(false);
  const dispatch = useDispatch();
  const countdownTimerReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.startCaptureTimer
  );

  useEffect(() => {
    countdownTimer(countdownTimerReducer);
  }, [countdownTimerReducer]); // eslint-disable-line

  useEffect(() => {
    if (startTimer) {
      setTimeout(() => {
        timeLeft = count - 1;
        if (timeLeft === 0) {
          dispatch(capturePhoneImage(true));
        } else {
          setCircleDasharray();
          setCount(timeLeft);
        }
      }, 1000);
    }
  }, [count, startTimer]); // eslint-disable-line

  const setCircleDasharray = () => {
    const circleDasharray = `${(
      calculateTimeFraction() * FULL_DASH_ARRAY
    ).toFixed(0)} ${FULL_DASH_ARRAY}`;

    document
      .getElementById("running-circle")
      .setAttribute("stroke-dasharray", circleDasharray);
  };

  const calculateTimeFraction = () => {
    const rawTimeFraction = timeLeft / CONFIG.TIMER_COUNTDOWN;
    return (
      rawTimeFraction - (1 / CONFIG.TIMER_COUNTDOWN) * (1 - rawTimeFraction)
    );
  };

  const countdownTimer = (action) => {
    switch (action) {
      case false:
        document.getElementById("countdownTimer").style.display = "none";
        setStartTimer(false);
        break;
      case true:
      default:
        document.getElementById("countdownTimer").style.display = "block";
        setStartTimer(true);
        break;
    }
  };

  return (
    <div className="container-fluid">
      <div
        className={`row align-items-center justify-content-center px-2 ${
          feedbackText.inverted ? style.invertedText : ""
        } ${style.feedbackBox}`}
      >
        <div className={`${startTimer ? "col-8 pe-0" : "col-12"}`}>
          <h3 className={`text-center ${style.feedbackText}`}>
            {feedbackText.text}
          </h3>
        </div>
        <div id="countdownTimer" className="col-4">
          <div className={style.timer}>
            <svg
              className={style.timer_svg}
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g className={style.svg_circle}>
                <circle
                  className={style.elapsed_circle}
                  cx="50"
                  cy="50"
                  r="45"
                ></circle>
                <path
                  id="running-circle"
                  strokeDasharray={FULL_DASH_ARRAY}
                  strokeLinecap={"round"}
                  className={`${style.circleColor} ${style.background_circle}`}
                  d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                ></path>
              </g>
            </svg>
            <span className={style.timerText}>{count}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

DiagnosisFeedback.propTypes = {
  feedbackText: PropTypes.object,
};

DiagnosisFeedback.defaultProps = {
  feedbackText: {
    text: "",
    inverted: false,
  },
};

export default DiagnosisFeedback;
