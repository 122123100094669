import ActionsHelper from "helpers/actionsHelper";
import CameraHelper from "helpers/cameraHelper";
import { deviceAssessmentsApi } from "../services/api";
import { convertToAudioBlob } from "../services/micService";
import CONFIG from "config";
import { isAutomatedTest } from "../services/middlewareModel";
import DeviceHelper from "helpers/deviceHelper";

export const getTestListSuccess = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.TEST_LIST_SUCCESS,
  response,
});

export const updateTestArray = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.UPDATE_TESTS,
  response,
});

export const startCaptureTimer = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.START_CAPTURE_TIMER,
  response,
});

export const capturePhoneImage = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.CAPTURE_PHONE_IMAGE,
  response,
});

export const setDetectionModel = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.COCO_SSD_MODEL,
  response,
});
export const setOCRData = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.OCR_DATA,
  response,
});

export const setFullPageLoader = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.PAGE_LOADER,
  response,
});

export const setC2PSaas = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.C2PSAAS,
  response,
});

export const setCameraDeviceID = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.CAMERA_DEVICE_ID,
  response,
});

export const setSlowAPI = (response) => ({
  type: ActionsHelper.DEVICE_ASSESSMENTS.SLOW_API,
  response,
});

export const getTestsList = async (dispatch, transactionId) => {
  try {
    const result = await deviceAssessmentsApi.getAllTestData(transactionId);
    if (result?.data) {
      const c2pSupportedTests =
        (result.data.device && result.data.device.tests) || [];
      dispatch(getTestListSuccess(c2pSupportedTests));
      return result.data;
    } else throw new Error("No tests found");
  } catch (error) {
    throw error.response;
  }
};

export const imageProcessing = async (transactionId, data) => {
  try {
    const response = await deviceAssessmentsApi.imageProcessing(
      transactionId,
      data
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export const fraudDetection = async (OCRImg, transactionId, characters) => {
  try {
    const response = deviceAssessmentsApi.fraudDetection(
      OCRImg,
      transactionId,
      characters
    );
    return (await response).data;
  } catch (error) {
    throw error.response;
  }
};

export const getSignedURL = async (
  dispatch,
  data,
  transactionId,
  testName,
  tenantCode
) => {
  // Adding timeout for slow API
  const timeout = isAutomatedTest(tenantCode) ? 90 : 6000;
  const slowAPItimer = setTimeout(() => {
    dispatch(setSlowAPI(true));
  }, timeout);

  try {
    let uploadObject = null;
    const makeAndModel = DeviceHelper.getMakeAndModel();
    if (makeAndModel !== null) {
      uploadObject = {
        vendor: makeAndModel.vendor || null,
        model: makeAndModel.model || null,
        marketingName: makeAndModel.marketingName || null,
        osName: makeAndModel.osName || null,
      };
    }

    const response = await deviceAssessmentsApi.fetchSignedURL(
      transactionId,
      testName,
      uploadObject
    );
    if (response?.data?.uploadURL) {
      try {
        await uploadDataToS3(response?.data?.uploadURL, data, testName);

        if (response?.data?.assetId) {
          return response?.data?.assetId;
        }

        return true;
      } catch (error) {
        throw error.response;
      }
    } else {
      throw new Error("uploadURL missing");
    }
  } catch (error) {
    throw error.response;
  } finally {
    clearTimeout(slowAPItimer);
  }
};

export const uploadDataToS3 = async (signedURL, data, testName) => {
  // Converting payload for uploading to S3
  let contentType = "image/jpeg";
  if (testName === CONFIG.TEST_LABELS["MIC_TEST"].name) {
    contentType = "audio/mpeg";
    data = await convertToAudioBlob(data);
  } else {
    try {
      data = await CameraHelper.convertToImageBlob(data);
    } catch (error) {
      throw error?.response;
    }
  }
  try {
    const response = await deviceAssessmentsApi.upload(
      signedURL,
      data,
      contentType
    );
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const getMicTestData = async (
  transactionId,
  locale,
  cb,
  errorHandler
) => {
  try {
    const result = await deviceAssessmentsApi.getMicTestData(
      transactionId,
      locale
    );
    if (cb) {
      cb(result);
    }
  } catch (error) {
    if (errorHandler) {
      const errorData = error && error.response ? error.response : null;
      errorHandler(errorData);
    }
  }
};

export const submitReport = async (dispatch, transactionId, payload) => {
  try {
    const response = await deviceAssessmentsApi.submit(transactionId, payload);
    dispatch(updateTestArray(payload.type));
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateAssessmentStatus = async (
  transactionId,
  payload
) => {
  try {
    await deviceAssessmentsApi.updateStatus(transactionId, payload);
  } catch (error) {
    const errorData = error && error.response ? error.response : null;
    console.error(errorData);
  }
};