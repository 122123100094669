import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import style from "./button.module.scss";
import Button from "react-bootstrap/Button";

const C2PButton = ({
  text,
  color,
  show,
  disabled,
  clickEvent,
  dataHelper,
  additionalStyle,
}) => {
  return (
    <Button
      active={false}
      className={cs({
        [style.c2pButton]: true,
        "d-none": !show,
        [style["btn-primary"] + " btn-primary"]: color === "primary",
        [style["btn-outline-primary"] + " btn-outline-primary"]:
          color === "secondary",
        [additionalStyle]: true,
      })}
      title={text}
      disabled={disabled}
      onClick={clickEvent}
      data-cy={dataHelper}
      aria-label="button"
    >
      {text}
    </Button>
  );
};

C2PButton.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  clickEvent: PropTypes.func,
  dataHelper: PropTypes.string,
};

C2PButton.defaultProps = {
  text: " ",
  color: "primary",
  disabled: false,
  show: true,
};

export default C2PButton;
