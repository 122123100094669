import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Redux store settings
import configureStore from "./store/configureStore";

const store = configureStore()["store"];
const history = configureStore()["history"];

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
