const BEARER_TOKEN_KEY = "BEARER_TOKEN";

const setBearerToken = (token, keyIdentifier) =>
  token &&
  localStorage.setItem(
    BEARER_TOKEN_KEY,
    JSON.stringify({ token, keyIdentifier })
  );

const bearerToken = () => {
  try {
    return JSON.parse(localStorage.getItem(BEARER_TOKEN_KEY));
  } catch (error) {
    return null;
  }
};

const Auth = {
  setBearerToken,
  bearerToken,
};

export default Auth;
