import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import cs from "classnames";
import style from "./main-diagnosis.module.scss";
import { setFullPageLoader } from "../../actions/deviceAssessmentsAction";
import CameraHelper from "../../helpers/cameraHelper";
import LabelHelper from "../../helpers/labelHelper";
import RouteHelper from "../../helpers/routeHelper";
import DeviceHelper from "../../helpers/deviceHelper";
import FullScreenHelper from "../../helpers/fullScreenHelper";
import { useNavigate, useParams } from "react-router-dom";
import ModalPopup from "../../components/modalPopup";
import ScreenCapture from "../../components/screenCapture";
import DiagnosisFeedback from "../../components/diagnosis-feedback-box";
import CONFIG from "config";
import {
  sendGTMDLPageView,
  sendTransactionId,
  sendGTMDLModelError,
  getTenantKey,
} from "../../services/api/googleTag";
import { isAutomatedTest } from "services/middlewareModel";

const bodyElement = document.getElementsByTagName("body")[0];
let isFullScreenMode = false;

const MainDiagnosis = ({
  lang,
  history,
  transactionId,
  tenantCode,
  ...res
}) => {
  const dispatch = useDispatch();
  const { type: testName } = useParams();
  const reduxTestData =
    useSelector((state) => state.DeviceAssessmentsReducer.testArray) || [];
  const ODMReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.ODMModel
  );
  const centralText =
    LabelHelper[lang].DIAGNOSE_LABEL_COMMON.POSITION_PHONE_IN_FRAME;
  const navigate = useNavigate();
  const [startTest, setTest] = useState(false);
  const [feedbackText, setFeedbackText] = useState({
    text: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.CAMERA_STARTING_TXT,
    inverted: false,
  });
  const [directionArrows, showArrows] = useState({
    left: false,
    right: false,
    top: false,
    bottom: false,
  });
  const [errorModal, showErrorModal] = useState({
    show: false,
    title: "",
    subText: "",
    errorType: null,
    CTA: "",
  });

  useEffect(() => {
    sendGTMDLPageView(
      lang,
      getTenantKey(),
      `${CONFIG.GA_TAGS[testName]} diagnosis`,
      ""
    );
    sendTransactionId(transactionId);
    let componentIsMounted = true;
    if (componentIsMounted) {
      dispatch(
        setFullPageLoader({
          show: true,
          mainText: LabelHelper[lang].PAGE_LOADER.COCO_MODAL_LOADING_TEXT,
          subText: LabelHelper[lang].PAGE_LOADER.COCO_MODAL_LOADING_SUBTEXT,
        })
      );

      if (ODMReducer) {
        if (DeviceHelper.isAppleDevice() || isAutomatedTest(tenantCode)) {
          setTest(true);
        } else {
          isFullScreenMode = false;
          bodyElement.onfullscreenchange = fullscreenChangeEvent;
          showErrorModal({
            ...errorModal,
            show: true,
            title: LabelHelper[lang].ERRORS.FULLSCREEN_TITLE,
            subText: LabelHelper[lang].ERRORS.FULLSCREEN_SUBTEXT,
            errorType: "FULLSCREEN",
            CTA: LabelHelper[lang].ERRORS.FULLSCREEN_CTA,
          });
        }
      }
    }

    return () => (componentIsMounted = false);
  }, [ODMReducer]); // eslint-disable-line

  const diagnosisCompleted = () => {
    if (!DeviceHelper.isAppleDevice()) {
      FullScreenHelper.exitFullScreenMode(isFullScreenMode)
        .then(() => {
          isFullScreenMode = false;
          redirectToResult();
        })
        .catch(() => {
          RouteHelper.replaceUrl({
            navigate,
            pathname: `/diagnosis/${testName}`,
            search: history.location.search,
          });
        });
    } else {
      redirectToResult();
    }
  };

  const fullscreenChangeEvent = () => {
    isFullScreenMode = !isFullScreenMode;

    if (!isFullScreenMode && !errorModal.show) {
      setTest(false);
      showErrorModal({
        show: true,
        title: LabelHelper[lang].ERRORS.FULLSCREEN_TITLE,
        subText: LabelHelper[lang].ERRORS.FULLSCREEN_SUBTEXT,
        errorType: "FULLSCREEN",
        CTA: LabelHelper[lang].ERRORS.FULLSCREEN_CTA,
      });
    }
  };

  const handleError = () => {
    switch (errorModal.errorType) {
      case "FULLSCREEN":
        FullScreenHelper.enterFullScreenMode(bodyElement)
          .then(() => {
            setTest(true);
          })
          .catch(() => {
            sendGTMDLModelError(
              lang,
              getTenantKey(),
              `${CONFIG.GA_TAGS[testName]} fullScreenAccessError`
            ); //eslint-disable-line
            backToInstructions();
          });
        break;

      case "CAMERA_ERROR":
        if (!DeviceHelper.isAppleDevice()) {
          FullScreenHelper.exitFullScreenMode(isFullScreenMode)
            .then(() => {
              isFullScreenMode = false;
              backToInstructions();
            })
            .catch(() => {
              sendGTMDLModelError(
                lang,
                getTenantKey(),
                `${CONFIG.GA_TAGS[testName]} CameraAccessError`
              ); //eslint-disable-line
              backToInstructions();
            });
        } else {
          backToInstructions();
        }
        break;

      default:
        RouteHelper.routeToUrl({
          navigate,
          pathname: RouteHelper.routesLabels[testName],
          search: history.location.search,
        });
        break;
    }
    showErrorModal({ ...errorModal, show: false });
  };

  const redirectToResult = () => {
    RouteHelper.replaceUrl({
      navigate,
      pathname: `/result/${testName}`,
      search: history.location.search,
    });
  };

  const backToInstructions = () => {
    RouteHelper.replaceUrl({
      navigate,
      pathname: `/instruction/${testName}`,
      search: history.location.search,
    });
  };

  const setFrameCentralText = () => {
    return feedbackText.text ===
      LabelHelper[lang].DIAGNOSE_LABEL[testName].POINT_SCREEN_AT_MIRROR
      ? centralText
      : null;
  };

  return (
    <>
      <div className={style.diagnose}>
        <div className={style.cameraContainer}>
          <div
            style={{
              height: `${CameraHelper.getWhiteFrameBoxMeasurements("height")}%`,
              width: `${CameraHelper.getWhiteFrameBoxMeasurements("width")}%`,
            }}
            className={style.cameraMask}
          >
            <div className={style.blockFrame}>
              <span className={style.left_corner}></span>
              <span className={style.right_corner}></span>
              <span className={style.bottom_left_corner}></span>
              <span className={style.bottom_right_corner}></span>
              <span
                data-cy="central-text"
                className={cs({
                  [style.text_center]: true,
                  [style.invertedText]: feedbackText.inverted,
                })}
              >
                {setFrameCentralText()}
              </span>
            </div>
            <div className={style.arrows}>
              <i
                className={`fa fa-angle-double-down ${style.arrowDown} ${
                  directionArrows.bottom ? "" : "d-none"
                }`}
                aria-hidden="true"
              ></i>
              <i
                className={`fa fa-angle-double-up ${style.arrowUp} ${
                  directionArrows.top ? "" : "d-none"
                }`}
                aria-hidden="true"
              ></i>
              <i
                className={`fa fa-angle-double-left ${style.arrowLeft} ${
                  directionArrows.left ? "" : "d-none"
                }`}
                aria-hidden="true"
              ></i>
              <i
                className={`fa fa-angle-double-right ${style.arrowRight} ${
                  directionArrows.right ? "" : "d-none"
                }`}
                aria-hidden="true"
              ></i>
            </div>
          </div>

          {reduxTestData.length ? (
            <ScreenCapture
              lang={lang}
              testName={testName}
              tenantCode={tenantCode}
              startTest={startTest}
              setFeedbackText={(value) => setFeedbackText(value)}
              isInvertedText={
                testName === CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name
                  ? false
                  : true
              }
              showArrows={(value) => showArrows(value)}
              errorModal={errorModal}
              showErrorModal={(value) => showErrorModal(value)}
              diagnosisCompleted={diagnosisCompleted}
              {...res}
            />
          ) : null}
        </div>

        <DiagnosisFeedback feedbackText={feedbackText} {...res} />

        <ModalPopup
          lang={lang}
          show={errorModal.show}
          title={errorModal.title}
          message={errorModal.subText}
          CTA={errorModal.CTA}
          clickEvent={handleError}
        />
      </div>
    </>
  );
};

export default MainDiagnosis;
