import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CONFIG from "../../config";
import style from "./completion.module.scss";
import LoadingView from "components/loadingView";
import C2PButton from "components/button";
import LabelHelper from "helpers/labelHelper";
import RouteHelper from "helpers/routeHelper";
import ErrorHelper from "helpers/errorHelper";
import {
  startNextTest,
  routeToDashboard,
} from "../../services/middlewareModel";
import {
  submitReport,
  setSlowAPI,
} from "../../actions/deviceAssessmentsAction";
import {
  getTenantKey,
  sendGTMDLConversion,
  sendGTMDLPageView,
  sendTransactionId,
} from "../../services/api/googleTag";
import { faCheckCircleIcon } from "assets/C2Picons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Completion = ({ lang, transactionId, history, ...res }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { type: testName } = useParams();
  const [processing, setProcessing] = React.useState(true);
  const reduxTestData =
    useSelector((state) => state.DeviceAssessmentsReducer.testArray) || [];

  useEffect(() => {
    dispatch(setSlowAPI(false));
    let payload = state || {};

    submitReport(dispatch, transactionId, { ...payload, type: testName })
      .catch((error) => {
        if (
          error?.data?.code !== "TEST_ALREADY_SUBMITTED" ||
          error?.data?.code !== "ALREADY_SUBMITTED"
        ) {
          ErrorHelper(error, history, navigate);
        }
      })
      .finally(() => {
        const pendingTests = reduxTestData.filter(
          (test) => test.status === "PENDING"
        );
        setProcessing(false);
        if (!pendingTests.length) {
          sendGTMDLPageView(
            lang,
            getTenantKey(),
            `${CONFIG.GA_TAGS[testName]} completed`,
            ""
          );

          sendGTMDLConversion(
            lang,
            getTenantKey(),
            "C2PTestCompleted",
            `${CONFIG.GA_TAGS[testName]} completed`
          );
          sendTransactionId(transactionId);
          routeToDashboard(history, navigate);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const proceedWithNextTest = () => {
    const type = startNextTest(reduxTestData, false);

    sendGTMDLPageView(
      lang,
      getTenantKey(),
      `${CONFIG.GA_TAGS[testName]} completed`,
      ""
    );
    sendGTMDLConversion(
      lang,
      getTenantKey(),
      "C2PTestCompleted",
      `${CONFIG.GA_TAGS[testName]} completed`
    );
    sendTransactionId(transactionId);
    RouteHelper.replaceUrl({
      navigate,
      pathname: `/instruction/${type}`,
      search: history.location.search,
    });
  };

  const goToDashboard = () => {
    RouteHelper.replaceUrl({
      navigate,
      pathname: "/",
      search: history.location.search,
    });
  };

  return (
    <>
      <div className={style.completion}>
        <div className="container text-center">
          <FontAwesomeIcon
            className={style.iconButton}
            aria-label={testName}
            icon={faCheckCircleIcon}
          />
          <h2 data-cy="completion-title" className="text-center">
            {LabelHelper[lang].COMPLETION.TITLE}
          </h2>
          <p className={"text-justify"}>
            {LabelHelper[lang].COMPLETION.THANK_YOU}
          </p>

          <div
            className={
              style.buttonGroup + " d-flex flex-column align-items-center gap-3"
            }
          >
            <C2PButton
              text={LabelHelper[lang].COMPLETION.START_NEXT_TEST}
              clickEvent={proceedWithNextTest}
              dataHelper="proceedWithNextTest"
            />
            <button
              className={style.linkBtn + " btn btn-link text-decoration-none"}
              onClick={goToDashboard}
              data-cy="goToDashboard"
            >
              {LabelHelper[lang].COMPLETION.GO_TO_DASHBOARD}
            </button>
          </div>
        </div>
      </div>
      <LoadingView show={processing} />
    </>
  );
};

export default Completion;
