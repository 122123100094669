import RouteHelper from "./routeHelper";

const RouteTo4xx = (navigate, errorCode) => {
  RouteHelper.replaceUrl({
    navigate,
    pathname: "/4xx",
    search: "",
    state: { errorCode },
  });
};

const RouteTo5xx = (navigate, errorCode) => {
  RouteHelper.replaceUrl({
    navigate,
    pathname: "/5xx",
    search: "",
    state: { errorCode },
  });
};

function ErrorHelper(error, history, navigate) {
  if (error?.status >= 400 && error?.status <= 499) {
    RouteTo4xx(navigate, error?.status);
  } else if (error?.status >= 500 && error?.status <= 599) {
    console.log(error?.status);
    RouteTo5xx(navigate, error?.status);
  } else {
    RouteTo4xx(navigate, error?.status);
  }
}

export default ErrorHelper;
