// generated from "C2P (Click 2 Protect)" using the "Awesome Icon Export" Figma Plugin
export const faCrackedScreenTestIcon = {
  prefix: "fas",
  iconName: "crackedscreentest",
  icon: [
    32,
    32,
    [],
    "e007",
    "M7,2h16v26h-16v-12h2v10h12v-22h-12v6h-2zM9,14h-2v-2h2zM17,25h-4v-2h4zM22,22h-14v-2h14zM22,8h-14v-2h14zM20.24074,6.34921l1.51851,1.30158l-2.68321,3.13041l0.46458,2.32292l-1.34593,3.21159l-1.22424,4.9255l-1.94094,-0.48242l1.26127,-5.0745l1.16858,-2.78841l-0.53542,-2.67708z",
  ],
};
export const faBackCoverTestIcon = {
  prefix: "fas",
  iconName: "backcovertest",
  icon: [
    32,
    32,
    [],
    "e005",
    "M7,2h16v26h-16v-12h2v10h12v-22h-12v6h-2zM10,6.5c0,-0.82843 0.67157,-1.5 1.5,-1.5c0.82843,0 1.5,0.67157 1.5,1.5c0,0.82843 -0.67157,1.5 -1.5,1.5c-0.82843,0 -1.5,-0.67157 -1.5,-1.5zM11.5,7c0.27614,0 0.5,-0.22386 0.5,-0.5c0,-0.27614 -0.22386,-0.5 -0.5,-0.5c-0.27614,0 -0.5,0.22386 -0.5,0.5c0,0.27614 0.22386,0.5 0.5,0.5zM7,14v-2h2v2z",
  ],
};
export const faPixelTestIcon = {
  prefix: "fas",
  iconName: "pixeltest",
  icon: [
    32,
    32,
    [],
    "e006",
    "M7,2h16v26h-16v-12h2v10h12v-22h-12v6h-2zM9,14h-2v-2h2zM19,18h-2v-2h2zM19,14h-2v-2h2zM16,17h-2v-2h2zM16,13h-2v-2h2zM17,25h-4v-2h4zM22,22h-14v-2h14zM22,8h-14v-2h14z",
  ],
};
export const faSpeakerTestIcon = {
  prefix: "fas",
  iconName: "speakertest",
  icon: [
    32,
    32,
    [],
    "e003",
    "M19.00002,5.48346v6.51654h-2v-3.48346l-5.79474,2.48346h-4.20528v8h4.20528l5.79474,2.48346v-3.48346h2v6.51654l-8.20526,-3.51654h-5.79477v-12h5.79477zM12,13v4h-2v-4zM19,14v2h-2v-2zM22.32132,12.77157c-0.40082,-0.53443 -0.87822,-0.77157 -1.32132,-0.77157v-2c1.21375,0 2.23636,0.65829 2.92132,1.57157c0.68473,0.91298 1.07868,2.12841 1.07868,3.42843c0,1.30002 -0.39395,2.51545 -1.07868,3.42843c-0.68496,0.91328 -1.70757,1.57157 -2.92132,1.57157v-2c0.4431,0 0.9205,-0.23714 1.32132,-0.77157c0.40105,-0.53474 0.67868,-1.31931 0.67868,-2.22843c0,-0.90912 -0.27763,-1.69369 -0.67868,-2.22843z",
  ],
};
export const faMicrophoneTestIcon = {
  prefix: "fas",
  iconName: "microphonetest",
  icon: [
    32,
    32,
    [],
    "e004",
    "M16,20v6h-2v-6zM20,14h2c0,3.86599 -3.13401,7 -7,7c-3.86599,0 -7,-3.13401 -7,-7h2c0,2.76142 2.23858,5 5,5c2.76142,0 5,-2.23858 5,-5zM19,8v6c0,2.20914 -1.79086,4 -4,4c-2.20914,0 -4,-1.79086 -4,-4v-6c0,-2.20914 1.79086,-4 4,-4c2.20914,0 4,1.79086 4,4zM13,14c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2v-6c0,-1.10457 -0.89543,-2 -2,-2c-1.10457,0 -2,0.89543 -2,2zM16,12h-2v-2h2zM12,26v-2h6v2z",
  ],
};

export const faLitroomIcon = {
  prefix: "fas",
  iconName: "litroom",
  icon: [
    32,
    32,
    [],
    "e000",
    "M13.125,3.81248l1.68751,-2.37495l1.68751,2.37495zM16.50002,26.25l-1.68751,2.31255l-1.68751,-2.31255zM25.375,10.87502l-1.68749,-2.93747l2.87498,0.24994zM6,22.12502l-2.875,-0.25002l1.12501,-2.68745zM3.125,8.18749l2.875,-0.24994l-1.74999,2.93747zM25.375,19.18755l1.1875,2.68745l-2.87498,0.25002zM8.75,12.12502c0,-3.25 2.62502,-5.87502 5.87502,-5.87502c3.25,0 5.875,2.62502 5.875,5.87502c0,2.0625 -1.0625,3.9375 -2.75,5v1.37497l-6.37501,0.81253v-2.24998c-1.625,-1.0625 -2.62501,-2.93752 -2.62501,-4.93752zM12.5625,15.87502l0.43751,0.25002v1.37497l3.125,-0.37498v-0.87502l0.43749,-0.25002c1.4375,-0.75 2.31251,-2.18748 2.31251,-3.81248c0,-2.375 -1.93749,-4.31252 -4.24999,-4.31252c-2.375,0 -4.25001,1.93753 -4.25001,4.25003c0,1.5625 0.875,3 2.1875,3.75zM17.75002,22.12502h-6.37501v-1.62498h6.37501zM13.68752,22.8125h1.68749v1.62498h-1.68749zM16.12501,16.93748h-1.75001",
  ],
};
export const faMirrorIcon = {
  prefix: "fas",
  iconName: "mirror",
  icon: [
    32,
    32,
    [],
    "e001",
    "M15,12l-3,3l-1.41421,-1.41421l3,-3zM15.58579,18.58579l3,-3l1.41421,1.41421l-3,3zM12.5,19.5l-1.41421,-1.41421l6.5,-6.5l1.41421,1.41421zM23,28h-16v-26h16zM21,4h-12v22h12z",
  ],
};
export const faCallIcon = {
  prefix: "fas",
  iconName: "call",
  icon: [
    32,
    32,
    [],
    "e002",
    "M2,8h7v6.77589l-3.5833,0.91902c1.41508,3.59065 4.22169,7.57521 9.84729,9.35364l0.94197,-4.04855h6.79404v7h-1c-2.33292,0 -4.40609,-0.26266 -6.24755,-0.73315c-7.73008,-1.97501 -11.12805,-7.53167 -12.60025,-11.95079c-0.57557,-1.7277 -0.86284,-3.29381 -1.00666,-4.42971c-0.07202,-0.56883 -0.10838,-1.03223 -0.12674,-1.35714c-0.00919,-0.16252 -0.01388,-0.29061 -0.01629,-0.38013c-0.0012,-0.04477 -0.00183,-0.07992 -0.00216,-0.10493l-0.0003,-0.02986l-0.00005,-0.00912l-0.00001,-0.00308l0,-0.00117c0,0 0,-0.00092 1,-0.00092l-1,0.00092zM4.05974,10c0.01762,0.18928 0.04043,0.40197 0.06995,0.63514c0.10622,0.83897 0.29855,1.93665 0.64584,3.15948l2.22446,-0.57051v-3.22411zM17.20137,25.54729c1.15549,0.23445 2.41831,0.38575 3.79863,0.43507v-2.98236h-3.20596zM12,2h16v13h-8.58579l-1.70711,1.70711l-1.41421,-1.41421l2.29289,-2.29289h7.41421v-9h-12v7h-2zM17,6h7v2h-7zM22,9h2v2h-2z",
  ],
};
export const faAttentionIcon = {
  prefix: "fas",
  iconName: "attention",
  icon: [
    32,
    32,
    [],
    "e008",
    "M15,18v-7h2v7zM15,21v-2h2v2zM4,16c0,-6.62742 5.37258,-12 12,-12c6.62742,0 12,5.37258 12,12c0,6.62742 -5.37258,12 -12,12c-6.62742,0 -12,-5.37258 -12,-12zM16,26c5.52285,0 10,-4.47715 10,-10c0,-5.52285 -4.47715,-10 -10,-10c-5.52285,0 -10,4.47715 -10,10c0,5.52285 4.47715,10 10,10z",
  ],
};
export const faCheckIcon = {
  prefix: "fas",
  iconName: "check",
  icon: [
    32,
    32,
    [],
    "e013",
    "M13.12133,19.12622l9.12623,-9.12622l1.41422,1.41421l-10.54045,10.54044l-5.12133,-5.12134l1.41421,-1.41421z",
  ],
};
export const faCheckCircleIcon = {
  prefix: "fas",
  iconName: "check-circle",
  icon: [
    32,
    32,
    [],
    "e015",
    "M21.70711,13.70713l-6.70711,6.70711l-4.70711,-4.70711l1.41421,-1.41421l3.29289,3.29289l5.29289,-5.29289zM4,16c0,-6.62742 5.37258,-12 12,-12c6.62742,0 12,5.37258 12,12c0,6.62742 -5.37258,12 -12,12c-6.62742,0 -12,-5.37258 -12,-12zM16,26c5.52285,0 10,-4.47715 10,-10c0,-5.52285 -4.47715,-10 -10,-10c-5.52285,0 -10,4.47715 -10,10c0,5.52285 4.47715,10 10,10z",
  ],
};
export const faCloseIcon = {
  prefix: "fas",
  iconName: "close",
  icon: [
    32,
    32,
    [],
    "e009",
    "M21,22.41421l-12,-12l1.41421,-1.41421l12,12zM10.41421,22.41421l12,-12l-1.41421,-1.41421l-12,12z",
  ],
};
export const faChevronDownIcon = {
  prefix: "fas",
  iconName: "chevron-down",
  icon: [
    32,
    32,
    [],
    "e010",
    "M22.41419,13.41424l-6.70711,6.70711l-6.70711,-6.70711l1.41421,-1.41421l5.29289,5.29289l5.29289,-5.29289z",
  ],
};
export const faChevronRightIcon = {
  prefix: "fas",
  iconName: "chevron-right",
  icon: [
    32,
    32,
    [],
    "e011",
    "M13.41421,9l6.70711,6.70711l-6.70711,6.70711l-1.41421,-1.41421l5.29289,-5.29289l-5.29289,-5.29289z",
  ],
};
export const faChevronUpIcon = {
  prefix: "fas",
  iconName: "chevron-up",
  icon: [
    32,
    32,
    [],
    "e012",
    "M8.99997,18.70711l6.70711,-6.70711l6.70711,6.70711l-1.41421,1.41421l-5.29289,-5.29289l-5.29289,5.29289z",
  ],
};
export const faChevronLeftIcon = {
  prefix: "fas",
  iconName: "chevron-left",
  icon: [
    32,
    32,
    [],
    "e013",
    "M18.70711,22.41421l-6.70711,-6.70711l6.70711,-6.70711l1.41421,1.41421l-5.29289,5.29289l5.29289,5.29289z",
  ],
};
