import CONFIG from "config";
import { checkForConflictedLocale } from "helpers/labelHelper";

const tenantKey = "TENANT_CODE";

const themeForTenant = (tenantCode) =>
  CONFIG.TenantCodeThemeMapping[tenantCode]
    ? CONFIG.TenantCodeThemeMapping[tenantCode].name
    : CONFIG.TenantCodeThemeMapping["BOLT"].name;

const load = (tenantCode, locale = "") => {
  tenantCode = CONFIG.TenantCodeThemeMapping[tenantCode]?.name
    ? tenantCode
    : setDefaultTenant();
  // Setting tenantCode in localStorage
  localStorage.setItem(tenantKey, tenantCode);

  const theme = themeForTenant(tenantCode);
  let lang = locale.toLowerCase().split("_")[0];

  // Checking for any conflicting locale
  if (checkForConflictedLocale(locale, theme, tenantCode)) {
    lang = CONFIG.TenantCodeThemeMapping[tenantCode]?.conflictingLocale[locale];
  }
  // Checking if language is supported
  lang = CONFIG.SUPPORTED_LANGUAGES.indexOf(lang) > -1 ? lang : "en";

  try {
    import(`./${theme}/theme-style.scss`); //eslint-disable-line
  } catch (error) {
    console.error("Error in loading scss style");
  }

  const faviconSrc = require(`./${theme}/images/favicon.ico`);
  document
    .querySelector("link[rel=icon]")
    .setAttribute("href", faviconSrc.default);

  return {
    theme,
    lang,
    helpLine: CONFIG.TenantCodeThemeMapping[tenantCode]?.helpLine,
    isHeaderVisible: CONFIG.TenantCodeThemeMapping[tenantCode]?.isHeaderVisible,
    helpLineVisibility:
      CONFIG.TenantCodeThemeMapping[tenantCode]?.helpLineVisibility,
    logoPosition:
      CONFIG.TenantCodeThemeMapping[tenantCode]?.logoPosition || "center",
    logoSrc: CONFIG.TenantCodeThemeMapping[tenantCode]?.logoPosition
      ? require(`./${theme}/images/branding-logo.svg`)
      : null,
  };
};

const setDefaultTenant = () => {
  const storedTenant = localStorage.getItem(tenantKey);
  if (storedTenant && CONFIG.TenantCodeThemeMapping[storedTenant]?.name) {
    return storedTenant;
  }
  // setting 'BOLT' as default tenant in case of error
  return "BOLT";
};

const LOADER = { load, tenantKey };

export default LOADER;
