import React, { useState, useRef } from "react";
import style from "./speaker-diagnosis.module.scss";
import Button from "components/button";
import RouteHelper from "helpers/routeHelper";
import LabelHelper from "helpers/labelHelper";
import { useParams, useNavigate } from "react-router-dom";
import {
  startSpeaker,
  randomValueGenerator,
} from "../../services/speakerService";
import ModalPopup from "../../components/modalPopup";
import { sendGTMDLPageView, getTenantKey } from "../../services/api/googleTag";
import CONFIG from "../../config";

const ACCEPTED_DIGITS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const SpeakerDiagnosis = ({ lang, locale, history, ...res }) => {
  const { type: testName } = useParams();
  const [inputView, setInputView] = useState(false);
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    input1: "",
    input2: "",
    input3: "",
  });
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: "",
    subText: "",
    CTA: "",
    secondaryCTA: "",
  });
  const [checkbox, setCheckbox] = useState({ status: true, value: null });
  const randomValue = useRef(null);
  const input1 = useRef("");
  const input2 = useRef("");
  const input3 = useRef("");

  React.useEffect(() => {
    randomValue.current = randomValueGenerator();
  }, []);

  const handleUserInput = (event) => {
    const value = event.currentTarget.value;

    if (
      !value ||
      (value.toString().length === 1 && ACCEPTED_DIGITS.indexOf(value) > -1)
    ) {
      let inputData = { ...userInput };
      if (value.length <= 1) {
        inputData = { ...userInput, [event.currentTarget.name]: value };

        setUserInput(inputData);
      }

      if (!inputData.input1) {
        input1.current.focus();
      } else if (!inputData.input2) {
        input2.current.focus();
      } else if (!inputData.input3) {
        input3.current.focus();
      } else {
        input1.current.blur();
        input2.current.blur();
        input3.current.blur();
      }
    }
  };

  const checkValue = () => {
    if (
      parseInt(userInput.input1 + userInput.input2 + userInput.input3) ===
      parseInt(randomValue.current)
    ) {
      return true;
    }
    return false;
  };

  const retakeTest = () => {
    setInputView(false);
    setCheckbox({ ...checkbox, status: true });
    setUserInput({ input1: "", input2: "", input3: "" });
  };

  const submitTest = () => {
    if (checkbox.value) {
      setConfirmModal({
        show: true,
        title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
        subText: LabelHelper[lang].DIAGNOSE_LABEL[testName].POPUP_HEADING_2,
        CTA: LabelHelper[lang].DIAGNOSE_LABEL[testName].CTA_Yes,
        secondaryCTA: LabelHelper[lang].DIAGNOSE_LABEL[testName].CTA_No,
      });
    } else {
      setConfirmModal({
        show: true,
        title: LabelHelper[lang].ERRORS.IMPORTANT_MESSAGE,
        subText: LabelHelper[lang].DIAGNOSE_LABEL[
          testName
        ].POPUP_HEADING.replace(
          "<numbers>",
          userInput.input1 + userInput.input2 + userInput.input3
        ),
        CTA: LabelHelper[lang].DIAGNOSE_LABEL[testName].CTA_Yes,
        secondaryCTA: LabelHelper[lang].DIAGNOSE_LABEL[testName].CTA_Change,
      });
    }
  };

  const listenToText = () => {
    sendGTMDLPageView(
      lang,
      getTenantKey(),
      `${CONFIG.GA_TAGS[testName]} performing`,
      ""
    );
    startSpeaker(lang, locale, randomValue.current);
    setInputView(true);
    input1.current.focus();
  };

  const handleEvent = () => {
    let result = true;
    if (checkbox.value) {
      result = false;
    } else {
      result = checkValue();
    }
    RouteHelper.replaceUrl({
      navigate,
      pathname: `/completion/${testName}`,
      search: history.location.search,
      state: { result },
    });
  };

  const handleSecondaryCTAEvent = () => {
    setConfirmModal({ ...confirmModal, show: false });
    setUserInput({ input1: "", input2: "", input3: "" });
    input1.current.focus();
  };

  return (
    <div className={`container ${style.speakerWrapper}`}>
      {!inputView ? (
        <div className={style.initialScreen}>
          <i
            className={`${style.speakerImage} fa fa-volume-up`}
            aria-hidden="true"
          ></i>
          <p>{LabelHelper[lang].DIAGNOSE_LABEL[testName].LISTEN_TO_DIGITS}</p>

          <Button
            clickEvent={listenToText}
            dataHelper="upload"
            data-cy="upload"
            text={LabelHelper[lang].DIAGNOSE_LABEL[testName].BUTTON_TO_LISTEN}
          />
        </div>
      ) : null}

      <div className={`${style.inputScreen} ${inputView ? "" : "d-none"}`}>
        <p>{LabelHelper[lang].DIAGNOSE_LABEL[testName].TYPE_NUMBERS}</p>

        <div className={style.inputFields}>
          <input
            type="number"
            ref={input1}
            onChange={handleUserInput}
            name="input1"
            pattern="[0-9]*"
            value={userInput.input1}
            id="input1"
          />
          <input
            type="number"
            ref={input2}
            onChange={handleUserInput}
            name="input2"
            pattern="[0-9]*"
            value={userInput.input2}
            id="input2"
          />
          <input
            type="number"
            ref={input3}
            onChange={handleUserInput}
            name="input3"
            pattern="[0-9]*"
            value={userInput.input3}
            id="input3"
          />
        </div>

        <div>
          {checkbox.status ? (
            <div className={style.checkboxArea}>
              <label htmlFor="checkbox">
                <input
                  type="checkbox"
                  name="checkbox"
                  onChange={(event) =>
                    setCheckbox({ ...checkbox, value: event.target.checked })
                  }
                  id="checkbox"
                />
                {LabelHelper[lang].DIAGNOSE_LABEL[testName].CHECKBOX_TEXT}
              </label>
            </div>
          ) : null}
        </div>
        <div>
          <Button
            clickEvent={() => {
              submitTest();
            }}
            disabled={
              checkbox.value
                ? false
                : !userInput.input1 || !userInput.input2 || !userInput.input3
            }
            text={LabelHelper[lang].DIAGNOSE_LABEL[testName].SUBMIT}
            dataHelper="upload"
            data-cy="upload"
          />
          <Button
            clickEvent={() => {
              retakeTest();
            }}
            text={LabelHelper[lang].DIAGNOSE_LABEL[testName].LISTEN_AGAIN}
            color="secondary"
            dataHelper="retake"
          />
        </div>
      </div>
      {confirmModal.show ? (
        <ModalPopup
          lang={lang}
          show={confirmModal.show}
          title={confirmModal.title}
          message={confirmModal.subText}
          CTA={confirmModal.CTA}
          secondaryCTA={confirmModal.secondaryCTA}
          clickEvent={handleEvent}
          secondaryClickEvent={handleSecondaryCTAEvent}
        />
      ) : null}
    </div>
  );
};

export default SpeakerDiagnosis;
