import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./main-diagnosis-result.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import CONFIG from "../../config";
import CameraHelper from "../../helpers/cameraHelper";
import LabelHelper from "../../helpers/labelHelper";
import RouteHelper from "../../helpers/routeHelper";
import DeviceHelper from "../../helpers/deviceHelper";
import ErrorHelper from "../../helpers/errorHelper";
import TenantHelper from "../../helpers/tenantSpecificHelper";
import NoPhoneFound from "assets/images/noPhoneFound.svg";
import LoadingView from "../../components/loadingView";
import ModalPopup from "../../components/modalPopup";
import C2PButton from "../../components/button";
import {
  imageProcessing,
  getSignedURL,
  setFullPageLoader,
  fraudDetection,
} from "../../actions/deviceAssessmentsAction";
import {
  sendGTMDLModelError,
  sendGTMDLPageView,
  sendTransactionId,
  getTenantKey,
  imagePredictionForGA,
} from "../../services/api/googleTag";
import {
  isAutomatedTest,
  isFeatureEnabled,
} from "../../services/middlewareModel";
import deviceDetection from "services/deviceDetectionModel";

let detectedImageClass = {
  class: "",
  score: "",
};

const MainDiagnosisResult = ({
  lang,
  transactionId,
  theme,
  tenantCode,
  history,
  ...res
}) => {
  const { type: testName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ODMReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.ODMModel
  );
  const OCRLetter = useSelector(
    (state) => state.DeviceAssessmentsReducer.OCRData
  );
  const c2pSaaS = useSelector(
    (state) => state.DeviceAssessmentsReducer.c2pSaaS
  );
  const [pageDetails, setDetails] = useState({
    label: "",
    subLabel: "",
    UPLOAD_CTA: false,
  });
  const [popup, setPopup] = useState({
    show: false,
    title: LabelHelper[lang].ERRORS.GENERIC_TITLE,
    message: LabelHelper[lang].ERRORS.GENERIC_MESSAGE,
    CTA: LabelHelper[lang].ERRORS.OK,
  });
  const [processing, setProcessing] = useState(false);
  const [noDeviceView, invokeNoDeviceView] = useState(false);

  let primaryImage = React.useRef(null);
  let secondaryImage = React.useRef(null);
  let isImagePredicted = React.useRef(false);
  // let isODMPersonCheckDone = React.useRef(false);
  const deviceMakeModel = React.useRef({ make: "", model: "" });
  const canvasRawInputRef = React.useRef(document.createElement("canvas"));
  const slowAPI = useSelector(
    (state) => state.DeviceAssessmentsReducer.slowAPI
  );
  useEffect(() => {
    sendTransactionId(transactionId);
    sendGTMDLPageView(
      lang,
      getTenantKey(),
      `${CONFIG.GA_TAGS[testName]} reviewing`,
      ""
    );
    if (ODMReducer) {
      TenantHelper("RESULT", theme, lang);

      if (
        c2pSaaS["device-make-model"] &&
        isFeatureEnabled(testName, "device-make-model") &&
        ((DeviceHelper.isAndroid() &&
          CONFIG.DEVICE_MAKE_AND_MODEL.IS_ANDROID) ||
          (DeviceHelper.isAppleDevice() && CONFIG.DEVICE_MAKE_AND_MODEL.IS_IOS))
      ) {
        const makeAndModel = DeviceHelper.getMakeAndModel();
        if (
          makeAndModel?.vendor &&
          makeAndModel?.marketingName &&
          makeAndModel?.model
        ) {
          deviceMakeModel.current = {
            make: makeAndModel.vendor + " " + makeAndModel.marketingName,
            model: makeAndModel.model,
          };
        }
      }

      const mainCanvas = document.getElementById(
        CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1
      );

      const fullMainImage = document.createElement("img");
      fullMainImage.src = CameraHelper.getImageBase64(mainCanvas);

      fullMainImage.onload = () => {
        if (isAutomatedTest(tenantCode)) {
          handleImagePrediction(
            deviceDetection.detect(null, true),
            fullMainImage
          );
        } else if (DeviceHelper.isAppleDevice()) {
          deviceDetection
            .detect(mainCanvas)
            .then((predictions) =>
              handleImagePrediction(predictions, fullMainImage)
            );
        } else {
          deviceDetection.detect(
            CameraHelper.convertRawToODMInput(
              canvasRawInputRef.current,
              mainCanvas,
              mainCanvas.width,
              mainCanvas.height
            )
          );

          deviceDetection.getODMWorker().onmessage = (message) => {
            const {
              data: { predictions },
            } = message;
            if (predictions && !isImagePredicted.current) {
              handleImagePrediction(predictions, fullMainImage);
              isImagePredicted.current = true;
            }
          };
        }
      };
    }
  }, [ODMReducer]); // eslint-disable-line

  const handleImagePrediction = (predictions, fullMainImage) => {
    if (DeviceHelper.isExperimentalFoldableCase()) {
      primaryImage.current = CameraHelper.getImageBase64(
        document.getElementById(CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1)
      );
      const phoneImage = document.getElementById("image");
      phoneImage.src = primaryImage.current;
      document
        .getElementById("imageResultContainer")
        .classList.remove("noImage");
      setDetails({
        ...pageDetails,
        label: finalConfirmationText(),
        UPLOAD_CTA: true,
      });
      dispatch(setFullPageLoader({ show: false }));
      return;
    }
    //- This log is kept here intentionally, please do not remove this.
    // console.log("[predictions captured] ", predictions);
    if (!predictions.length) {
      noDeviceDetected();
      return false;
    }

    const acceptablePrediction = predictions.filter((prediction) =>
      detectDevice(prediction.class)
    );
    if (!acceptablePrediction.length) {
      noDeviceDetected();
      return false;
    } else if (acceptablePrediction.length > 1) {
      setDetails({
        ...pageDetails,
        label:
          LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MULTIPLE_CELLPHONE_DETECTED,
        UPLOAD_CTA: false,
      });
      dispatch(setFullPageLoader({ show: false }));
      return false;
    }
    detectedImageClass = acceptablePrediction[0];
    if (checkImageCropping(acceptablePrediction)) {
      return false;
    }

    CameraHelper.doScreenshot(
      fullMainImage,
      acceptablePrediction[0].bbox,
      CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1
    );
    primaryImage.current = CameraHelper.getImageBase64(
      document.getElementById(CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1)
    );
    const phoneImage = document.getElementById("image");
    phoneImage.src = primaryImage.current;
    document.getElementById("imageResultContainer").classList.remove("noImage");

    phoneImage.onload = () => {
      // check for 'person' in final image

      // PERSON DETECTION VIA ODM code is disabled due to failure issues
      // if (
      //   c2pSaaS["face-detection"] &&
      //   isFeatureEnabled(testName, "face-detection")
      // ) {
      //   detectPersonViaODM(acceptablePrediction, phoneImage);
      // } else {
      //   proceedWithFinalImage(acceptablePrediction, phoneImage);
      // }

      proceedWithFinalImage(acceptablePrediction, phoneImage);
    };
  };

  // const detectPersonViaODM = (acceptablePrediction, phoneImage) => {
  //   const mainCanvas = document.getElementById(
  //     CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1
  //   );
  //   if (isAutomatedTest(tenantCode)) {
  //     proceedWithFinalImage(acceptablePrediction);
  //   } else if (DeviceHelper.isAppleDevice()) {
  //     deviceDetection
  //       .detect(mainCanvas)
  //       .then((predictions) => {
  //         const result = predictions.filter(
  //           (item) => item.class === "person" && item.score > 0.5
  //         );
  //         if (result.length) {
  //           setDetails({
  //             ...pageDetails,
  //             label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.PERSON_DETECTED,
  //             UPLOAD_CTA: false,
  //           });

  //           dispatch(setFullPageLoader({ show: false }));
  //           return false;
  //         } else {
  //           proceedWithFinalImage(acceptablePrediction);
  //         }
  //       })
  //       .catch(() => {
  //         setPopup({ ...popup, show: true });
  //       });
  //   } else {
  //     deviceDetection.detect(
  //       CameraHelper.convertRawToODMInput(
  //         canvasRawInputRef.current,
  //         phoneImage,
  //         phoneImage.width,
  //         phoneImage.height
  //       )
  //     );

  //     deviceDetection.getODMWorker().onmessage = (message) => {
  //       const {
  //         data: { predictions },
  //       } = message;

  //       if (!isODMPersonCheckDone.current) {
  //         isODMPersonCheckDone.current = true;
  //         const result =
  //           predictions.length &&
  //           predictions.filter(
  //             (item) => item.class === "person" && item.score > 0.5
  //           );

  //         if (result.length) {
  //           setDetails({
  //             ...pageDetails,
  //             label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.PERSON_DETECTED,
  //             UPLOAD_CTA: false,
  //           });

  //           dispatch(setFullPageLoader({ show: false }));
  //         } else {
  //           proceedWithFinalImage(acceptablePrediction);
  //         }
  //       }
  //     };
  //   }
  // };

  const proceedWithFinalImage = (acceptablePrediction) => {
    if (
      c2pSaaS["fraud-detection"] &&
      isFeatureEnabled(testName, "fraud-detection")
    ) {
      const secondaryCanvas = document.getElementById(
        CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS2
      );
      const fullSecondaryImage = document.createElement("img");
      fullSecondaryImage.src = CameraHelper.getImageBase64(secondaryCanvas);

      fullSecondaryImage.onload = () => {
        CameraHelper.doScreenshot(
          fullSecondaryImage,
          acceptablePrediction[0].bbox,
          CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS2
        );
        secondaryImage.current = CameraHelper.getImageBase64(secondaryCanvas);
      };
      // For development use
      if (CONFIG.SHOW_TEST_IMAGE_IN_RESULT) {
        const phoneSecondImage = document.createElement("img");
        phoneSecondImage.src = secondaryImage.current;
        phoneSecondImage.classList.add("d-none");
        document
          .getElementById("imageResultContainer")
          .append(phoneSecondImage);
      }
    }
    // This if statement is to directly check for BLACK SCREEN check
    if (testName === CONFIG.TEST_LABELS?.FRONT_SCREEN_CRACK_TEST?.name)
      processImage();
    else if (
      (c2pSaaS["blurr-detection"] &&
        isFeatureEnabled(testName, "blurr-detection")) ||
      (c2pSaaS["dull-detection"] &&
        isFeatureEnabled(testName, "dull-detection")) ||
      (c2pSaaS["finger-detection"] &&
        isFeatureEnabled(testName, "finger-detection"))
    ) {
      processImage();
    } else if (
      c2pSaaS["fraud-detection"] &&
      isFeatureEnabled(testName, "fraud-detection")
    ) {
      detectFraud();
    } else {
      setDetails({
        ...pageDetails,
        label: finalConfirmationText(),
        UPLOAD_CTA: true,
      });
      dispatch(setFullPageLoader({ show: false }));
    }
  };

  const checkImageCropping = (acceptablePrediction) => {
    const mainCanvas = document.getElementById(
      CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1
    );
    toggleMainCanvas("block");
    if (
      acceptablePrediction[0].bbox[1] <
        (CONFIG.IMAGE_CROP_DETECTION_CHECK / 100) * mainCanvas.clientHeight ||
      acceptablePrediction[0].bbox[1] + acceptablePrediction[0].bbox[3] >
        ((100 - CONFIG.IMAGE_CROP_DETECTION_CHECK) / 100) *
          mainCanvas.clientHeight ||
      acceptablePrediction[0].bbox[0] <
        (CONFIG.IMAGE_CROP_DETECTION_CHECK / 100) * mainCanvas.clientWidth ||
      acceptablePrediction[0].bbox[0] + acceptablePrediction[0].bbox[2] >
        ((100 - CONFIG.IMAGE_CROP_DETECTION_CHECK) / 100) *
          mainCanvas.clientWidth
    ) {
      sendGTMDLModelError(
        lang,
        getTenantKey(),
        `${CONFIG.GA_TAGS[testName]} CropImageIssue`
      );
      setDetails({
        ...pageDetails,
        label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.CROPPED_IMAGE,
        UPLOAD_CTA: false,
      });
      dispatch(setFullPageLoader({ show: false }));
      toggleMainCanvas("none");
      return true;
    }
    toggleMainCanvas("none");
    return false;
  };

  const toggleMainCanvas = (status) => {
    document.getElementById(
      CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1
    ).style.display = status;
  };

  const processImage = () => {
    imageProcessing(transactionId, primaryImage.current)
      .then((response) => {
        // checking black opaque screen detection
        if (
          !isAutomatedTest(tenantCode) &&
          !DeviceHelper.isValidFoldingScreenCase() &&
          isFeatureEnabled(testName, "opaque-screen-detection") &&
          response?.isScreenNonBlack
        ) {
          sendGTMDLModelError(
            lang,
            getTenantKey(),
            `${CONFIG.GA_TAGS[testName]} opaqueScreenDetection`
          );
          setDetails({
            ...pageDetails,
            label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.OPAQUE_SCREEN_CHECK,
            UPLOAD_CTA: false,
          });
          dispatch(setFullPageLoader({ show: false }));
        }
        // FACE CHECK
        else if (c2pSaaS["face-detection"] && response?.isImagePII) {
          setDetails({
            ...pageDetails,
            label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.API_IMAGE_PII_CHECK,
            UPLOAD_CTA: false,
          });
          dispatch(setFullPageLoader({ show: false }));
        }
        // checking dull and blur detection
        else if (
          c2pSaaS["blurr-detection"] &&
          c2pSaaS["dull-detection"] &&
          response.isImageDull &&
          response.isImageBlur
        ) {
          sendGTMDLModelError(
            lang,
            getTenantKey(),
            `${CONFIG.GA_TAGS[testName]} dullAndBlurDetection`
          );
          setDetails({
            ...pageDetails,
            label:
              LabelHelper[lang].DIAGNOSE_LABEL_COMMON.DULL_AND_BLURRY_IMAGE,
            UPLOAD_CTA: false,
          });
          dispatch(setFullPageLoader({ show: false }));
        }
        // checking blur detection
        else if (c2pSaaS["blurr-detection"] && response.isImageBlur) {
          setDetails({
            ...pageDetails,
            label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.BLURRY_IMAGE,
            UPLOAD_CTA: false,
          });
          dispatch(setFullPageLoader({ show: false }));
          sendGTMDLModelError(
            lang,
            getTenantKey(),
            `${CONFIG.GA_TAGS[testName]} blurImageDetected`
          );
        }
        // checking dull detection
        else if (c2pSaaS["dull-detection"] && response.isImageDull) {
          setDetails({
            ...pageDetails,
            label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.DULL_IMAGE,
            UPLOAD_CTA: false,
          });
          dispatch(setFullPageLoader({ show: false }));
          sendGTMDLModelError(
            lang,
            getTenantKey(),
            `${CONFIG.GA_TAGS[testName]} dullImageDetected`
          );
        }
        // checking finger detection
        else if (
          c2pSaaS["finger-detection"] &&
          response.isFingerCoveringScreen
        ) {
          setDetails({
            ...pageDetails,
            label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.FINGER_DETECTED,
            UPLOAD_CTA: false,
          });
          dispatch(setFullPageLoader({ show: false }));
          sendGTMDLModelError(
            lang,
            getTenantKey(),
            `${CONFIG.GA_TAGS[testName]} fingerDetected`
          );
        }
        // checking fraud detection
        else if (
          c2pSaaS["fraud-detection"] &&
          isFeatureEnabled(testName, "fraud-detection")
        ) {
          detectFraud();
        } else {
          setDetails({
            ...pageDetails,
            label: finalConfirmationText(),
            UPLOAD_CTA: true,
          });
          dispatch(setFullPageLoader({ show: false }));
        }
      })
      .catch((error) => {
        dispatch(setFullPageLoader({ show: false }));
        setPopup({ ...popup, show: true });
      });
  };

  const detectFraud = () => {
    fraudDetection(secondaryImage.current, transactionId, OCRLetter)
      .then((response) => {
        if (response.ocrPass) {
          setDetails({
            ...pageDetails,
            label: finalConfirmationText(),
            UPLOAD_CTA: true,
          });
        } else {
          sendGTMDLModelError(
            lang,
            getTenantKey(),
            `${CONFIG.GA_TAGS[testName]} fraudDetected`
          );
          setDetails({
            ...pageDetails,
            label: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MIRROR_FRAUD,
            UPLOAD_CTA: false,
          });
        }
      })
      .catch((error) => {
        setPopup({ ...popup, show: true });
      })
      .finally(() => {
        dispatch(setFullPageLoader({ show: false }));
      });
  };

  const finalConfirmationText = () => {
    let finalText = "";
    if (deviceMakeModel.current.make || deviceMakeModel.current.model) {
      finalText = `(${deviceMakeModel.current.make} ${
        deviceMakeModel.current.model
          ? "- " + deviceMakeModel.current.model
          : ""
      })`;
      if (DeviceHelper.isAppleDevice()) {
        finalText = deviceMakeModel.current.make;
      }
    }
    return LabelHelper[lang].DIAGNOSE_LABEL[testName].CONFIRM.replace(
      "<device>",
      finalText
    );
  };

  const noDeviceDetected = () => {
    sendGTMDLModelError(
      lang,
      getTenantKey(),
      `${CONFIG.GA_TAGS[testName]} deviceNotDetected`
    );
    invokeNoDeviceView(true);
    dispatch(setFullPageLoader({ show: false }));
    return false;
  };

  const detectDevice = (prediction) => {
    return CONFIG.ACCEPTABLE_PREDICTION_CLASSES.image[testName].includes(
      prediction
    );
  };

  const confirmUpload = () => {
    setProcessing(true);
    imagePredictionForGA(
      lang,
      getTenantKey(),
      "c2p_odm_result",
      CONFIG.GA_TAGS[testName],
      detectedImageClass["class"],
      detectedImageClass["score"]
    );
    getSignedURL(
      dispatch,
      primaryImage.current,
      transactionId,
      testName,
      tenantCode
    )
      .then((response) => {
        let permanentLinkAssetId;

        // Permanent Link Case
        if (typeof response === 'string') {
          permanentLinkAssetId = response;
        }

        sendGTMDLPageView(
          lang,
          getTenantKey(),
          `${CONFIG.GA_TAGS[testName]} reviewing`,
          "image accepted"
        );
        routeToCompletion(permanentLinkAssetId);
      })
      .catch((error) => {
        if (error?.data?.code === "ALREADY_SUBMITTED") {
          routeToCompletion();
        } else {
          setProcessing(false);
          ErrorHelper(error, history, navigate);
        }
      });
  };

  const routeToCompletion = (permanentLinkAssetId) => {
    RouteHelper.replaceUrl({
      navigate,
      pathname: `/completion/${testName}`,
      search: history.location.search,
      state: {
        isExperimentalFoldableCase: DeviceHelper.isExperimentalFoldableCase(),
        ...(permanentLinkAssetId ? {assetId: permanentLinkAssetId} : {})
      },
    });
  };

  const goToInstructions = () => {
    RouteHelper.replaceUrl({
      navigate,
      pathname: `/instruction/${testName}`,
      search: history.location.search,
    });
  };

  const retakeImage = () => {
    DeviceHelper.removeExperimentalFoldableCase();
    sendGTMDLPageView(
      lang,
      getTenantKey(),
      `${CONFIG.GA_TAGS[testName]} reviewing`,
      "image not accepted"
    );
    RouteHelper.replaceUrl({
      navigate,
      pathname: RouteHelper.routesLabels[testName],
      search: history.location.search,
    });
  };

  return (
    <>
      <div
        id="imageResultContainer"
        className={style.resultContainer + " container"}
      >
        {noDeviceView ? (
          <div className={style.noImageContainer}>
            <h1 className="text-center">
              {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.DEVICE_NOT_DETECTED}
            </h1>
            <p className={style.subHeading + " mb-0"}>
              {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.DIAGNOSIS_HELP_HEADING}
            </p>

            <ul className="px-3">
              {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.DIAGNOSIS_HELP_STEPS.map(
                (item, index) => (
                  <li
                    key={index}
                    style={{
                      background: `url(/images/result/${item.IMG}) no-repeat left center`,
                    }}
                  >
                    {item.TITLE}
                  </li>
                )
              )}
            </ul>

            <p className={style.subHeading + " mb-0 text-center"}>
              {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.VIEW_INSTRUCTION.HEADING}
            </p>
            <button
              type="button"
              onClick={goToInstructions}
              className={style.instLink + " text-center btn btn-link"}
            >
              {LabelHelper[lang].DIAGNOSE_LABEL_COMMON.VIEW_INSTRUCTION.TEXT}
            </button>

            <div className="mt-3 text-center">
              <C2PButton
                text={
                  LabelHelper[lang].DIAGNOSE_LABEL_COMMON.VIEW_INSTRUCTION.CTA
                }
                clickEvent={retakeImage}
                dataHelper="tryAgain"
                data-cy="tryAgain"
              />
            </div>
          </div>
        ) : (
          <>
            <div className={style.imageContainer}>
              <img
                id="image"
                className={primaryImage.current ? style.image : style.noImage}
                src={NoPhoneFound}
                alt=""
              />
            </div>
            <h3
              data-cy="result-heading"
              className={style.heading + " text-center mt-4"}
            >
              {pageDetails.label}
            </h3>
            {pageDetails.subLabel ? (
              <p className={`${style.Temptext} pt-2 text-center`}>
                {pageDetails.subLabel}
              </p>
            ) : null}

            <p id="GDPRtext" className={style.specialText + " text-center"}></p>

            <div
              className={
                style.btnGroup +
                " my-4 text-center d-flex flex-column align-items-center gap-3"
              }
            >
              <C2PButton
                additionalStyle={style.btnStyle}
                show={pageDetails.UPLOAD_CTA}
                text={LabelHelper[lang].DIAGNOSE_LABEL_COMMON.UPLOAD_PHOTO}
                clickEvent={confirmUpload}
                dataHelper="upload"
                data-cy="upload"
              />
              <C2PButton
                additionalStyle={style.btnStyle}
                text={
                  pageDetails.UPLOAD_CTA
                    ? LabelHelper[lang].DIAGNOSE_LABEL_COMMON.NO_RETAKE_PHOTO
                    : LabelHelper[lang].DIAGNOSE_LABEL_COMMON.RETAKE_PHOTO
                }
                color={pageDetails.UPLOAD_CTA ? "secondary" : "primary"}
                clickEvent={retakeImage}
                dataHelper="retake"
              />
            </div>
          </>
        )}
      </div>
      <LoadingView
        text={LabelHelper[lang].DIAGNOSE_LABEL_COMMON.UPLOADING_IMAGE}
        show={processing}
        slowAPIText={slowAPI}
        lang={lang}
      />

      <ModalPopup
        lang={lang}
        show={popup.show}
        title={popup.title}
        message={popup.message}
        CTA={popup.CTA}
        clickEvent={retakeImage}
      />
    </>
  );
};

export default MainDiagnosisResult;
