const ActionsHelper = {
  DEVICE_ASSESSMENTS: {
    TEST_LIST_SUCCESS: "TEST_LIST_SUCCESS",
    UPDATE_TESTS: "UPDATE_TESTS",
    START_CAPTURE_TIMER: "START_CAPTURE_TIMER",
    CAPTURE_PHONE_IMAGE: "CAPTURE_PHONE_IMAGE",
    COCO_SSD_MODEL: "COCO_SSD_MODEL",
    OCR_DATA: "OCR_DATA",
    PAGE_LOADER: "PAGE_LOADER",
    C2PSAAS: "C2PSAAS",
    CAMERA_DEVICE_ID: "CAMERA_DEVICE_ID",
    SLOW_API: "SLOW_API",
  },
};

export default ActionsHelper;
