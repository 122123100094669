import React from "react";
import PropTypes from "prop-types";
import Button from "components/button";
import style from "./popup.module.scss";
import LabelHelper from "../../helpers/labelHelper";

const Popup = ({
  lang,
  type,
  status,
  videoElement,
  isDismissable,
  clickAction,
  heading,
}) => {
  return (
    <>
      <div
        className={`${status ? "show d-block" : ""} modal fade`}
        id="popupModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content text-center">
            <div className="modal-header align-self-center">
              <h4 className={`${style.modalTitle} font-weight-bold`}>
                {heading}
              </h4>
              {isDismissable ? (
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              ) : null}
            </div>
            <div className="modal-body">
              {type === "CAMERA_DEVICE_IDS" ? (
                <>
                  <p className={style.paragraph}>
                    {
                      LabelHelper[lang].DIAGNOSE_LABEL_COMMON
                        .VIDEO_SELECT_PARAGRAPH
                    }
                  </p>
                  <video
                    ref={videoElement}
                    autoPlay
                    muted
                    playsInline
                    className={style.smallVideo}
                    id="smallVideo"
                  >
                    No Video
                  </video>
                  <p className="text-dark mt-3">
                    {
                      LabelHelper[lang].DIAGNOSE_LABEL_COMMON
                        .VIDEO_SELECT_QUESTION
                    }
                  </p>
                </>
              ) : null}
            </div>
            <div
              className={`${style.bottomButtons} modal-footer justify-content-center`}
            >
              <Button
                text={
                  LabelHelper[lang].DIAGNOSE_LABEL_COMMON
                    .VIDEO_SELECT_REJECT_BUTTON
                }
                dataHelper="error-modal-button"
                color="secondary"
                fullWidth={false}
                clickEvent={() => clickAction(false)}
              />
              <Button
                text={
                  LabelHelper[lang].DIAGNOSE_LABEL_COMMON
                    .VIDEO_SELECT_ACCEPT_BUTTON
                }
                dataHelper="error-modal-button"
                fullWidth={false}
                clickEvent={() => clickAction(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${status ? "d-block" : ""} modal-backdrop fade show d-none`}
        id="backdrop"
      ></div>
    </>
  );
};

Popup.propTypes = {
  type: PropTypes.string,
  status: PropTypes.bool,
  isDismissable: PropTypes.bool,
};

Popup.defaultProps = {
  type: "",
  status: false,
  isDismissable: false,
};

export default Popup;
