import React, { useEffect, useState } from "react";
import cs from "classnames";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import CONFIG from "../../config";
import CameraHelper from "helpers/cameraHelper";
import style from "./opaqueOverlay.module.scss";
import { setOCRData } from "../../actions/deviceAssessmentsAction";
import { isFeatureEnabled } from "services/middlewareModel";
import OpaqueOverlayFraudChars from "components/opaqueOverlayFraudChars";

const OpaqueOverlay = ({
  video,
  testName,
  dispatch,
  imagesCaptured,
  overlayColors,
}) => {
  const [fraudData, setFraudData] = useState({ letters: [], show: false });
  const c2pSaaS = useSelector(
    (state) => state.DeviceAssessmentsReducer.c2pSaaS
  );
  const opaqueOverlayElement = React.useRef(null);

  useEffect(() => {
    opaqueOverlayElement.current = document.getElementById("opaqueOverlay");
    if (opaqueOverlayElement.current) {
      opaqueOverlayElement.current.addEventListener(
        "animationend",
        captureImage
      );
      opaqueOverlayElement.current.addEventListener(
        "webkitAnimationEnd",
        captureImage
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const captureImage = () => {
    setTimeout(() => {
      CameraHelper.doScreenshot(
        video,
        [],
        CONFIG.MAIN_DIAGNOSIS_LABELS.CANVAS1
      );
      opaqueOverlayElement.current.removeEventListener(
        "animationend",
        captureImage
      );
      opaqueOverlayElement.current.removeEventListener(
        "webkitAnimationEnd",
        captureImage
      );
      if (
        c2pSaaS["fraud-detection"] &&
        isFeatureEnabled(testName, "fraud-detection")
      ) {
        setFraudData({ letters: setOCRLetter(), show: true });
      } else {
        imagesCaptured();
      }
    }, CONFIG.OVERLAY_TIMEOUT);
  };

  const setOCRLetter = () => {
    const shuffledArray = CONFIG.LETTER_LIST.sort(() => Math.random() - 0.5);
    const finalArray = shuffledArray.slice(0, 9);

    dispatch(setOCRData(finalArray));
    return finalArray;
  };

  return (
    <div
      id="opaqueOverlay"
      data-cy="opaqueOverlay"
      style={{
        background: overlayColors.main,
      }}
      className={cs({
        [style.opaqueOverlay]: true,
        [style.darkOverlay]: !overlayColors.isLightOverlay,
        [style.lightOverlay]: overlayColors.isLightOverlay,
      })}
    >
      {fraudData.show && (
        <OpaqueOverlayFraudChars
          video={video}
          letters={fraudData.letters}
          overlayColors={overlayColors}
          imagesCaptured={imagesCaptured}
        />
      )}
    </div>
  );
};

OpaqueOverlay.propTypes = {
  showOverlay: PropTypes.bool,
  video: PropTypes.object,
  overlayColors: PropTypes.object,
};

OpaqueOverlay.defaultProps = {
  showOverlay: false,
  overlayColors: {
    isLightOverlay: false,
    main: "#000000",
    characters: "#f2f2f2",
  },
};

export default OpaqueOverlay;
