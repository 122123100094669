import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cs from "classnames";

import LabelHelper from "helpers/labelHelper";
import CONFIG from "../../config";
import style from "./instruction.module.scss";
import C2PButton from "components/button";
import { setFullPageLoader } from "actions/deviceAssessmentsAction";
import {
  faBackCoverTestIcon,
  faChevronLeftIcon,
  faCrackedScreenTestIcon,
  faMicrophoneTestIcon,
  faPixelTestIcon,
  faSpeakerTestIcon,
} from "assets/C2Picons";
import {
  getTenantKey,
  sendGTMDLPageView,
  sendTransactionId,
} from "../../services/api/googleTag";

const Instruction = ({
  lang,
  transactionId,
  history,
  theme,
  tenantCode,
  ...res
}) => {
  const { type: testName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screenType, setScreenType] = useState(1);
  const [tutorialCurrentIndex, setIndex] = useState(0);
  const tutorialSlides = React.useRef(
    LabelHelper[lang]?.INSTRUCTIONS[testName]?.INSTRUCTION_SCREEN
      ?.INSTRUCTIONS || []
  );
  const fullPageLoaderReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.fullPageLoader
  );

  useEffect(() => {
    if (tutorialCurrentIndex === 0) {
      sendGTMDLPageView(
        lang,
        getTenantKey(),
        `${CONFIG.GA_TAGS[testName]} `,
        " tutorial Start"
      );
    }
    sendGTMDLPageView(
      lang,
      getTenantKey(),
      tutorialCurrentIndex && tutorialCurrentIndex > 0
        ? `${CONFIG.GA_TAGS[testName]} tutorial step ${
            tutorialCurrentIndex + 1
          }`
        : `${CONFIG.GA_TAGS[testName]} test preview`,
      tutorialCurrentIndex && tutorialCurrentIndex > 0
        ? `Step ${tutorialCurrentIndex + 1}`
        : ""
    );
  }, [tutorialCurrentIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const slideToNext = (selectedIndex, actionType) => {
    if (actionType?.type === "touchend") {
      if (Math.abs(selectedIndex - tutorialCurrentIndex) === 1)
        setIndex(selectedIndex);
    } else if (selectedIndex === tutorialSlides.current.length) {
      setScreenType(3);
      setIndex(0);
      sendGTMDLPageView(
        lang,
        getTenantKey(),
        `${CONFIG.GA_TAGS[testName]} tutorial end`,
        ""
      );
    } else if (selectedIndex >= 0) setIndex(selectedIndex);
  };

  const handleBackButton = (selectedIndex) => {
    if (selectedIndex === 0) {
      navigate(-1);
    } else {
      setIndex(selectedIndex - 1);
    }
  };

  const getDiagnosisRoute = () => {
    sendTransactionId(transactionId);
    navigate({
      pathname: `/diagnosis/${testName}`,
      search: history?.location?.search,
    });
  };

  const getTestIcon = () => {
    switch (testName) {
      case CONFIG.TEST_LABELS.FRONT_SCREEN_CRACK_TEST.name:
        return faCrackedScreenTestIcon;
      case CONFIG.TEST_LABELS.BACK_SCREEN_CRACK_TEST.name:
        return faBackCoverTestIcon;
      case CONFIG.TEST_LABELS.PIXEL_DISPLAY_TEST.name:
        return faPixelTestIcon;
      case CONFIG.TEST_LABELS.MIC_TEST.name:
        return faSpeakerTestIcon;
      case CONFIG.TEST_LABELS.SPEAKER_TEST.name:
        return faMicrophoneTestIcon;

      default:
        return faCrackedScreenTestIcon;
    }
  };

  const getImagePath = (IMG_SRC) => {
    if (
      CONFIG?.TenantCodeThemeMapping[tenantCode]?.hasColouredIllustrations[
        testName
      ]
    ) {
      return `/images/instructions/${theme}/${testName}/${IMG_SRC}`;
    }
    return `/images/instructions/GENERIC/${testName}/${IMG_SRC}`;
  };

  const setPageLoader = () => {
    if (fullPageLoaderReducer.show)
      dispatch(setFullPageLoader({ ...fullPageLoaderReducer, show: false }));
  };

  return (
    <div className={style.instructionContainer + " container-fluid"}>
      {/* STARTING SCREEN */}
      {screenType === 1 ? (
        <div className={style.startScreen}>
          <div className={style.screenIcon + " mt-3"}>
            <FontAwesomeIcon
              className={style.iconButton}
              aria-label={testName}
              icon={getTestIcon()}
            />
          </div>
          <h2 className={style.heading + " text-center pb-2"}>
            {LabelHelper[lang].INSTRUCTIONS[testName]?.START_SCREEN?.TITLE}
          </h2>
          <p className={style.subHeading + " text-center"}>
            {parse(
              LabelHelper[lang].INSTRUCTIONS[testName]?.START_SCREEN.DESCRIPTION
            )}
          </p>

          {LabelHelper[lang].INSTRUCTIONS[testName]?.START_SCREEN?.LIST
            ?.SHOW && (
            <>
              <h4 className={style.listHeading + " text-start"}>
                {
                  LabelHelper[lang].INSTRUCTIONS[testName]?.START_SCREEN.LIST
                    .HEADING
                }
              </h4>
              <ul className="ps-0">
                {LabelHelper[lang].INSTRUCTIONS[
                  testName
                ]?.START_SCREEN.LIST.DATA.map((item, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon={item.ICON} />
                    {item.TITLE}
                  </li>
                ))}
              </ul>
            </>
          )}

          <div className={style.buttonContainer + " text-center"}>
            <C2PButton
              text={LabelHelper[lang].INSTRUCTIONS[testName]?.START_SCREEN.CTA}
              additionalStyle={style.beginButton}
              dataHelper="start-instructionsButton"
              clickEvent={() => {
                setScreenType(2);
                sendGTMDLPageView(
                  lang,
                  getTenantKey(),
                  `${CONFIG.GA_TAGS[testName]} tutorial step 1`,
                  "Step 1"
                );
              }}
            />
            <button
              type="button"
              onClick={getDiagnosisRoute}
              className={style.skipButton + " btn btn-link"}
            >
              {LabelHelper[lang].INSTRUCTIONS[testName]?.START_SCREEN.CTA_2}
            </button>
          </div>
        </div>
      ) : null}

      {/* INSTRUCTION SCREEN */}
      <div
        className={
          style.instructionScreen + `${screenType === 2 ? "" : " d-none"}`
        }
      >
        <h5 className={style.stepBystep}>
          {
            LabelHelper[lang].INSTRUCTIONS[testName]?.INSTRUCTION_SCREEN
              .STEP_BY_STEP
          }
        </h5>
        <div className={style.instructionDetailsBox}>
          {tutorialSlides.current.map((slide, index) => (
            <div
              key={index}
              className={cs({
                [style.hideInstructionDetails]: index !== tutorialCurrentIndex,
                [style.instructionDetails]: true,
              })}
            >
              <h2 data-cy="test-title" className={style.heading}>
                {slide.PRIMARY_TEXT}
              </h2>
              <p className={style.subHeading}>{parse(slide.SECONDARY_TEXT)}</p>
            </div>
          ))}
        </div>
        <Carousel
          controls={false}
          indicators={false}
          interval={null}
          activeIndex={tutorialCurrentIndex}
          onSelect={slideToNext}
        >
          {tutorialSlides.current.map((slide, index) => (
            <Carousel.Item key={index}>
              <div className={style.imageContainer + " text-center"}>
                <img
                  className="px-sm-5"
                  src={getImagePath(slide.IMG_SRC)}
                  alt={slide.PRIMARY_TEXT}
                  onLoad={setPageLoader}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        <div
          className={
            style.paginationDots + " d-flex justify-content-center p-2 my-2"
          }
        >
          {tutorialSlides.current.map((slide, index) => (
            <div
              className={cs({
                [style.tutorialPaginationItem]: true,
                [style.tutorialPaginationItemActive]:
                  tutorialCurrentIndex === index,
              })}
              key={index}
              data-cy="intro-pagination-step"
              onClick={() => slideToNext(index)}
            ></div>
          ))}
        </div>

        <div className={style.buttonContainer}>
          <button
            type="button"
            onClick={() => handleBackButton(tutorialCurrentIndex)}
            className={style.backButton + " btn btn-link"}
          >
            <FontAwesomeIcon
              className={style.iconButton}
              aria-label={testName}
              icon={faChevronLeftIcon}
            />
            {LabelHelper[lang].INSTRUCTIONS[testName].INSTRUCTION_SCREEN.CTA2}
          </button>

          <C2PButton
            text={
              LabelHelper[lang].INSTRUCTIONS[testName].INSTRUCTION_SCREEN.CTA
            }
            additionalStyle={style.nextButton}
            clickEvent={() => slideToNext(tutorialCurrentIndex + 1)}
            dataHelper="instruction-nextButton"
          />
        </div>
      </div>

      {/* END SCREEN */}
      {screenType === 3 ? (
        <div className={style.endScreen}>
          <svg
            className={style.flagIcon}
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={style.flagIconFill}
              d="M23.6 13H12.6V28.5H23.6V13Z"
              fill="#00BAC7"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 8.5C8 9.94331 8.92328 11.2135 10.2 11.7407V40.4H8.09998V43H12.8V29.7H39.9L36.1 20.7L39.9 11.7H14.8V14.3H23.4V27.1H12.8V11.7539C14.0767 11.2501 15 10.0193 15 8.5C15 6.6 13.4 5 11.5 5C9.6 5 8 6.6 8 8.5ZM26 27.1H36L33.2 20.7L36 14.3H26V27.1ZM11.5 7.59998C11 7.59998 10.6 8 10.6 8.5C10.6 9 11 9.40002 11.5 9.40002C12 9.40002 12.4 9 12.4 8.5C12.4 8 12 7.59998 11.5 7.59998ZM14.8 40.6H17V42.8H14.8V40.6Z"
              fill="#170F4F"
            />
          </svg>

          <h2 className={style.heading + " text-center pb-2"}>
            {LabelHelper[lang].INSTRUCTIONS[testName]?.END_SCREEN.HEADING}
          </h2>
          <p className={style.subHeading + " text-center"}>
            {parse(
              LabelHelper[lang].INSTRUCTIONS[testName]?.END_SCREEN.DESCRIPTION
            )}
          </p>

          <div className={style.buttonContainer + " text-center"}>
            <C2PButton
              text={LabelHelper[lang].INSTRUCTIONS[testName]?.END_SCREEN.CTA}
              additionalStyle={style.startTestButton}
              clickEvent={() => getDiagnosisRoute()}
              dataHelper="start-testButton"
            />
            <button
              type="button"
              onClick={() => {
                setIndex(0);
                setScreenType(2);
              }}
              className={style.tutorialAgainButton + " btn btn-link"}
            >
              {LabelHelper[lang].INSTRUCTIONS[testName]?.END_SCREEN.CTA_2}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Instruction;
