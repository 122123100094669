import React, { useState, useEffect, useRef } from "react";
import CameraHelper from "../../helpers/cameraHelper";
import LabelHelper from "helpers/labelHelper";
import cs from "classnames";
import PropTypes from "prop-types";
import style from "./screenCapture.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setFullPageLoader,
  startCaptureTimer,
  setCameraDeviceID,
} from "../../actions/deviceAssessmentsAction";
import OpaqueOverlay from "components/opaqueOverlay";
import {
  isAutomatedTest,
  resetDiagnosisReducers,
} from "../../services/middlewareModel";
import Popup from "../popup";
import CONFIG from "../../config";
import DeviceHelper from "../../helpers/deviceHelper";
import deviceDetection from "services/deviceDetectionModel";

const whiteGuideBoxMeasurements = {};
const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

let videoStream = null;
let recalculatedPredictionDims = {};
let predictedBoundingBox = [];
let mostRecentFeedbackText = "";
let canvasContext = null;
let deviceIDs = { IDs: [], count: 0 };

const ScreenCapture = ({
  lang,
  testName,
  tenantCode,
  startTest,
  setFeedbackText,
  isInvertedText,
  showArrows,
  showErrorModal,
  diagnosisCompleted,
  ...res
}) => {
  const webcamElement = useRef();
  const smallWebcamElement = useRef();
  const canvasRef = useRef();
  const capturedPredictions = useRef({});
  const videoToImgCanvasRef = useRef(document.createElement("canvas"));
  const withinFrameFeedbackText = useRef({
    leftSide: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MOVE_PHONE_LEFT,
    rightSide: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MOVE_PHONE_RIGHT,
    topSide: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MOVE_PHONE_TOP,
    bottomSide: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MOVE_PHONE_BOTTOM,
  });
  const captureTimerStarted = useRef(false);
  const objectDetectionTimeoutTimer = useRef(null);
  // ========================= EXPERIMENTAL
  const foldableNotDetectedCase = useRef({
    timeOut: null,
    initialTime: 8000,
    finalTime: 5000,
  });
  // ========================= EXPERIMENTAL

  const dispatch = useDispatch();
  const captureImageReducer = useSelector(
    (state) => state.DeviceAssessmentsReducer.capturePhoneImage
  );
  const cameraDeviceID = useSelector(
    (state) => state.DeviceAssessmentsReducer.cameraDeviceID
  );
  const [cameraStarted, setCameraStarted] = useState(false);
  const [deviceIDPopup, showDeviceIDPopup] = useState(false);
  const [overlay, showOverlay] = useState(false);
  const [overlayColors, setOverlayColors] = useState({
    isLightOverlay: false,
    main: "#000000",
    characters: "#f2f2f2",
  });

  useEffect(() => {
    DeviceHelper.removeExperimentalFoldableCase();

    let componentIsMounted = true;
    whiteGuideBoxMeasurements.offsetLeft =
      (100 - parseInt(CameraHelper.getWhiteFrameBoxMeasurements("width"))) / 2;
    whiteGuideBoxMeasurements.offsetTop =
      (100 - parseInt(CameraHelper.getWhiteFrameBoxMeasurements("height"))) / 2;
    whiteGuideBoxMeasurements.leftSide =
      (whiteGuideBoxMeasurements.offsetLeft * windowWidth) / 100;
    whiteGuideBoxMeasurements.topSide =
      (whiteGuideBoxMeasurements.offsetTop * windowHeight) / 100;
    whiteGuideBoxMeasurements.rightSide =
      windowWidth - whiteGuideBoxMeasurements.leftSide;
    whiteGuideBoxMeasurements.bottomSide =
      windowHeight - whiteGuideBoxMeasurements.topSide;

    if (testName === CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name) {
      withinFrameFeedbackText.current.leftSide =
        LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MOVE_PHONE_RIGHT;
      withinFrameFeedbackText.current.rightSide =
        LabelHelper[lang].DIAGNOSE_LABEL_COMMON.MOVE_PHONE_LEFT;
    } else if (
      testName === CONFIG.TEST_LABELS["PIXEL_DISPLAY_TEST"]?.name ||
      DeviceHelper.isValidFoldingScreenCase()
    ) {
      setOverlayColors({
        isLightOverlay: true,
        main: "#FFFFFF",
        characters: "#000000",
      });
    }

    startCamera(cameraDeviceID[testName])
      .then((response) => {
        if (componentIsMounted) {
          setCameraStarted(response);
        }
      })
      .catch(({ type, subText }) => {
        if (
          type === "DEVICEID" &&
          testName !== CONFIG.TEST_LABELS["FRONT_SCREEN_CRACK_TEST"].name
        ) {
          CameraHelper.getVideoDeviceId()
            .then((data) => {
              deviceIDs = { IDs: data, count: 0 };
              deviceIDInterface();
            })
            .catch(() =>
              showErrorModal({
                ...res["errorModal"],
                show: true,
                subText: LabelHelper[lang].ERRORS.CAMERA_ERROR,
                CTA: "",
                errorType: "CAMERA_ERROR",
              })
            );
        } else {
          showErrorModal({
            ...res["errorModal"],
            show: true,
            CTA: "",
            subText,
            errorType: "CAMERA_ERROR",
          });
        }
      });

    window.onpopstate = () => {
      stopCamera();
    };

    return () => {
      componentIsMounted = false;
      stopCamera();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let componentIsMounted = true;
    if (componentIsMounted && cameraStarted && startTest) {
      if (DeviceHelper.isIOS15Above()) {
        // MANDATORY ALERT BOX...PLEASE DO NOT REMOVE THIS
        alert(LabelHelper[lang].DIAGNOSE_LABEL_COMMON.IOS_DIALOG_BOX);
      }
      dispatch(setFullPageLoader({ show: false }));
      setFeedbackText({
        text: LabelHelper[lang].DIAGNOSE_LABEL[testName].POINT_SCREEN_AT_MIRROR,
        inverted: isInvertedText,
      });

      const canvas = canvasRef.current;
      canvas.width = webcamElement.current.videoWidth;
      canvas.height = webcamElement.current.videoHeight;
      canvasContext = canvas.getContext("2d");
      canvasContext.textBaseline = "top";
      canvasContext.lineWidth = 12;

      if (isAutomatedTest(tenantCode)) {
        handlePredictions(deviceDetection.detect(null, true));
      } else {
        if (deviceDetection.getODMWorker())
          deviceDetection.getODMWorker().onmessage = (message) => {
            const {
              data: { predictions },
            } = message;
            handlePredictions(predictions);
          };

        ODMDetection();
      }
    }

    return () => {
      componentIsMounted = false;
    };
  }, [startTest, cameraStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  const ODMDetection = () => {
    if (isAutomatedTest(tenantCode)) {
      handlePredictions(deviceDetection.detect(null, true));
    } else if (DeviceHelper.isAppleDevice()) {
      deviceDetection
        .detect(webcamElement.current)
        .then((predictions) => handlePredictions(predictions));
    } else
      deviceDetection.detect(
        CameraHelper.convertRawToODMInput(
          videoToImgCanvasRef.current,
          webcamElement.current,
          webcamElement.current.videoWidth,
          webcamElement.current.videoHeight
        )
      );
  };

  const handlePredictions = (predictions) => {
    if (predictions) {
      //- This log is kept here intentionally, please do not remove this.
      // console.log("[predictions captured] ", predictions);
      const acceptablePrediction = getBestPrediction(predictions);
      if (acceptablePrediction?.class) {
        // ========================= EXPERIMENTAL
        if (
          CONFIG.isExperimentalFoldableCaptureAllowed &&
          foldableNotDetectedCase.current.timeOut &&
          DeviceHelper.detectFoldableDevice() === 1 &&
          DeviceHelper.getFoldScreenType() === 2
        ) {
          window.clearTimeout(foldableNotDetectedCase.current.timeOut);
          foldableNotDetectedCase.current.timeOut = null;
        }
        // ========================= EXPERIMENTAL

        if (objectDetectionTimeoutTimer.current) {
          clearTimeout(objectDetectionTimeoutTimer.current);
          objectDetectionTimeoutTimer.current = null;
        }
        drawBox(acceptablePrediction);
      } else {
        // ========================= EXPERIMENTAL
        if (
          CONFIG.isExperimentalFoldableCaptureAllowed &&
          !foldableNotDetectedCase.current.timeOut &&
          DeviceHelper.detectFoldableDevice() === 1 &&
          DeviceHelper.getFoldScreenType() === 2
        ) {
          foldableNotDetectedCase.current.timeOut = setTimeout(() => {
            setFeedbackText({
              text: LabelHelper[lang].DIAGNOSE_LABEL_COMMON
                .EXPERIMENTAL_FOLDABLE_TEXT,
              inverted: false,
            });
            setExperimentalFoldableCaseTimeout();
          }, foldableNotDetectedCase.current.initialTime);

          // ======= THIS IS COMMENTED AND KEPT HERE IN CASE GA LOGGING IS REQUIRED =======
          // noBBoxPrediction = predictions.reduce((total, prediction) => {
          //   total.push(prediction?.class + "-" + prediction?.score);
          //   return total;
          // }, []);
        }
        // ========================= EXPERIMENTAL

        objectNotDetected(
          LabelHelper[lang].DIAGNOSE_LABEL[testName].POINT_SCREEN_AT_MIRROR
        );
      }
      setTimeout(() => {
        window.requestAnimationFrame(() => {
          if (!webcamElement.current) {
            return false;
          }
          ODMDetection();
        });
      }, 1000 / CONFIG.ODM_FPS);
    }
  };

  const setExperimentalFoldableCaseTimeout = () => {
    foldableNotDetectedCase.current.timeOut = setTimeout(
      handleNoBBoxFoldableCase,
      foldableNotDetectedCase.current.finalTime
    );
  };

  const handleNoBBoxFoldableCase = () => {
    DeviceHelper.setExperimentalFoldableCase();
    setFeedbackText({
      text: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.ABOUT_TO_CAPTURE,
      inverted: isInvertedText,
    });
    dispatch(startCaptureTimer(true));
    captureTimerStarted.current = true;
  };

  const startCamera = (deviceID = null) => {
    return new Promise((resolve, reject) => {
      let cameraFacingMode = "user";
      const webcam = webcamElement.current;
      if (testName === CONFIG.TEST_LABELS["BACK_SCREEN_CRACK_TEST"].name) {
        cameraFacingMode = "environment";
      }

      let cameraStream = null;
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        cameraStream = navigator.mediaDevices.getUserMedia(
          CameraHelper.getConstraints(cameraFacingMode, deviceID)
        );
      } else {
        const getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        cameraStream = getUserMedia.call(
          navigator,
          CameraHelper.getConstraints(cameraFacingMode, deviceID),
          resolve,
          reject
        );
      }
      if (cameraStream === null) {
        reject({
          type: "DEVICEID",
          subText: LabelHelper[lang].ERRORS.CAMERA_ERROR,
        });
      } else {
        cameraStream
          .then((stream) => {
            videoStream = stream;
            if (webcam) {
              webcam.srcObject = stream;
            }
            webcam.onloadedmetadata = () => {
              resolve(true);
            };
          })
          .catch((error) => {
            console.error("[startCamera] error ", error);
            if (window.localStorage.getItem("mockDetection") === "true") {
              resolve(true);
            }
            dispatch(setFullPageLoader({ show: false }));
            const errorData = (error && error.toString()) || "";
            if (
              errorData.includes("not allowed by the user") ||
              errorData.includes("Permission denied")
            ) {
              reject({
                type: "PERMISSION",
                subText: LabelHelper[lang].ERRORS.ENABLE_CAMERA,
              });
            } else {
              reject({
                type: "DEVICEID",
                subText: LabelHelper[lang].ERRORS.CAMERA_ERROR,
              });
            }
          });
      }
    });
  };

  const deviceIDInterface = (deviceID = null) => {
    showDeviceIDPopup(true);
    const webcam = webcamElement.current;
    const smallWebcam = smallWebcamElement.current;

    return new Promise((resolve, reject) => {
      const constraintDeviceID = deviceID
        ? deviceID
        : deviceIDs.IDs[deviceIDs.count];
      let constraints = CameraHelper.getConstraints(null, constraintDeviceID);
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          videoStream = stream;
          if (webcam) {
            webcam.srcObject = stream;
          }
          if (smallWebcam) {
            smallWebcam.srcObject = stream;
          }
        })
        .catch((error) => {
          console.error("[deviceIDInterface] error ", error);
          if (deviceIDs.IDs.length > deviceIDs.count + 1) {
            deviceIDs.count += 1;
            deviceIDInterface();
          } else {
            showErrorModal({
              ...res["errorModal"],
              show: true,
              subText: LabelHelper[lang].ERRORS.CAMERA_ERROR,
              CTA: "",
              errorType: "CAMERA_ERROR",
            });
          }
        });
    });
  };

  const deviceIDPopupHandler = (value) => {
    if (value) {
      dispatch(
        setCameraDeviceID({
          ...cameraDeviceID,
          [testName]: deviceIDs.IDs[deviceIDs.count],
        })
      );

      showDeviceIDPopup(false);
      setCameraStarted(true);
    } else if (deviceIDs.IDs.length > deviceIDs.count + 1) {
      deviceIDs.count += 1;
      deviceIDInterface();
    } else {
      showErrorModal({
        ...res["errorModal"],
        show: true,
        subText: LabelHelper[lang].ERRORS.CAMERA_ERROR,
        CTA: "",
        errorType: "CAMERA_ERROR",
      });
    }
  };

  const stopCamera = () => {
    resetDiagnosisReducers(dispatch);
    contextFromCleanedCanvas();
    if (videoStream && videoStream.getVideoTracks) {
      videoStream.getVideoTracks().map((track) => track.stop());
    }
  };

  const isObjectTooFarAway = (predictedWidth, predictedHeight) => {
    if (
      predictedHeight <=
        0.45 *
          (whiteGuideBoxMeasurements.bottomSide -
            whiteGuideBoxMeasurements.topSide) ||
      predictedWidth <=
        0.5 *
          (whiteGuideBoxMeasurements.rightSide -
            whiteGuideBoxMeasurements.leftSide)
    ) {
      mostRecentFeedbackText =
        LabelHelper[lang].DIAGNOSE_LABEL_COMMON.TOO_FAR_MOVE_CLOSER;
      return true;
    } else {
      return false;
    }
  };

  const isObjectTooClose = (predictedWidth, predictedHeight) => {
    if (
      predictedHeight >=
        0.8 *
          (whiteGuideBoxMeasurements.bottomSide -
            whiteGuideBoxMeasurements.topSide) ||
      predictedWidth >=
        0.8 *
          (whiteGuideBoxMeasurements.rightSide -
            whiteGuideBoxMeasurements.leftSide)
    ) {
      mostRecentFeedbackText =
        LabelHelper[lang].DIAGNOSE_LABEL[testName].TOO_CLOSE_MOVE_FRAME;
      return true;
    } else {
      return false;
    }
  };

  const isObjectWithinFrame = () => {
    canvasContext.strokeStyle = CONFIG.BoundaryBoxColors.FAIL;
    if (recalculatedPredictionDims.dim0 < whiteGuideBoxMeasurements.leftSide) {
      mostRecentFeedbackText = withinFrameFeedbackText.current.leftSide;
      showArrows({ left: false, right: true, top: false, bottom: false });
      return false;
    } else if (
      whiteGuideBoxMeasurements.rightSide < recalculatedPredictionDims.dim2
    ) {
      mostRecentFeedbackText = withinFrameFeedbackText.current.rightSide;
      showArrows({ left: true, right: false, top: false, bottom: false });
      return false;
    } else if (
      whiteGuideBoxMeasurements.topSide > recalculatedPredictionDims.dim1
    ) {
      mostRecentFeedbackText = withinFrameFeedbackText.current.bottomSide;
      showArrows({ left: false, right: false, top: false, bottom: true });
      return false;
    } else if (
      whiteGuideBoxMeasurements.bottomSide < recalculatedPredictionDims.dim3
    ) {
      mostRecentFeedbackText = withinFrameFeedbackText.current.topSide;
      showArrows({ left: false, right: false, top: true, bottom: false });
      return false;
    } else if (
      recalculatedPredictionDims.dim1 >= whiteGuideBoxMeasurements.topSide &&
      whiteGuideBoxMeasurements.bottomSide >= recalculatedPredictionDims.dim3
    ) {
      showArrows({ left: false, right: false, top: false, bottom: false });
      canvasContext.strokeStyle = CONFIG.BoundaryBoxColors.PASS;
      return true;
    } else {
      mostRecentFeedbackText =
        LabelHelper[lang].DIAGNOSE_LABEL[testName].POINT_SCREEN_AT_MIRROR;
      return false;
    }
  };

  const isObjectCorrectlyPositioned = (predictedWidth, predictedHeight) => {
    if (isObjectWithinFrame()) {
      if (
        !isObjectTooClose(predictedWidth, predictedHeight) &&
        !isObjectTooFarAway(predictedWidth, predictedHeight)
      ) {
        return true;
      }
    }
    return false;
  };

  const drawBox = (prediction) => {
    let BUFFER = {
      x: 0.05,
      y: 0.15,
      width: 0.05,
      height: 0.15,
    };
    if (
      DeviceHelper.isIpad() ||
      (DeviceHelper.detectFoldableDevice() === 1 &&
        DeviceHelper.getFoldScreenType() === 2)
    ) {
      BUFFER = { x: 0.3, y: 0.3, width: 0.2, height: 0.3 };
    }
    contextFromCleanedCanvas();

    if (!canvasContext) {
      return false;
    }
    if (!webcamElement.current) {
      return false;
    }

    predictedBoundingBox = [
      prediction.bbox[0] + BUFFER.x * prediction.bbox[0],
      prediction.bbox[1] - BUFFER.y * prediction.bbox[1],
      prediction.bbox[2] - BUFFER.width * prediction.bbox[2],
      prediction.bbox[3] + BUFFER.height * prediction.bbox[3],
    ];
    const predictedWidth =
      predictedBoundingBox[2] *
      (windowWidth / webcamElement.current.videoWidth);
    const predictedHeight =
      predictedBoundingBox[3] *
      (windowHeight / webcamElement.current.videoHeight);

    recalculatedPredictionDims["dim0"] =
      predictedBoundingBox[0] *
      (windowWidth / webcamElement.current.videoWidth);
    recalculatedPredictionDims["dim1"] =
      predictedBoundingBox[1] *
      (windowHeight / webcamElement.current.videoHeight);
    recalculatedPredictionDims["dim2"] =
      predictedWidth + recalculatedPredictionDims["dim0"];
    recalculatedPredictionDims["dim3"] =
      predictedHeight + recalculatedPredictionDims["dim1"];

    if (
      isObjectCorrectlyPositioned(predictedWidth, predictedHeight) &&
      !captureTimerStarted.current
    ) {
      setFeedbackText({
        text: LabelHelper[lang].DIAGNOSE_LABEL_COMMON.ABOUT_TO_CAPTURE,
        inverted: isInvertedText,
      });
      if (prediction.score >= CONFIG.ACCEPTABLE_PREDICTION_SCORE) {
        capturedPredictions.current = { ...prediction };
        dispatch(startCaptureTimer(true));
        captureTimerStarted.current = true;
      }
    }

    if (captureTimerStarted.current) {
      capturedPredictions.current = { ...prediction };
    } else {
      setFeedbackText({
        text: mostRecentFeedbackText,
        inverted: isInvertedText,
      });
    }

    // strokeRect(x,y,width,height) = SYNTAX
    canvasContext.strokeRect(
      predictedBoundingBox[0],
      predictedBoundingBox[1],
      predictedBoundingBox[2],
      predictedBoundingBox[3]
    );
    return true;
  };

  const getBestPrediction = (predictions) => {
    // First filter predictions with phone classes, and take prediction with biggest bounding box
    return predictions
      .filter((prediction) =>
        CONFIG.ACCEPTABLE_PREDICTION_CLASSES.video.includes(prediction.class)
      )
      .reduce(
        (previous, current) =>
          previous === null ||
          (current.bbox[2] >= previous.bbox[2] &&
            current.bbox[3] >= previous.bbox[3])
            ? current
            : previous,
        null
      );
  };

  const objectNotDetected = (text) => {
    contextFromCleanedCanvas();
    if (!objectDetectionTimeoutTimer.current) {
      objectDetectionTimeoutTimer.current = setTimeout(() => {
        setFeedbackText({ text, inverted: false });
      }, 3000);
    }
  };

  const contextFromCleanedCanvas = () => {
    if (canvasContext) {
      canvasContext.clearRect(
        0,
        0,
        canvasContext.canvas.width,
        canvasContext.canvas.height
      );
    }
  };

  useEffect(() => {
    let componentIsMounted = true;
    if (componentIsMounted && captureImageReducer) {
      showOverlay(true);
    }
    return () => (componentIsMounted = false);
  }, [captureImageReducer]); // eslint-disable-line react-hooks/exhaustive-deps

  const imagesCaptured = () => {
    stopCamera();
    dispatch(
      setFullPageLoader({
        show: true,
        mainText: LabelHelper[lang].PAGE_LOADER.IMAGE_CHECK_TEXT,
        subText: "",
      })
    );
    diagnosisCompleted();
  };

  return (
    <>
      <Popup
        lang={lang}
        type={"CAMERA_DEVICE_IDS"}
        status={deviceIDPopup}
        clickAction={deviceIDPopupHandler}
        heading={LabelHelper[lang].DIAGNOSE_LABEL_COMMON.VIDEO_SELECT_HEADING}
        videoElement={smallWebcamElement}
      />

      <video
        ref={webcamElement}
        autoPlay
        muted
        playsInline
        className={cs({
          [style.video]: true,
          "d-none": !videoStream || !startTest || deviceIDPopup,
        })}
        id="video"
      />
      <canvas
        ref={canvasRef}
        className={cs({ [style.boxArea]: true, "d-none": deviceIDPopup })}
        id="canvas"
      />

      {overlay && (
        <OpaqueOverlay
          testName={testName}
          dispatch={dispatch}
          video={webcamElement.current}
          overlayColors={overlayColors}
          imagesCaptured={() => imagesCaptured()}
        />
      )}
    </>
  );
};

ScreenCapture.propTypes = {
  setFeedbackText: PropTypes.func,
};

export default ScreenCapture;
