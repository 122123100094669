import React, { useState } from "react";
import style from "./micTest-output.module.scss";
import Button from "components/button";
import LabelHelper from "../../helpers/labelHelper";

const MicTestOutput = ({
  lang,
  resetTest,
  testResult,
  testName,
  audioFile,
  ...res
}) => {
  const [checkbox, setCheckbox] = useState(null);

  const submitTest = () => {
    let result = true;
    if (checkbox) {
      result = false;
    }

    testResult(result);
  };

  const speakAgain = () => {
    resetTest();
  };

  const playRecording = () => {
    const audioPanel = document.getElementById("audioPanel");
    audioPanel.play();
  };

  return (
    <div className={style.micOutputWrapper}>
      <div className={style.waveIcon}>
        <svg
          width="106"
          height="105"
          viewBox="0 0 106 105"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path d="M3.22824 66.8182C1.91096 66.8182 0.841797 65.7491 0.841797 64.4318V40.5681C0.841797 39.2508 1.91096 38.1816 3.22824 38.1816C4.54551 38.1816 5.61468 39.2508 5.61468 40.5681V64.4318C5.61445 65.7491 4.54551 66.8182 3.22824 66.8182Z" />
            <path d="M17.5466 85.9091C16.2293 85.9091 15.1602 84.84 15.1602 83.5227V21.4773C15.1602 20.16 16.2293 19.0908 17.5466 19.0908C18.8639 19.0908 19.9328 20.16 19.9328 21.4773V83.5227C19.9328 84.84 18.8639 85.9091 17.5466 85.9091Z" />
            <path d="M31.865 105C30.5477 105 29.4785 103.931 29.4785 102.614V2.38622C29.4785 1.06917 30.5477 0 31.865 0C33.1822 0 34.2514 1.06917 34.2514 2.38644V102.614C34.2512 103.931 33.1822 105 31.865 105Z" />
            <path d="M46.1833 100.227C44.866 100.227 43.7969 99.158 43.7969 97.8407V7.1589C43.7969 5.84163 44.866 4.77246 46.1833 4.77246C47.5006 4.77246 48.5695 5.84163 48.5695 7.1589V97.8407C48.5695 99.158 47.5006 100.227 46.1833 100.227Z" />
            <path d="M60.5017 81.1363C59.1844 81.1363 58.1152 80.0671 58.1152 78.7498V26.2497C58.1152 24.9324 59.1844 23.8633 60.5017 23.8633C61.8189 23.8633 62.8881 24.9324 62.8881 26.2497V78.7498C62.8879 80.0671 61.8189 81.1363 60.5017 81.1363Z" />
            <path d="M74.82 100.227C73.5028 100.227 72.4336 99.158 72.4336 97.8407V7.1589C72.4336 5.84163 73.5028 4.77246 74.82 4.77246C76.1373 4.77246 77.2065 5.84163 77.2065 7.1589V97.8407C77.2063 99.158 76.1373 100.227 74.82 100.227Z" />
            <path d="M89.1364 81.1363C87.8192 81.1363 86.75 80.0671 86.75 78.7498V26.2497C86.75 24.9324 87.8192 23.8633 89.1364 23.8633C90.4537 23.8633 91.5229 24.9324 91.5229 26.2497V78.7498C91.5226 80.0671 90.4537 81.1363 89.1364 81.1363Z" />
            <path d="M103.455 66.8182C102.138 66.8182 101.068 65.7491 101.068 64.4318V40.5681C101.068 39.2508 102.138 38.1816 103.455 38.1816C104.772 38.1816 105.841 39.2508 105.841 40.5681V64.4318C105.841 65.7491 104.772 66.8182 103.455 66.8182Z" />
            <path d="M3.22824 66.8182C1.91096 66.8182 0.841797 65.7491 0.841797 64.4318V40.5681C0.841797 39.2508 1.91096 38.1816 3.22824 38.1816C4.54551 38.1816 5.61468 39.2508 5.61468 40.5681V64.4318C5.61445 65.7491 4.54551 66.8182 3.22824 66.8182Z" />
            <path d="M17.5466 85.9091C16.2293 85.9091 15.1602 84.84 15.1602 83.5227V21.4773C15.1602 20.16 16.2293 19.0908 17.5466 19.0908C18.8639 19.0908 19.9328 20.16 19.9328 21.4773V83.5227C19.9328 84.84 18.8639 85.9091 17.5466 85.9091Z" />
            <path d="M31.865 105C30.5477 105 29.4785 103.931 29.4785 102.614V2.38622C29.4785 1.06917 30.5477 0 31.865 0C33.1822 0 34.2514 1.06917 34.2514 2.38644V102.614C34.2512 103.931 33.1822 105 31.865 105Z" />
            <path d="M46.1833 100.227C44.866 100.227 43.7969 99.158 43.7969 97.8407V7.1589C43.7969 5.84163 44.866 4.77246 46.1833 4.77246C47.5006 4.77246 48.5695 5.84163 48.5695 7.1589V97.8407C48.5695 99.158 47.5006 100.227 46.1833 100.227Z" />
            <path d="M60.5017 81.1363C59.1844 81.1363 58.1152 80.0671 58.1152 78.7498V26.2497C58.1152 24.9324 59.1844 23.8633 60.5017 23.8633C61.8189 23.8633 62.8881 24.9324 62.8881 26.2497V78.7498C62.8879 80.0671 61.8189 81.1363 60.5017 81.1363Z" />
            <path d="M74.82 100.227C73.5028 100.227 72.4336 99.158 72.4336 97.8407V7.1589C72.4336 5.84163 73.5028 4.77246 74.82 4.77246C76.1373 4.77246 77.2065 5.84163 77.2065 7.1589V97.8407C77.2063 99.158 76.1373 100.227 74.82 100.227Z" />
            <path d="M89.1364 81.1363C87.8192 81.1363 86.75 80.0671 86.75 78.7498V26.2497C86.75 24.9324 87.8192 23.8633 89.1364 23.8633C90.4537 23.8633 91.5229 24.9324 91.5229 26.2497V78.7498C91.5226 80.0671 90.4537 81.1363 89.1364 81.1363Z" />
            <path d="M103.455 66.8182C102.138 66.8182 101.068 65.7491 101.068 64.4318V40.5681C101.068 39.2508 102.138 38.1816 103.455 38.1816C104.772 38.1816 105.841 39.2508 105.841 40.5681V64.4318C105.841 65.7491 104.772 66.8182 103.455 66.8182Z" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="105"
                height="105"
                fill="white"
                transform="translate(0.841797)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div className={style.playRecording}>
        <audio id="audioPanel" className={`d-none`}>
          <source src={audioFile} type="audio/mpeg" />
        </audio>
        <button onClick={playRecording}>
          {LabelHelper[lang].DIAGNOSE_LABEL[testName].PLAY_RECORDING}
        </button>
      </div>

      <div className={style.bottomGroup}>
        <div className={style.checkBoxArea}>
          <label htmlFor="micCheckbox">
            <input
              type="checkbox"
              name="micError"
              id="micCheckbox"
              onChange={(event) => setCheckbox(event.target.checked)}
            />
            {LabelHelper[lang].DIAGNOSE_LABEL[testName].CHECKBOX_TEXT}
          </label>
        </div>
        <Button
          clickEvent={submitTest}
          dataHelper="submitButton"
          data-cy="submitButton"
          text={LabelHelper[lang].DIAGNOSE_LABEL[testName].SUBMIT_BUTTON}
        />
        <Button
          clickEvent={speakAgain}
          color="secondary"
          dataHelper="retestButton"
          data-cy="retestButton"
          text={LabelHelper[lang].DIAGNOSE_LABEL[testName].RETRY_BUTTON}
        />
      </div>
    </div>
  );
};

export default MicTestOutput;
