import AudioRecorder from "audio-recorder-polyfill";
let recorder;

const PERMISSION_DENIED_ERRORS = [
  "not allowed by the user",
  "user permission denied",
  "Permission denied",
];
const ALREADY_IN_USE_ERRORS = ["I/O read operation failed"];
export const MIC_ERRORS_CONSTANTS = {
  ALREADY_IN_USE: "ALREADY_IN_USE",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  UNKNOWN: "UNKNOWN",
};

export const startMediaRecorder = () => {
  window.MediaRecorder = AudioRecorder;

  return new Promise((resolve, reject) => {
    if (MediaRecorder.notSupported) {
      alert("Audio recording is not supported in this browser");
      reject(MIC_ERRORS_CONSTANTS.UNKNOWN);
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        recorder = new MediaRecorder(stream);
        recorder.start();
        resolve(true);

        recorder.addEventListener("error", (error) => {
          reject(MIC_ERRORS_CONSTANTS.UNKNOWN);
        });
      })
      .catch((error) => {
        const errorData = (error && error.toString()) || "";
        let rejectError = MIC_ERRORS_CONSTANTS.UNKNOWN;

        if (errorData) {
          let result = ALREADY_IN_USE_ERRORS.filter((error) =>
            errorData.includes(error)
          );
          if (result.length) {
            rejectError = MIC_ERRORS_CONSTANTS.ALREADY_IN_USE;
          } else {
            result = PERMISSION_DENIED_ERRORS.filter((error) =>
              errorData.includes(error)
            );
            if (result.length) {
              rejectError = MIC_ERRORS_CONSTANTS.PERMISSION_DENIED;
            }
          }
        }
        reject(rejectError);
      });
  });
};

export const stopMediaRecorder = () => {
  return new Promise((resolve, reject) => {
    if (isMockMicTest()) {
      resolve(true);
    }

    recorder.stop();
    recorder.stream.getTracks().forEach((i) => i.stop());

    recorder.addEventListener("dataavailable", (e) => {
      const audioURL = URL.createObjectURL(e.data);
      resolve(audioURL);
    });
  });
};

export const convertToAudioBlob = async (data) => {
  const response = await fetch(data);
  const blob = await response.blob();
  const formData = new FormData();
  formData.append("audioBlob", blob);

  return formData;
};

const isMockMicTest = () => {
  if (window.localStorage.getItem("mockMicTesting") === "true") {
    return true;
  }
  return false;
};

export const startMicTest = async () => {
  if (isMockMicTest()) {
    return true;
  }

  const result = await startMediaRecorder();
  return result;
};
